import React from 'react';
import './productDetailsPagestyle.css'
import Divider from '@material-ui/core/Divider';

import {
    Grid,
    Paper,
    Button,
    Typography,
    Tooltip, Link
} from '@material-ui/core';
import {
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    customWidthA: {
        height: '25px',
        width: '65px',
        backgroundColor: '#3C9637',
        textAlign: 'center',
        fontSize: '12px',
    },
    customArrowA: {
        color: '#3C9637',
    },
    customWidthB: {
        height: '25px',
        width: '65px',
        backgroundColor: '#AAC83C',
        textAlign: 'center',
        fontSize: '12px',
    },
    customArrowB: {
        color: '#AAC83C',
    },
    customWidthC: {
        height: '25px',
        width: '65px',
        backgroundColor: '#FCC300',
        textAlign: 'center',
        fontSize: '12px',
    },
    customArrowC: {
        color: '#FCC300',
    },
    customWidthD: {
        height: '25px',
        width: '65px',
        backgroundColor: '#FF784B',
        textAlign: 'center',
        fontSize: '12px',
    },
    customArrowD: {
        color: '#FF784B',
    },
    customWidthE: {
        height: '25px',
        width: '65px',
        backgroundColor: '#FF5050',
        textAlign: 'center',
        fontSize: '12px',
    },
    customArrowE: {
        color: '#FF5050',
    },
    customWidthNA: {
        height: '25px',
        width: '65px',
        backgroundColor: '#c8c4c4',
        textAlign: 'center',
        fontSize: '12px',
    },
    customArrowNA: {
        color: '#c8c4c4',
    },
}));
const ProductRatingToolTip = (props: any) => {
    const classes = useStyles();

    if (props.item === 'A') {
        return (
            <Tooltip title={<span
                style={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: '12px',
                    color: '#FFFFFF',
                }}
                
                >N<sup style={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '12px',
                    color: '#FFFFFF',
                }}
    >IQ</sup> Score</span>} arrow open={props.itemRating === 'A' ? true : false}
                classes={{
                    tooltip: classes.customWidthA,
                    arrow: classes.customArrowA
                }}>

                {props.children}
            </Tooltip>
        )
    }
    if (props.item === 'B') {
        return (
            <Tooltip title={<span
                style={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: '12px',
                    
                    color: '#FFFFFF',
                }}
                
                >N<sup style={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '12px',
                    color: '#FFFFFF',
                }}
    >IQ</sup> Score</span>} arrow open={props.itemRating === 'B' ? true : false}
                classes={{
                    tooltip: classes.customWidthB,
                    arrow: classes.customArrowB
                }}>

                {props.children}
            </Tooltip>
        )
    }
    if (props.item === 'C') {
        return (
            <Tooltip title={<span
            style={{
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '12px',
                color: '#FFFFFF',
                
            }}
            
            >N<sup style={{
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '12px',
                color: '#FFFFFF',
            }}
>IQ</sup> Score</span>} arrow open={props.itemRating === 'C' ? true : false}
                classes={{
                    tooltip: classes.customWidthC,
                    arrow: classes.customArrowC
                }}
                
                >

                {props.children}
            </Tooltip>
        )
    }
    if (props.item === 'D') {
        return (
            <Tooltip title={<span
                style={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: '12px',
                    
                    color: '#FFFFFF',
                }}
                
                >N<sup style={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '12px',
                    color: '#FFFFFF',
                }}
    >IQ</sup> Score</span>} arrow open={props.itemRating === 'D' ? true : false}
                classes={{
                    tooltip: classes.customWidthD,
                    arrow: classes.customArrowD
                }}>

                {props.children}
            </Tooltip>
        )
    }
    if (props.item === 'E') {
        return (
            <Tooltip title={<span
                style={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: '12px',
                    
                    color: '#FFFFFF',
                }}
                
                >N<sup style={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '12px',
                    color: '#FFFFFF',
                }}
    >IQ</sup> Score</span>} arrow open={props.itemRating === 'E' ? true : false}
                classes={{
                    tooltip: classes.customWidthD,
                    arrow: classes.customArrowD
                }}>

                {props.children}
            </Tooltip>
        )
    }

    return (
        <Tooltip title={<span
            style={{
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '12px',
                
                color: '#FFFFFF',
            }}
            
            >N<sup style={{
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '12px',
                color: '#FFFFFF',
            }}
>IQ</sup> Score</span>} arrow open={props.itemRating === 'NA' ? true : false}
            classes={{
                tooltip: classes.customWidthNA,
                arrow: classes.customArrowNA
            }}>

            {props.children}
        </Tooltip>
    )

}

export default ProductRatingToolTip