import React, { useState } from 'react';
// Customizable Area Start
import {
  Grid,
  Paper,
  Link,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  footerLogoImage,
  footerPhoneStoreImage,
} from '../../blocks/catalogue/src/assets';
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
    background: 'none',
    boxShadow: 'none',
    color: 'white',
  },

  // css for footer_section start {rf}
  footer_section: {
    background: `linear-gradient(109.52deg, #38006F 55.17%, #E07933 111.06%)`,
    padding: '5rem 0',
    [theme.breakpoints.down('lg')]: {
      padding: '5rem 0',
    },
  },
  footerTitle: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '30px',
    lineHeight: '45px',

    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
      lineHeight: '35px',
    },
  },
  footerGridContent: {
    color: '#ffff',
    padding: '0 4rem',
    [theme.breakpoints.down('lg')]: {
      padding: '0rem 1rem',
    },
    [theme.breakpoints.down('md')]: {
      padding: '1rem 1rem',
    },
    
  },
  footerLink: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '30px',
    [theme.breakpoints.down('md')]: {
      fontSize: '15px',
      lineHeight: '30px',
    },
  },
  footer_Down_img: {
    width: '50%',
  },

  // css for footerBootom start
  footerBootom: {
    color: '#ffff',
    background: `linear-gradient(185deg, #38006F 55.17%, #E07933 111.06%)`,
    padding: '1rem 5rem',
    [theme.breakpoints.down('md')]: {
      padding: '1rem 1rem',
    },
  },
  copyWriteText: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '30px',
    textTransform: 'capitalize',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  ImpLinkStyle: {
    marginBottom:10,
    width:'80px',
      // [theme.breakpoints.down('md')]: {
      //   width:'80px',
      // },
      
      // [theme.breakpoints.down('xs')]: {
      //   width:'150px',
      // },
  },
 icon: {
    fill: '#fff',
 },
}));

const FooterWeb = ({ history }) => {
  const classes = useStyles();
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const [languageList, setLanguageList] = useState([
    'English',
    'Hindi',
    'Arebic',
    'Franch',
  ]);

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value)
  };

  return (
    <div style={{
      display: history?.location?.pathname == "/signup" ? "none" : false, }}>
      {/* --------- footer section start {rf} -------------- */}

      <div>
        <div className={classes.footer_section} >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            
          >
            <Grid item xs={12} sm={6} md={3}>
              <Paper className={classes.paper}>
            <div className={classes.footerGridContent}>
                <img
                  src={footerLogoImage}
                  alt="footerLogoImage"
                  // className={classes.footer_Down_img}
                  height={110}
                  width={"auto"}
          
                />
              </div>
              </Paper>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              className={classes.footerGridContent}
              
            >
              <div style={{borderBottom:'8px'}}>
                <Typography variant="h4" className={classes.footerTitle}>
                  Important Links
                </Typography>
                <div style={{height:'1px',background: '#FCC300',}} className={classes.ImpLinkStyle}/> 
                
              </div>
              <div>
                <Link href="/" color="inherit" className={classes.footerLink}>
                  About Us
                </Link>
              </div>
              <div>
                <Link href="/" color="inherit" className={classes.footerLink}>
                  Contact Us
                </Link>
              </div>
              <div>
                <Link href="/PrivacyPolicy" color="inherit" className={classes.footerLink}>
                  Privacy Policy
                </Link>
              </div>
              <div>
                <Link href="/" color="inherit" className={classes.footerLink}>
                  Terms & Conditions
                </Link>
              </div>
              <div>
                <Link href="/faqs" color="inherit" className={classes.footerLink}>
                  FAQs
                </Link>
              </div>
              <div>
                <Link href="/" color="inherit" className={classes.footerLink}>
                  Health Blog
                </Link>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              className={classes.footerGridContent}
              
            >
              <div style={{borderBottom:'8px'}}>
                <Typography variant="h4" className={classes.footerTitle}>
                  Quick Navigation
                  
                </Typography>
                <div style={{height:'1px',background: '#FCC300',}} className={classes.ImpLinkStyle}/> 
              </div>
              <div>
                <Link href="/" color="inherit" className={classes.footerLink}>
                  My Profile
                </Link>
              </div>
              <div>
                <Link href="/" color="inherit" className={classes.footerLink}>
                  Favorite Products
                </Link>
              </div>
              <div>
                <Link href="/" color="inherit" className={classes.footerLink}>
                  BMI Calculator
                </Link>
              </div>
              <div>
                <Link href="/" color="inherit" className={classes.footerLink}>
                  Nutrition Gap Calculator
                </Link>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={3} >
              <div className={classes.footerGridContent}>
                <Typography variant="h4" className={classes.footerTitle}>
                  Download Our APP
                  
                </Typography>
                <div style={{height:'1px',background: '#FCC300',}} className={classes.ImpLinkStyle}/> 
                

                <img
                  src={footerPhoneStoreImage}
                  alt="footerPhoneStoreImage"
                  // className={classes.footer_Down_img}
                  height={140}
                  width={170}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      {/* --------- footer section end {rf} -------------- */}
      {/* --------- footer bottom section start {rf} -------------- */}

      <div className={classes.footerBootom}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <FormControl>
            <Select
            disableUnderline
              value={selectedLanguage}
              onChange={handleLanguageChange}
              inputProps={{
                name: 'agent',
                id: 'age-simple',
                  classes: {
                      icon: classes.icon,
                  },
              
            
              }}
              style={{ color: '#ffff',
              fontFamily: 'Montserrat',
              fontStyle: "normal",
              fontWeight: 500, }}
              
            >
              {languageList.map((value, index) => {
                return (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Typography className={classes.copyWriteText}>
            © 2022 whatsyourN<sup>IQ</sup>. All rights reserved.
          </Typography>
        </Grid>
      </div>
      {/* --------- footer bottom section end {rf} -------------- */}
    </div>
  );
};

export default withRouter(FooterWeb);
