import React from 'react';
import ProductDetailsScreenWebController, { Props } from './ProductDetailsScreenWebController';
import './productDetailsPagestyle.css'
import {Grid,Typography,} from '@material-ui/core';
import {createStyles,withStyles,Theme,} from '@material-ui/core/styles';
import LinearProgress from "@material-ui/core/LinearProgress";


const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: '6rem 1rem',
            [theme.breakpoints.only("xs")]: {
                margin: '4rem 1rem',
            },
            [theme.breakpoints.only("sm")]: {
                margin: '5rem 1rem',
            },
            [theme.breakpoints.only("md")]: {
                margin: '6rem 1rem',
            },
        },        
        productTextMontserrat600: {
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600
        },
        ProductProgressBarContainer: {
            padding: '0 2rem',
            [theme.breakpoints.down("sm")]: {
                padding: '0 1rem',
            },
        },

        productGoodQualityText: {
            textAlign: "center",
            color: '#0EAA58',
        },
        productBadQualityText: {
            textAlign: "center",
            color: '#EF502E',
        },

        ProductProgressStatus: {
            marginBottom: 34,
            border: "double 2px transparent",
            borderRadius: 15,
            backgroundImage:
                "linear-gradient(white, white), linear-gradient(360deg,#440A70,#FF3939)",
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
            [theme.breakpoints.down("sm")]: {
                marginBottom: 16,
                marginLeft: "auto",
                marginRight: "auto",
            },
        },

        progressBar: {
            marginBottom: '2rem'
        },
        progress: {
            backgroundColor: '#D5D1D1',
            width: '100%',
            borderRadius: '20px',
            margin: '1rem 0'
        },
        progressContent: {
            borderRadius: '20px'
        },
        progressText: {
            color: '#FFFFFF',
            paddingLeft: '1rem',
            textTransform:"capitalize",
        },
        progressBarText: {
            color: '#968C8C', fontFamily: `'Poppins', sans-serif`, fontWeight: 500, fontSize: '17px',
            [theme.breakpoints.down("sm")]: {
                fontSize: '14px'
            },
        },
        
        // css for button
        


        //sai dev
        pergramtext: {                          
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '30px',
            color: "#38006D",
            textAlign:"center",
            [theme.breakpoints.down("lg")]: {
                fontSize: '16px',
            },
        
        },
        goodnotgoodtitle:{
            display:"flex",
            justifyContent:"space-around",
            [theme.breakpoints.down("xs")]: {
                display:"none",
            },
        },
        emojidisplay: {
            display:"none",
            [theme.breakpoints.down("xs")]: {
                display:"block",
            },
        },    
    });


class GoodNotSoGood extends ProductDetailsScreenWebController {
    // Customizable Area Start
    render() {
        const { classes } = this.props;    
        
        return (
            <>
                <div className={classes.root}>
                    
                    

                    {/* progress bar section */}
                    <div className={classes.ProductProgressStatus}>
                        <div className={classes.ProductProgressBarContainer}>

                            <Typography className={classes.pergramtext}>Per 100g</Typography>

                            <div className={classes.goodnotgoodtitle}>                            
                            <p className={`${classes.productGoodQualityText} ${classes.productTextMontserrat600}`}> <i className="fa-solid fa-face-laugh-beam"></i> Good ({this?.state?.positiveProductDetails.length})</p>
                            <p className={`${classes.productBadQualityText} ${classes.productTextMontserrat600}`}> <i className="fa-solid fa-face-frown-open"></i> Not so good ({this?.state?.negativeProductDetails.length})</p>
                            </div>

                        <Grid spacing={3} container direction="row" justifyContent="center" alignItems={this?.state?.positiveProductDetails.length === 0 ? "center" : 
                        this?.state?.negativeProductDetails.length === 0 ? "center" : 
                        "flex-start"}
                        
                        >
                        {this?.state?.positiveProductDetails.length === 0 ? <Grid item xs={12} sm={6}> 
                        <p className={`${classes.productGoodQualityText} ${classes.productTextMontserrat600} ${classes.emojidisplay}`}><i className="fa-solid fa-face-laugh-beam"></i> Good ({this?.state?.positiveProductDetails.length})</p>
                                    <Typography className={classes.pergramtext} style={{}}>N/A</Typography>                                                                      
                                  </Grid> :
                            <Grid item xs={12} sm={6}>
                            
                                <p className={`${classes.productGoodQualityText} ${classes.productTextMontserrat600} ${classes.emojidisplay}`}><i className="fa-solid fa-face-laugh-beam"></i> Good ({this?.state?.positiveProductDetails.length})</p>
                                    {this?.state?.positiveProductDetails?.map((goodProductDetails:any, index:any)=> { 
                                                                                                        
                                    const BorderLinearProgress = withStyles((theme: Theme) =>
                                            createStyles({
                                                root: {
                                                height: 25,
                                                borderRadius: 20
                                                },
                                                colorPrimary: {
                                                backgroundColor: "#D5D5D5"
                                                },
                                                bar: {
                                                borderRadius: 20,
                                                backgroundColor: `${goodProductDetails?.level?.toLowerCase() === 'high' ? "#3C9637" : 
                                                goodProductDetails?.level?.toLowerCase() === 'medium' ? '#AAC83C' : 
                                                goodProductDetails?.level?.toLowerCase() === 'low' ? '#AAC83C' :
                                                goodProductDetails?.level?.toLowerCase() === 'free' ? '#3C9637' : "#D5D5D5"}`
                                                }
                                            })
                                            )(LinearProgress);
                                                              
                                            
                                            
                                        
                                return( 
                                    <div className={classes.progressBar} key={index} >   
                                    
                                        <Grid container direction="row" justifyContent="space-between" alignItems="center"
                                        
                                        >
                                            <div style={{display:"flex"}}>
                                            <Typography className={classes.progressBarText} style={{textTransform:"capitalize"}} >{goodProductDetails?.name?.replace("_", " ")}&nbsp;</Typography>  
                                            <Typography className={classes.progressBarText} >({goodProductDetails?.quantity})</Typography>  
                                            </div>
                                            <Typography className={classes.progressBarText}>RDA {goodProductDetails?.percent}%</Typography>    
                                        </Grid>

                                        <div style={{ position: "relative" }}>
                                                <Typography className={classes.progressText} style={{ position: "absolute", zIndex: 9 }}>{goodProductDetails?.level}</Typography>
                                                {/* @ts-ignore */}
                                                <BorderLinearProgress variant="determinate" value={goodProductDetails?.percent > 100 ? 100 : goodProductDetails?.percent} />
                                        </div>
                                     </div>   
                                    )})}
                                </Grid> }

                                {this?.state?.negativeProductDetails.length === 0 ? <Grid item xs={12} sm={6}> 
                                <p className={`${classes.productBadQualityText} ${classes.productTextMontserrat600} ${classes.emojidisplay}`}> <i className="fa-solid fa-face-frown-open"></i> Not so good ({this?.state?.negativeProductDetails.length})</p>                                                                       
                                    <Typography className={classes.pergramtext} style={{}}>N/A</Typography>                                                                      
                                  </Grid>  :
                                <Grid item xs={12} sm={6}>
                                    <p className={`${classes.productBadQualityText} ${classes.productTextMontserrat600} ${classes.emojidisplay}`}> <i className="fa-solid fa-face-frown-open"></i> Not so good ({this?.state?.negativeProductDetails.length})</p>
                                    {this?.state?.negativeProductDetails.map((notGoodProductDetails:any, index:any)=> { 
                                        
                                        const BorderLinearProgressNegative = withStyles((theme: Theme) =>
                                            createStyles({
                                                root: {
                                                height: 25,
                                                borderRadius: 33
                                                },
                                                colorPrimary: {
                                                backgroundColor: "#D5D5D5"
                                                },
                                                bar: {
                                                borderRadius: 33,
                                                backgroundColor: `${notGoodProductDetails?.level?.toLowerCase() === 'high' ? "#FF3939" : 
                                                notGoodProductDetails?.level?.toLowerCase() === 'low' ? '#AAC83C' : notGoodProductDetails?.level?.toLowerCase() === 'free' ? "#3C9637" : '#D5D5D5' }`      
                                                }
                                            })
                                            )(LinearProgress);
                                        
                                        return( 
                                    <div className={classes.progressBar} key={index} >
                                        
                                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                            <div style={{display:"flex"}}>
                                            <Typography className={classes.progressBarText} style={{textTransform:"capitalize"}}>{notGoodProductDetails?.name?.replace("_", " ")}&nbsp;</Typography>
                                            <Typography className={classes.progressBarText}>({notGoodProductDetails?.quantity})</Typography>
                                            </div>
                                            <Typography className={classes.progressBarText}>RDA {notGoodProductDetails?.percent}%</Typography>
                                        </Grid>
                                        
                                            <div style={{ position: "relative" }}>
                                                <Typography className={classes.progressText} style={{ position: "absolute", zIndex: 9 }}>{notGoodProductDetails?.level}</Typography>
                                                {/* @ts-ignore */}
                                                <BorderLinearProgressNegative variant="determinate" value={notGoodProductDetails?.percent > 100 ? 100 : notGoodProductDetails?.percent} />
                                        </div>

                                    </div>
                                     )})}
                                  
                                  </Grid>  }
                            </Grid> 
                        </div>
                    </div>

                </div>

            </>
        );
    }
}
// Customizable Area End

export default withStyles(styles, { withTheme: true })(
    GoodNotSoGood
);
