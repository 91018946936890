import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  aboutImage,
  globalGuidelinesImage,
  productAnalysisImage,
  nutritiveValueImage,
  extensiveDataImage,
} from '../../catalogue/src/assets';
import {
  aboutBgImage,
  productAnalysisBgImage,
  globalGuidelinesBgImage,
  siteLogo,
  NutritiveValueScaleBgImage,
  nextArrow,
} from './assets';


const useStyles = makeStyles((theme) => ({
  caroselBgImage: {
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  // css for site image grid
  caroselBodyContent: {
    width: '100%',
    position: 'relative',
    justifyContent: 'center',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    textAlign: 'center',
      },
  caroselTitleText: {
    fontWeight: 600,
    fontFamily: 'Montserrat',
    fontSize: '30px',
    lineHeight: '37px',
    textAlign: 'left',
    padding: '0 100px',
    marginBottom:15,
    [theme.breakpoints.down('md')]: {
      padding: '0 50px',
      fontSize: '30px',
      lineHeight: '50px',
    },
    color: '#FFFFFF',
  },
  caroselContentText: {
    fontWeight: 500,
    fontFamily: 'Montserrat',
    fontSize: '23px',
    lineHeight: '28px',
    textAlign: 'left',
    padding: '0 100px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 50px',
    },
    color: '#FFFFFF',
  },
  intro_img: {
    // display:"inline-block",
    // width: '100%',
    // height: '100%',
    
    // [theme.breakpoints.down('lg')]: {
    //   // width: '40%',
    // },
    // [theme.breakpoints.only('sm')]: {
    //   width: '40%',
    // },
    // [theme.breakpoints.only('xs')]: {
    //   width: '60%',
    // },
    width:500,
    height:500,
    display:"inline-block",
    position: "relative",
    [theme.breakpoints.up('xl')]: {
      width:500,
      height:500,
    },
    [theme.breakpoints.down('lg')]: {
      width:250,
      height:250,
    },
    [theme.breakpoints.only('sm')]: {
      width:200,
      height:200,
    },
    [theme.breakpoints.only('xs')]: {
      width:150,
      height:150,
    },

  },
  logoImagePosi: {
    position: 'absolute',
    top: '-70px',
    right: '60px',
    width: '20%',
    [theme.breakpoints.only('sm')]: {
      width: '40%',
    },
    [theme.breakpoints.only('xs')]: {
      width: '60%',
    },
  },
  caroseNextBtn: {
    position: 'absolute',
    bottom: '-15%',
    right: 0,
    color: '#ffff',
    paddingLeft:10,
    paddingRight:20,
  },
  caroselDotBtn: {
    backgroundColor: '#ffff',
    borderRadius: '50%',
    width: '12px',
    height: '12px',
    marginLeft: '1rem',
    border: 'none',
    cursor: 'pointer',
  },
  caroselActiveDotBtn: {
    backgroundColor: '#ffff',
    borderRadius: '50%',
    width: '16px',
    height: '16px',
    marginLeft: '1rem',
    border: 'none',
    cursor: 'pointer',
  },
}));

const ImageCarosel = () => {

  const [caroselInfo, setCaroselInfo] = useState([
    {
      id: 0,
      image: aboutImage,
      bgImage: aboutBgImage,
      title: 'What’s Your NIQ',
      details:
        'We are a completely independent app, that helps you assess the nutritive value of any packaged, raw or cooked food or drink',
    },
    {
      id: 1,
      image: productAnalysisImage,
      bgImage: productAnalysisBgImage,
      title: 'Product Analysis',
      details:
        'Is based on a scientific logic developed by some of the world’s best scientists and ratified by highly experienced nutrition experts',
    },
    {
      id: 2,
      image: globalGuidelinesImage,
      bgImage: globalGuidelinesBgImage,
      title: 'Global & Local Guidelines',
      details:
        'The analysis is based on WHO, European & Indian dietary guidelines, which are globally accepted as a benchmark',
    },
    {
      id: 3,
      image: nutritiveValueImage,
      bgImage: NutritiveValueScaleBgImage,
      title: 'Nutritive value scale',
      details:
      "NIQ score grades all products on their nutritive value from highest (A) to lowest (E) based on this unique scientific logic",
    },
    {
      id: 4,
      image: extensiveDataImage,
      bgImage: globalGuidelinesBgImage,
      title: 'Extensive Data & Deep Tech',
      details:
        'An extensive database of 20,000+ products, millions of data points & deep analytic tools to simplify complex information ',
    },
  ]);

  const [indexValue, setIndexValue] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      handleIncrement();
    }, 4000);

    return () => clearInterval(interval);
  }, [indexValue]);

  const handleIncrement = useCallback(() => {
    if (indexValue === 4) {
      setIndexValue(0);
    } else {
      setIndexValue(indexValue + 1);
    }
  }, [indexValue]);

  const nextSlide = () => {
    setIndexValue(indexValue === caroselInfo.length - 1 ? 0 : indexValue + 1);
  };

  const prevSlide = (index: any) => {
    setIndexValue(index);
  };

  const classes = useStyles();
  return (
    <div
      className={classes.caroselBgImage}
      style={{
        backgroundImage: `url(${caroselInfo[indexValue].bgImage})`,
      }}
    >
      <div className={classes.caroselBodyContent}>
        
        <div>
          <img src={siteLogo} className={classes.logoImagePosi}/>
        </div>

        <div className={classes.intro_img}>
          {caroselInfo[indexValue].id == 4 ? <img
            src={caroselInfo[indexValue].image}
            alt=""
            width={"100%"}
            height={"90%"}
          /> :
          <img
            src={caroselInfo[indexValue].image}
            alt=""
            width={"100%"}
            height={"100%"}
          />
          }
          
        </div>

        <div>
          {
            caroselInfo[indexValue].title == "What’s Your NIQ" ? 
            <Typography variant="h4" className={classes.caroselTitleText}>            
           What’s Your N<sup>IQ</sup>
          </Typography>
          : <Typography variant="h4" className={classes.caroselTitleText}>            
            {caroselInfo[indexValue].title}
          </Typography>
          }
          
          {
            caroselInfo[indexValue].title == "Nutritive value scale" ? <Typography className={classes.caroselContentText}>
            N<sup>IQ</sup> score grades all products on their nutritive value from highest (A) to lowest (E) based on this unique scientific logic
          </Typography>:<Typography className={classes.caroselContentText}>
            {caroselInfo[indexValue].details}
          </Typography>
          }

        </div>
         

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          className={classes.caroseNextBtn}
        >
          <div>
            {caroselInfo.map((item, index) => {
              return (
                <button
                  key={index}
                  onClick={() => {
                    prevSlide(index);
                  }}
                  className={
                    index === indexValue
                      ? classes.caroselActiveDotBtn
                      : classes.caroselDotBtn
                  }
                />
              );
            })}
          </div>
          <div style={{display:"flex",cursor: 'pointer'}} onClick={nextSlide}>
          <Typography>
            Next 
          </Typography>
          <img src={nextArrow} alt="" style={{marginLeft:10,}}/>
          </div>
        </Grid>

      </div>
    </div>
  );
};

export default ImageCarosel;
