// Customizable Area Start
import React, { Component } from "react";
import {Accordion, AccordionSummary, AccordionDetails, Typography,Grid,Button} from "@material-ui/core";
// import Accordion from "@material-ui/core/Accordion";
// import AccordionSummary from "@material-ui/core/AccordionSummary";
// import AccordionDetails from "@material-ui/core/AccordionDetails";
// import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
// import Grid from "@material-ui/core/Grid";
// import Button from "@material-ui/core/Button";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import SearchControllerWeb, {
  Props,
  configJSON
} from "./SearchControllerWeb";
import {searchBgImage,noRateBgImage, noDataImage,noRatedBgm,cantBerateImg,DrumstickImage} from "./assets"
import { borderRadius } from "react-select/src/theme";
const styles = (theme: Theme) =>
  createStyles({
      root: {
        marginTop:10,
        [theme.breakpoints.up(600)]: {
          marginTop: 64,
        },
        [theme.breakpoints.down(600)]: {
          marginTop: 48,
        },
        [theme.breakpoints.down(515)]: {
          marginTop: 56,
        },
      },
      noProductContainer:{
        background: '#E5E5E5',
        display:"flex", 
        flexDirection:"column",
        justifyContent:"center",
        alignItems:'center',
        paddingTop:50,
        paddingBottom:50,
       
        [theme.breakpoints.down("lg")]: {
            paddingTop:40,
            paddingBottom:40,
        },
        [theme.breakpoints.down("md")]: {
            paddingTop:25,
            paddingBottom:25,
        },
        [theme.breakpoints.down("sm")]: {
            paddingTop:15,
            paddingBottom:15,
        },
        [theme.breakpoints.down("xs")]: {
            paddingTop:5,
            paddingBottom:5,
        },
      },
      noDataimage:{
        height:50,
        width:50,
      },
      noProductImage:{
        // width: 5,
        height:450,
        [theme.breakpoints.down("md")]: {
            width: 150,
            height:200,
        },
        [theme.breakpoints.down("xs")]: {
            width: 100,
            height:150,
        },
      },
      noProductTitle:{
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        textAlign:'left',
        fontSize: '25px',
        lineHeight:"117.5%",
        color: '#FF3939',
        // textAlign:"right",
        padding:50,
        [theme.breakpoints.down("lg")]: {
            padding:35,
        },
        [theme.breakpoints.down("md")]: {
            padding:25,
            fontSize: '20px',
        },
        [theme.breakpoints.down("sm")]: {
            padding:15,
        },
        [theme.breakpoints.down("xs")]: {
            padding:10,
            fontSize: '16px',
        },
      },
      textContainer:{
        textAlign:"center",
        padding:50,
        border:2,
        height:20,
        
      },
      textView:{
        fontSize:20,
      },
      readText:{
        color:"red",
        fontSize:20,
        paddingTop:20,
      },

      // straightLine:{
      //   width:"90%",
      //   borderBottom:"1px solid #455A64",
      //   marginLeft:80,
      //   marginRight:80,
      // },
      containerStyle: {
        marginTop: 50,
        // marginLeft: 55.5,
        // marginRight: 55.5,
        paddingBottom: 100,
        width:"87%",

        [theme.breakpoints.down("md")]: {
          marginLeft: 0,
          marginRight: 0,
          paddingBottom: 0,
        },
      },
      AccordionRootStyle: {
        // marginRight: 45,
        // marginLeft: 59,
        marginBottom: 203.86,
        [theme.breakpoints.down("sm")]: {
          marginLeft: 0,
          marginRight: 0,
          marginBottom: 30,
        },
      },
      AccordionStyle: {
        marginBottom: 34,
        background: '#FFFFFF',
        border: '0.3px solid #757D8A',
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.12)',
        borderRadius: '5px',
        // backgroundImage:
        //   "linear-gradient(white, white), linear-gradient(180deg, #FF3939,#41479B)",
        // backgroundOrigin: "border-box",
        // backgroundClip: "content-box, border-box",
        [theme.breakpoints.down("sm")]: {
          marginBottom: 16,
          marginLeft: "auto",
          marginRight: "auto",
        },
      },
      AccordionSummaryStyle: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 22,
        margin: 12,
        color: "#455A64",
        [theme.breakpoints.down("sm")]: {
          fontSize: 16,
          margin: 5,
          fontWeight: 500,
        },
      },
      AccordionDetailStyle: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 20,
        lineHeight: 2,
        marginLeft: 30,
        marginRight: 70,
        marginBottom: 24,
        color: "#757D8A",
        [theme.breakpoints.down("sm")]: {
          fontWeight: 400,
          fontSize: 14,
          marginLeft: 0,
          marginRight: 0,
        },
      },
      AccordionDetailBoxStyle: {
        display: "flex",
        flexDirection: "column",
        rowGap: 10,
      },
      submitProductButton:{
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '22px',
        textAlign: 'center',
        textDecorationLine: 'underline',    
        textTransform:"none",
        color: '#FF3939',
        [theme.breakpoints.down("sm")]: {
          fontSize: 14,
        },
      },
      // submitProductButtonContainer:{
      //   display:"flex",
      //   justifyContent:"center",
      //   marginTop:30,
      //   marginBottom:30,
      
      // [theme.breakpoints.down("sm")]: {
      //   marginTop:30,
      //   marginBottom:30,
      // },
      // },
      // SearchAnotherProductButton:{
      //   fontFamily: 'Montserrat',
      //   fontStyle: 'normal',
      //   fontWeight: 700,
      //   fontSize: '22px',
      //   textAlign: 'center',
      //   textTransform: 'uppercase',
      //   color: '#FFFFFF',
      //   background:'#38006D',
      //   borderRadius:"44px",
      //   padding:'25px 100px',
      //   [theme.breakpoints.down("sm")]: {
      //     padding:'15px 50px',
      //     fontSize: '16px',
      //   },
      //   [theme.breakpoints.down("sm")]: {
      //     padding:'5px 20px',
      //     fontSize: '12px',
      //   },

// New  styles Code for Rated Page
  
    // },
    back_btn:{        
      height:35,
      width:90,
      fontSize: '15px',
      borderRadius:20,
        color:"#FF3939",fontFamily: 'Poppins',fontStyle: 'normal',fontWeight: 500,
        textTransform:"capitalize",
      [theme.breakpoints.down("sm")]: {
        height:20, width:40,
        fontSize: '12px',
      },
    },
    product_bgImg:{
      width:"100%", height:400,
      display:"flex", justifyContent:"center",
      alignItems:"center", 
      [theme.breakpoints.down("xs")]: {
        height:250,
      },
    },
    product_img:{      
      width:300, height:300, background:'#fff',          
      border: "double 6px transparent",
      borderRadius: '50%',
      backgroundImage:
      "linear-gradient(white, white), linear-gradient(200deg,#41479B,#FF3939)",
      backgroundOrigin: "border-box",
      backgroundClip: "content-box, border-box",
      [theme.breakpoints.down("xs")]: {
        border: "double 4px transparent",
        width:180, height:180,
      },
    },
    productname:{
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '30px',
      lineHeight: '35px',
      color: '#455A64',
      [theme.breakpoints.down("lg")]: {
        fontSize: '29px',
        lineHeight: '30px',         
      },
      [theme.breakpoints.down("md")]: {
        fontSize: '24',        
        lineHeight: '25px',
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: '18',        
      },

    },
    foodType:{
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '23px',          
      color: '#FF5050',
      marginTop:20,
      [theme.breakpoints.down("lg")]: {
        fontSize: '20px',         
      },
      [theme.breakpoints.down("md")]: {
        fontSize: '17',        
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: '14',        
      },
    },
    cantBeRateImg:{
      width:200, height:200, margin:"10 auto",
      [theme.breakpoints.down("sm")]: {
        width:100, height:100,
      },
    },

    CardContentContainer:{
      margin:50,
      [theme.breakpoints.down("sm")]: {        
        margin:0
      },
    },
    cantbeRateTxt:{
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '27px',
      lineHeight: '10px',
      color: '#FF3939',                    
      textAlign:"center",
      [theme.breakpoints.down("lg")]: {
        fontSize: '21px',         
      },
      [theme.breakpoints.down("md")]: {
        fontSize: '18',        
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: '16',        
      },
    },
    discription:{
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '25px',                                       
          textAlign:"center",
          lineHeight: '38px',
          color: '#455A64',
          [theme.breakpoints.down("lg")]: {
            fontSize: '21px',
            lineHeight: '34px', 
          },
          [theme.breakpoints.down("md")]: {
            fontSize: '18',
            lineHeight: '30px', 
          },
          [theme.breakpoints.down("sm")]: {
            fontSize: '16',
            lineHeight: '25px', 
          },
},

    submitProductButtonContainer:{
      display:"flex",
      justifyContent:"center",
      marginTop:30,
      marginBottom:30,
    
    [theme.breakpoints.down("sm")]: {
      marginTop:30,
      marginBottom:30,
    },
    },
    SearchAnotherProductButton:{
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '22px',
      textAlign: 'center',
      textTransform: 'uppercase',
      color: '#FFFFFF',
      background:'#38006D',
      borderRadius:"44px",
      padding:'25px 100px',
      '&:hover': {
        background: '#12125c',
        border: 'none',
    },
      [theme.breakpoints.down("sm")]: {
        padding:'15px 50px',
        fontSize: '16px',
      },
      [theme.breakpoints.down("sm")]: {
        padding:'5px 20px',
        fontSize: '12px',
      },
    }
 
  });

class NoRatedComponent extends SearchControllerWeb {

    constructor(props: Props) {
    super(props);
    }

  render() {
    const { classes } = this.props;
    return (   
      
        <div style={{padding:50,}}>
          {console.log(this.state.noRatedArray, "__data")}           
          <div style={{marginTop:40, display:"flex", justifyContent:"flex-end"}}>
       <Button variant="outlined" className={classes.back_btn} 
      onClick={()=>{
        this.props.navigation.goBack();
      }}
       size="small">Back</Button>
       </div>
       {console.log(this.state.noRatedArray, "ggg")}
        <Card  style={{marginTop:20,minHeight:700, boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.2)"}} >         
          <CardMedia
          image={this.state?.noRatedArray?.attributes?.image}
          title="imgae"        
        className={classes.product_bgImg}                
        
        >
          
<div    className={classes.product_img}  style={{position:"relative"}}        >
          <img 
       style={{    position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '59%'}}
           src={this.state?.noRatedArray?.attributes?.image}
           alt="productImage"               
          />          
          </div>
          </CardMedia>

         <CardContent 
         className={classes.CardContentContainer}>
          <Typography
          className={classes.productname}        >
          {/* WOW Life Apple Cider Vinegar - 750 ml */}
        {this.state?.noRatedArray?.attributes?.product_name}

          </Typography>
          <Typography         
        className={classes.foodType}
          >
            {/* Packaged Food */}
            {this.state?.noRatedArray?.attributes?.category_type}
          </Typography>                              
          </CardContent>

          <CardMedia
          className={classes.cantBeRateImg}
          image={cantBerateImg}
          title="cantbeRatedImg"
          />
          <CardContent 
          className={classes.CardContentContainer}>
                  
          <Typography 
          className={classes.cantbeRateTxt}
          style={{
          
        }}
          >
            Can't be rated
          </Typography>                              
          </CardContent>
          <CardContent           
          className={classes.CardContentContainer}>  
                    
          <Typography 
          className={classes.discription}
          style={{
          
        }}>
            This product cannot be rated because the manufacturer did not publish complete nutritional information 
          </Typography>                                        
          </CardContent>
          <CardActions className={classes.submitProductButtonContainer}>
                  <Button className={classes.SearchAnotherProductButton}
                  onClick={()=>this.searchAnotherProductButton()}
                  >Search Another Product</Button>
          </CardActions>
       </Card>
       </div>
    );
  }
}


export default withStyles(styles, { withTheme: true })(NoRatedComponent);
// Customizable Area End
// <Card className={classes.root} style={{boxShadow:'20px 20px 60px #b1b1b1,-20px -20px 60px #ffffff'}}>    
        //      <img src={searchBgImage} alt="bgImage" style={{width: "100%",}}/>  
        //      {/* No Product Found Page start */}
        //      <div className={classes.noProductContainer}>
        //      <img src={noRateBgImage} alt="bgImage" 
        //      className={classes.noProductImage}/>
        //      <div>
        //      <Typography className={classes.noProductTitle}>WOW Life Apple Cider Vinegar - 750 ml</Typography>

        //      </div>
        //      <Typography>Packaged Food</Typography>
        //      <div className={classes.noDataContainer}>
        //        <img src={noDataImage} alt="bgImage" 
        //      className={classes.noDataImage}/>
        //      </div>
        //      <Typography className={classes.readText}>Can't be rated</Typography>
        //      <div className={classes.textContainer}>
        //       <Typography className={classes.textView}>This product cannot be rated because the manufacturer did not publish complete nutritional information</Typography>
        //      </div>
        //      {/* <div className={classes.straightLine}/> */}
        //      {/* <Container maxWidth="xl"> */}
        // {/* <Toolbar disableGutters> */}
        //      <Grid container justifyContent="center" alignItems="center" >
        //     <div className={classes.containerStyle}>
        //     <div className={classes.submitProductButtonContainer}>
        //           <Button
        //           className={classes.SearchAnotherProductButton}>
        //             Search Another Product
        //           </Button>
        //           </div>
        //           </div>
        //           </Grid>
        //           {/* </Toolbar> */}
        //           {/* </Container> */}
        //     </div>
        // </Card>