import React from 'react';
import Loader from '../../../components/src/Loader';
import UserSignUpScreenWebController, {
    Props,
    configJSON,
} from '../../social-media-account/src/UserSignUpScreenWebController';

import MobilePhoneInputPage from './MobilePhoneInputPage';

import {
    Grid,
    Paper,
    CssBaseline,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import OtpInputPage from './OtpInputPage';
import DescribeInputPage from './DescribeInputPage';
import ImageCarosel from './ImageCarosel';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: '100vh',
            overflowY: 'hidden',
        },
        image: {
            // backgroundImage: `url(${signupBgImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor:
                theme.palette.type === 'light'
                    ? theme.palette.grey[50]
                    : theme.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
    });

class UserSignUpScreenWeb extends UserSignUpScreenWebController {
    static UserSignUpScreenWeb: UserSignUpScreenWeb;
    // Customizable Area Start
    render() {
        const {
            loading,
            gridItemNumber,
            mobileNumberInputPageEnable,
            otpInputPageEnable,
            describeInputPageEnable,
            gotErrorResponse
        } = this.state;
        const { classes } = this.props;

        return (
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={false} md={6}>
                    <ImageCarosel />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    component={Paper}
                    elevation={6}
                    square
                >


                    {mobileNumberInputPageEnable && (
                        <MobilePhoneInputPage
                            sentOtpRequest={this.sentMobileOtpRequest}
                            socialFacebookAuthResponse={this.socialFacebookAuthResponse}
                            socialGoogleAuthResponse={this.socialGoogleAuthResponse}
                            navigationBtnFnc={this.navigationBtnFnc}
                            loading={loading}
                            gotErrorResponse={gotErrorResponse}
                        />
                    )}
                    {otpInputPageEnable && (
                        <OtpInputPage
                            otpVarification={this.mobileOtpVerification}
                            resentOtpRequest={this.resentOtpRequest}
                            pageManageInLocalstore={this.pageManageInLocalstore}
                            navigationBtnFnc={this.navigationBtnFnc}
                            handleContinue={this.handleContinue}
                            loading={loading}
                        />
                    )}
                    {describeInputPageEnable && (
                        <DescribeInputPage userProfileUpdate={this.userProfileUpdate} navigationBtnFnc={this.navigationBtnFnc} loading={loading} />
                    )}
                </Grid>
            </Grid>
        );
    }
}
// Customizable Area End

export default withStyles(styles, { withTheme: true })(
    UserSignUpScreenWeb
);
