import React, { useEffect, useState, useCallback, } from "react";
// import { View, Button, StyleSheet } from "react-native";


import { withRouter } from "react-router-dom";
//Customise area start
import { useRef } from 'react'
import { InputBase, Typography, DialogContent, Dialog } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import CircularProgress from "@material-ui/core/CircularProgress";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import StopIcon from '@material-ui/icons/Stop';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { setInterval } from "timers";
import IconButton from '@material-ui/core/IconButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

const baseURL = require("../../framework/src/config");
const QRicon = require("../assets/QRScanIcon.svg") as string;
const VoiceSearch = require("../assets/Voice Search Icon.svg") as string;
const noProductFoundImg = require("../assets/NpProductFoundImg.svg") as string;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerStyle: {
      display: "flex",
      flexGrow: 1,
      justifyContent: "space-between",
      alignItems: "center",
      marginLeft: 50,
      marginRight: 50,
      [theme.breakpoints.down("xl")]: {
        marginLeft: 40,
        marginRight: 40,
      },
      [theme.breakpoints.down("lg")]: {
        marginLeft: 30,
        marginRight: 30,
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: 20,
        marginRight: 20,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: 10,
        marginRight: 10,
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },

    search: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      backgroundColor: "#fff",
      borderRadius: "59px",
      boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.12)",
      marginRight: theme.spacing(2),
      marginLeft: 0,
      paddingRight: "1em",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },

      gap: "10px",
    },

    Homesearch: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      backgroundColor: "#fff",
      borderRadius: "59px",
      boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.12)",
      width: "100%",
      minHeight: "70px",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },
    },

    inputInput: {
      background: "none !important",
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
      transition: theme.transitions.create("width"),
      fontFamily: "Poppins",
      color: "#38006D",
      fontSize: "13px",
      fontWeight: 500,
      lineHeight: "18px",
      fontStyle: "normal",
      width: "100%",
      [theme.breakpoints.up("xl")]: {
        fontSize: 18,
      },
      [theme.breakpoints.up("lg")]: {
        width: "34ch",
      },
      [theme.breakpoints.only("md")]: {
        width: "34ch",
      },
      [theme.breakpoints.only("sm")]: {
        width: "34ch",
      },
      [theme.breakpoints.between(599, 655)]: {
        width: "30ch",
      },
      [theme.breakpoints.between(360, 599)]: {
        maxWidth: "30ch",
        fontSize: 16,
      },
      [theme.breakpoints.down(360)]: {
        width: "6ch",
        fontSize: 16,
      },
    },

    HomeinputInput: {
      background: "none !important",
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
      paddingRight: `calc(1em + ${theme.spacing(1)}px)`,
      transition: theme.transitions.create("width"),
      fontFamily: "Poppins",
      color: "#38006D",
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "18px",
      fontStyle: "normal",
      width: "100%",
      [theme.breakpoints.up("xl")]: {
        fontSize: 18,
      },
      [theme.breakpoints.up("lg")]: {
        width: "55ch",
      },
      [theme.breakpoints.only("md")]: {
        width: "45ch",
      },
      [theme.breakpoints.only("sm")]: {
        width: "35ch",
      },
      [theme.breakpoints.between(599, 655)]: {
        width: "30ch",
      },
      [theme.breakpoints.only("xs")]: {
        width: "18ch",
        fontSize: 16,
      },
      [theme.breakpoints.down(355)]: {
        width: "13.4ch",
        fontSize: 16,
      },
    },

    voiceQrIcon: {
      display: "flex",
      columnGap: 10,
      alignItems: "center",
      justifyContent: "flex-end",
      [theme.breakpoints.up("xl")]: {
        columnGap: 30,
      },
      [theme.breakpoints.down("sm")]: {
        columnGap: 20,
      },
      [theme.breakpoints.down("xs")]: {
        columnGap: 10,
      },
    },

    HomevoiceQrIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },

    HomeVoiceQrIconStyle: {
      marginRight: 40,
      height: 24,
      width: 24,
      [theme.breakpoints.down("sm")]: {
        height: 16,
        width: 16,
        marginRight: 20,
      },
      [theme.breakpoints.down("xs")]: {
        height: 16,
        width: 16,
        marginRight: 20,
      },
    },

    searchDropdown: {
      marginTop: '4px',
      position: "absolute",
      zIndex: -1,
      maxHeight: 400,
      background: "#fff",
      borderRadius: "15px",
      padding: 20,
      paddingTop: 25,
      paddingBottom: 0,
      overflowY: "scroll",
      width: "-webkit-fill-available",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,0.00)",
      },
    },
    HomesearchDropdown: {
      marginTop: '4px',
      position: "absolute",
      zIndex: -1,
      maxHeight: 150,
      background: "#fff",
      borderRadius: "29px",
      padding: 20,
      paddingTop: 75,
      paddingBottom: 0,
      overflowY: "scroll",
      width: "-webkit-fill-available",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,0.00)",
      },
    },
    noProductImage: {
      width: 64,
      height: 'auto',
      padding: 15,
    },
    noProductTitle: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: "117.5%",
      color: '#FF3939',
      textAlign: "center",
      padding: 10
    },

    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: '#455A64',
      border: '1px solid #455A64',
      padding: '2px'

    },
    notificationText: {
      textAlign: 'center',
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "33px",
      margin: '0rem 0rem 2rem 0rem',
      color: "#455A64",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        lineHeight: "12px",
      },
    },

  })
);

//Customise area stop

const CustomSearch = (props: any) => {
  //Customise area start
  const classes = useStyles();
  const [searchTxt, setSearchTxt] = useState<any>(localStorage.getItem("reloadValue") ? localStorage.getItem("reloadValue"): "");
  const [ResultTxt, setResultTxt] = useState([]);
  const [dropDown, setDropDown] = useState(true);
  const [loading, setLoading] = useState(false);
  const [noProducts, setNoproducts] = useState(false);
  const [isUserClicked, setIsUserClicked] = useState(false);
  const video: any = useRef(null);
  const canvas: any = useRef(null);
  const [camModal, setCamModal] = useState(false);
  const [barCode, setBarCode] = useState("");
  const [openNotificationModal, setOpenNotificationModal] = useState(false)  

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  // UseEffect Hook for Voice Search functionality start
  useEffect(() => {
    if (isUserClicked && transcript) {
      setSearchTxt(transcript);
      optimisedVersion(transcript);
      localStorage.setItem("searchValue", transcript)
      dropDownClose();

      if (transcript && listening && props?.history?.location?.pathname !== "/search") {
        setTimeout(() => {
          props?.history?.push({
            pathname: '/search',
            search: `${transcript}`,
            state: { searchName: `${transcript}` }
          });
        }, 2000);

      }
      else if (transcript && listening && props?.history?.location?.pathname === "/search") {
        window.location.reload();
        if (!localStorage.getItem("searchValue")) { localStorage.setItem("searchValue", searchTxt) }
        else { localStorage.setItem("searchValue", transcript) }
      }
    }
  }, [transcript]);

  useEffect(() => {
    if (isUserClicked && !listening) {
      setIsUserClicked(false)
    }
  }, [listening]);

  // UseEffect Hook for Voice Search functionality stop

  //  Use Effect Hook for send & update data to locaStorage start
  useEffect(() => {
    transcript ? localStorage.setItem("searchValue", transcript) : localStorage.setItem("searchValue", searchTxt)
  }, [searchTxt || transcript]);

    
  useEffect(() => {

    if (
      props?.history?.location?.pathname === "/ProductDetails" ||     
      props?.history?.location?.pathname === "/search" ||
      props?.history?.location?.pathname === "/noproduct" ||
      props?.history?.location?.pathname === "/norated"
      ) {
      const x: any = localStorage.getItem("searchValue")
      x ? setSearchTxt(x) : setSearchTxt(searchTxt)
    }
    else {               
      searchTxt ? setSearchTxt("") : searchTxt
    }
  }, [props?.history?.location]);
  
  
  //  Use Effect Hook for send & update data to locaStorage stop

  const handleChange = (e: any) => {
    setSearchTxt(e.target.value);
    optimisedVersion(e.target.value);
  }

  const handleSearchAutoComplete = async (value: any) => {
    if (value.trim().length > 2) {
      try {
        setLoading(true);
        let response = await fetch(`${baseURL.baseURL}/bx_block_catalogue/search?category_id=1&query=${value}`,
        );
        const data = await response?.json();
        if (data?.products || data?.meta) {
          setNoproducts(false);
          setResultTxt(data?.products?.data);
          setLoading(false);
        }
        else if (data?.errors) { setNoproducts(true); }
        document.addEventListener('click', function (event) { setDropDown(false); })
      }
      catch (error) { console.error(error, "error while calling api"); }
    }

  };

  const dropDownClose = () => { setDropDown(false); };

  const debounce = (func: any) => {
    let timer: any;
    return function (...args: any) {
      // @ts-ignore
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null
        func.apply(context, args);
      }, 500);
    }
  }

  const optimisedVersion = useCallback(debounce(handleSearchAutoComplete), []);

  const handleBarcodeSearch = async (value: any) => {

    if (value?.length !== 0) {
      try {

        setLoading(true);
        let response = await fetch(`${baseURL.baseURL}/bx_block_catalogue/search?category_id=1&query=${value}`,
        );
        const data = await response?.json();
        if (data?.products || data?.meta) {
          setNoproducts(false);
          if (data?.products?.data[0]?.id) {
            if(data?.products?.data[0]?.attributes.product_rating === 'NA' || null){
              handleCloseCam();
              // handleNotificationModalOpen();
              if (props?.history?.location?.pathname === "/norated") {
                await localStorage.setItem("NoRatedData", JSON.stringify(data?.products?.data[0]))                                                
                window.location.reload();
              }
              else {
                await localStorage.setItem("NoRatedData", JSON.stringify(data?.products?.data[0]))                                
                await props?.history?.push({pathname: '/norated',})
              }
              // setTimeout(()=>{
              //     window.location.reload();
              // },1800)
              // window.location.reload();
            }
            else {
            if (props?.history?.location?.pathname == "/ProductDetails") {
              if(data?.products?.data[0]?.attributes.product_rating === 'NA' || null){
                handleCloseCam();
                handleNotificationModalOpen();
                setTimeout(()=>{
                    window.location.reload();
                },1800)
                // window.location.reload();
              }
              else {

              
              window.location?.reload();
            }
            }
            else {
              props?.history?.push({
                pathname: '/ProductDetails',
                search: `${data?.products?.data[0]?.id}`,
                state: { searchName: `${data?.products?.data[0]?.id}` }
              });

              await window.location?.reload();
            }

            await localStorage.setItem("proId", data?.products?.data[0]?.id)
            setLoading(false);
            handleCloseCam();
          }
          }
        }
        else if (data?.errors) {
          if (props?.history?.location?.pathname == "/noproduct") {
            window.location?.reload();
          }
          else {
            props?.history?.push({
              pathname: '/noproduct',
              search: `${data?.errors}`,
              state: { searchName: `${data?.errors}` }
            });
            await window.location?.reload();
          }
          setNoproducts(true);
          handleCloseCam();

        }
      }
      catch (error) { console.error(error, "error while calling api"); }
    }

  };


  const handleOpenCam = () => {
    setCamModal(true);
    openCamFunc();
  }

  const handleCloseCam = async () => {
    setCamModal(false);
  }

  useEffect(() => {
    if (barCode) {
      closeCamFunc();
    }
  }, [barCode])


  const closeCamFunc = () => {

    let videoEl: any = document?.getElementById('video');
    let stream: any = videoEl?.srcObject;
    let tracks: any = stream?.getTracks();
    tracks?.forEach(function (track: any) {
      track?.stop();
    });
    // videoEl.srcObject = null;      
  }

  const openCamFunc = () => {

    navigator?.mediaDevices?.getUserMedia({ video: { width: 300, height: 300, facingMode: { exact: "environment" } } })
      .then((stream: any) => {
        video.current.srcObject = stream;
        video?.current?.play();
        const ctx = canvas?.current?.getContext("2d");
        // @ts-ignore
        const barcode: any = new window.BarcodeDetector(
          { formats: ['aztec', 'code_128', 'code_39', 'code_93', 'codabar', 'data_matrix', 'ean_13', 'ean_8', 'itf', 'pdf417', 'qr_code', 'upc_a', 'upc_e', 'unknown'] }
        )
        setInterval(() => {
          canvas.current.width = video?.current?.videoWidth;
          canvas.current.height = video?.current?.videoHeight;
          ctx?.drawImage(video?.current, 0, 0, video?.current?.videoWidth, video?.current?.videoHeight);
          barcode?.detect(canvas?.current)
            .then(async ([data]: any) => {
              if (data) {
                setTimeout(() => {
                  handleBarcodeSearch(data?.rawValue);
                  setBarCode(data?.rawValue);
                }, 500)

              }

            }).catch((err: any) => { console.log(err); });
        }, 100)

      })
      .catch((err: any) => { console.log(err) })
  }
  //Customise area stop
  const handleNotificationModalClose = () => {
    setOpenNotificationModal(false)
  }
  const handleNotificationModalOpen = () => {
    setOpenNotificationModal(true)
  }
  return (
    // Customise area start
    <>
      {/* Search */}
      <div>
        <div
          className={`${props.data === "TopNav" ? classes.search : classes.Homesearch}`}
          style={{
            // margin: 0,
            position: "relative",
          }}>
          <InputBase
            placeholder="Search any Packaged, Raw & Cooked food"
            classes={{ input: `${props.data === "TopNav" ? classes.inputInput : classes.HomeinputInput}` }}
            inputProps={{ "aria-label": "search" }}
            fullWidth
            value={searchTxt}
            onChange={(e) => {
              handleChange(e);
              setDropDown(true);
            }}

            onKeyPress={(e) => {

              dropDownClose();              
              if (e?.key === "Enter" && props?.history?.location?.pathname !== "/search") {
                // @ts-ignore
                localStorage.setItem("searchValue", e.target.value)
                props?.history?.push({
                  pathname: '/search',
                  // @ts-ignore
                  search: `${e.target.value}`,
                  // @ts-ignore
                  state: { searchName: `${e.target.value}` }
                });


              }
              else if (e?.key === "Enter" && props?.history?.location?.pathname === "/search") {

                if (!localStorage.getItem("searchValue")) { localStorage.setItem("searchValue", searchTxt) }
                else {
                  // @ts-ignore
                  localStorage.setItem("searchValue", e.target.value)
                }
              }
            }}
          />
          <div className={`${props.data === "TopNav" ? classes.voiceQrIcon : classes.HomevoiceQrIcon}`}>
            <img
              src={QRicon}
              alt="QR scanner Icon"
              onClick={() => {
                handleOpenCam();

              }}
              className={`${props.data === "TopNav" ? "" : classes.HomeVoiceQrIconStyle}`}
            />
            {
              listening && isUserClicked ? <StopIcon style={{ color: "#FF3939" }} fontSize={"small"}
                className={`${props.data === "TopNav" ? "" : classes.HomeVoiceQrIconStyle}`}

                onClick={() => {
                  SpeechRecognition.stopListening();
                  setIsUserClicked(false)
                }}
              /> :
                <img
                  src={VoiceSearch}
                  alt="VoiceSearch Icon"
                  className={`${props.data === "TopNav" ? "" : classes.HomeVoiceQrIconStyle}`}
                  onClick={() => {
                    if (!browserSupportsSpeechRecognition) {
                      alert("Oops....!!! This Browser is Not support")
                    }
                    else {
                      setIsUserClicked(true)
                      SpeechRecognition.startListening();
                    }
                  }}
                />}
          </div>

          {searchTxt?.length > 2 && (
            <div style={{ display: dropDown ? "block" : "none", }}
              className={`${props.data === "TopNav" ? classes.searchDropdown : classes.HomesearchDropdown}`} >

              {noProducts ?
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <img src={noProductFoundImg} alt="bgImage" className={classes.noProductImage} />
                  <Typography className={classes.noProductTitle}>No product found</Typography>
                </div> :

                <>
                  {loading ?
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress
                        style={{ width: "25px", height: "25px" }}
                      />
                    </div>
                    :
                    <>
                      {ResultTxt?.map((product: any, index: any) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              paddingLeft: `${props.data !== "TopNav" ? 20 : 5}`,
                              paddingRight: `${props.data !== "TopNav" ? 20 : 5}`,
                              cursor: "pointer",
                            }}
                            onClick={async () => {
                              if(product.attributes.product_rating === 'NA' || null){
                                if (props?.history?.location?.pathname === "/norated") {
                                  await localStorage.setItem("NoRatedData", JSON.stringify(product))                                
                                  await localStorage.setItem("reloadValue",searchTxt);
                                  window.location.reload();
                                }
                                else {
                                  await localStorage.setItem("NoRatedData", JSON.stringify(product))                                
                                  await props?.history?.push({pathname: '/norated',})
                                }
                                // handleNotificationModalOpen()                                
                              }else{
                                localStorage.setItem("proId", product?.id);
                                localStorage.setItem("reloadValue",searchTxt);
                                dropDownClose();
                                if (props?.history?.location?.pathname == "/ProductDetails") {                                
                                props?.history?.push({                                
                                  search: `${product?.id}`,                                  
                                });                                    
                                window.location.reload();
                              }
                                else {

                                  props?.history?.push({
                                    pathname: '/ProductDetails',
                                    search: `${product?.id}`,
                                    state: { searchName: `${product?.id}` }
                                  });                                  
                                }
                              }
                            }}
                            key={index}
                          >
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 5, }}>
                              <div style={{ marginTop: 10 }}>
                                <Typography
                                  style={{
                                    color: "#455A64",
                                    fontFamily: "Montserrat",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    lineHeight: "14px",
                                    letterSpacing: "0em",
                                    textAlign: "left",
                                  }}
                                >
                                  {product.attributes.product_name}                                  
                                </Typography>

                                <Typography
                                  style={{
                                    color: "#455A64",
                                    fontFamily: "Montserrat",
                                    fontSize: "8px",
                                    fontWeight: 600,
                                    lineHeight: "7px",
                                    textAlign: "left"
                                  }}
                                >
                                  Category:{product.attributes.category_type}
                                </Typography>
                              </div>

                              <ArrowRightAltIcon style={{ color: "#455A64" }} />
                            </div>
                          </div>
                        );
                      })}

                    </>
                  }
                </>
              }
            </div>
          )}
        </div>
      </div>
      {/* @ts-ignore */}
      <Modal
        open={camModal}
        onClose={() => { handleCloseCam(); closeCamFunc(); window.location.reload(); }}
        style={{
          width: 'fit-content',
          margin: "50px auto",
          zIndex:9999,
        }}>
        <Paper style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: 10 }}>
          <div>
            <video ref={video} autoPlay muted hidden id="video" />
            <canvas ref={canvas} />
          </div>
          <div>
            <Button onClick={() => { closeCamFunc(); handleCloseCam(); window.location.reload(); }} style={{ textTransform: "none", background: "#38006D", color: "#fff", marginTop: 5 }} size="small"
              variant="contained"
            >Stop</Button>
          </div>

        </Paper>

      </Modal>

      {/* dilogbox for null value */}
      <Dialog
        open={openNotificationModal} //openNotificationModal        
        aria-labelledby="form-dialog-title"
        BackdropProps={{ style: { background: 'linear-gradient(167.38deg, rgba(10, 0, 48, 0.89) 3.11%, rgba(38, 2, 37, 0.89) 91.84%' } }}
        style={{ zIndex: 9999 }}
      >
        <div>
          <MuiDialogTitle disableTypography>

            <IconButton aria-label="close" className={classes.closeButton} onClick={handleNotificationModalClose}>
              <CloseIcon style={{ fontSize: '13px' }} />
            </IconButton>

          </MuiDialogTitle>

          <DialogContent style={{ textAlign: 'center' }}>

            <Typography className={classes.notificationText}>Please select a product with N<sup>IQ </sup>score</Typography>
          </DialogContent>

        </div>
      </Dialog>

    </>
    //Customise area stop  
  );
};
export default withRouter(CustomSearch);
