import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';
import { truncate } from 'fs';

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  pageHandler: any;
  data: any;
  PaginationWeb: any;
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  loading: boolean;
  foodFilter: any;
  searchResult: any
  pagenationData: any;
  paginationRemove: any
  filteredSearchResults: any;
  openNotificationModal: any;
  filteredFoods:any;
  filterCategory:any;
  noRatedArray:any;
  // Customizable Area End
}

interface SS {
  id: any;
  PaginationWeb: any;
}

export default class SearchControllerWeb extends BlockComponent<Props, S, SS> {
  getSearchApiCallID: string = "";
  getFilterCategoryApiCallID: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: '',
      // Customizable Area Start
      loading: false,
      foodFilter: "Packaged Food",      
      searchResult: "",
      pagenationData: {},
      paginationRemove: false,
      filteredSearchResults: [],
      openNotificationModal: false,
      filteredFoods:{},
      filterCategory:[],
      noRatedArray:{},
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    if(this.props?.navigation?.history?.location?.pathname === "/search") {    
    const searchValue:any = localStorage.getItem("searchValue") == "" ? this.props.navigation.history.location.search.substring(1) :  localStorage.getItem("searchValue")
    this.props.navigation.history.location.search
    this.getFilterCategory();
    document.addEventListener('keydown', (e: any) => this.keyFun(e))
    await this.setState({ searchResult: searchValue })
    await this.getSearchProducts(this.state.searchResult);    
    this.props.navigation?.history?.push({
      search:searchValue
    })
  }
  if(this.props?.navigation?.history?.location?.pathname === "/norated") {
    const noRatedData:any = localStorage.getItem("NoRatedData") ? localStorage.getItem("NoRatedData") : ""
    await this.setState({noRatedArray:JSON.parse(noRatedData)});
    console.log(this.state.noRatedArray, "__data__check")
  }
  }
  // Customizable Area Start
  keyFun = async (event: any) => {
    if (event.code == 'Enter' && this.props?.navigation?.history?.location?.pathname === "/search") {      
      this.setState({ loading: true })      
      await this.componentDidMount()
    }
  }

  getSearchProducts = (value: any, pageNO: any = 1) => {
    this.setState({ loading: true })
    // const userToken = localStorage.getItem("token");
    const header = {
      'Content-Type': 'application/json',
      // token: userToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSearchApiCallID = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getSearchProductsEndPoint}?category_id=1&query=${value}&page=${pageNO}`);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  // Function to call the Filter Category API
  getFilterCategory = () => {
    this.setState({ loading: true })
    const header = {'Content-Type': 'application/json',};
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFilterCategoryApiCallID = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getFilterCategoryEndPoint);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  async receive(from: string, message: Message) {

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getSearchApiCallID &&
      this.getSearchApiCallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loading: false });
      if (responseJson?.products || responseJson?.meta) {
        this.setState({ pagenationData: responseJson?.meta });
        this.SearchResultsCategoryFunction(responseJson.products)
      }
      else { this.props.navigation.navigate("NoProudctFound") }

    }
    else { this.setState({ loading: false }) }

    // Get the Response From the Filter Category API
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getFilterCategoryApiCallID &&
      this.getFilterCategoryApiCallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var categoryResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loading: false });
      this.setState({filterCategory: categoryResponseJson.data})
    }
    else { this.setState({ loading: false }) }


  }

  goToNoRatedPage = async (e:any) => {        
    
    await localStorage.setItem("NoRatedData", JSON.stringify(e))
    await this.props?.navigation?.navigate("NoRated");
    // console.log(this.props?.navigation?.history, "___data__check")
    // console.log(e, "___data__check")
  }

  filterChange = async (e: any) => {
    await this.setState({ foodFilter: e })
    await this.setState({ filteredSearchResults: this.state.filteredFoods[e] })
    return;
  }

  SearchResultsCategoryFunction = async (inComRx: any) => {
    if (inComRx) {
      let values = inComRx;
      let filteredCategoryFoods: any = {};
      await this.setState({ filteredSearchResults: values?.data })

      this.state.filterCategory.map((cat_type:any)=>{  
        const items = values?.data.filter((cat_item:any)=>{
            return cat_item?.attributes?.category_type === cat_type?.attributes.category_type })
            filteredCategoryFoods[cat_type?.attributes.category_type] = items })
      this.setState({filteredFoods: filteredCategoryFoods})
      
    }
  }

  handlePageClick = async (pageNumber: any) => {
    let num = pageNumber.selected + 1
    await this.getSearchProducts(this.state.searchResult, num,);    
  }

  searchAnotherProductButton = () => {
    this.props.navigation.navigate("Home");
    window.scrollTo({ top: 0 });
  }
  Removepageniation(event: any) {
    if  (this.state?.filteredFoods[event]?.length === 0) {this.setState({ paginationRemove: true })}
    else {this.setState({ paginationRemove: false })}
  }

  handleNotificationModalClose = () => {
    this.setState({
      openNotificationModal: false
    })
  }

  handleNotificationModalOpen = () => {
    this.setState({
      openNotificationModal: true
    })
  }

  // function for navigate to the product details page with GetProductDetailsAdapter {adapter} {add by rifadulDev}
  goToProductDetailsPage = (id: any) => {
    localStorage.setItem('proId', id)
    const msg: Message = new Message(getName(MessageEnum.FromProductPageNavigationMessage)); // here nedd to change messageEnum name {adapter} {add by rifadulDev}
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), id); // here nedd to change value {adapter} {add by rifadulDev}
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "ProductDetails"); // here need to change Route name
    this.send(msg);
    // window.location.reload()
  }

  // Customizable Area End
}