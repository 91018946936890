import React from 'react';
// Customizable Area Start
import { Typography,Select,MenuItem, FormControl } from '@material-ui/core';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { heroTopImage,LandingPageBgImage } from './assets';
import CustomSearch from '../../../components/src/CustomSearch';


const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
    },

    hero__section: {
      height: '100vh',
      marginTop:"64px",
      background: `linear-gradient(109.52deg, rgba(56, 0, 111, 0.82) 55.17%, rgba(255, 57, 57, 0.82) 111.06%), url(${LandingPageBgImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      [theme.breakpoints.down('xs')]: {
        marginTop:54,
      },
    },

    hero__image: {
        margin:"auto",
        height:"auto",
        width:160,
      [theme.breakpoints.down('md')]: {
        width:140,
      },
      [theme.breakpoints.down('sm')]: {
        width:120,
      },
      [theme.breakpoints.down('xs')]: {
        width:100,
      },
    },

    hero__content: {
      width: '100%',
      height:"100%",
      textAlign: 'center',
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      justifyContent:"center",
      },

    hero_text: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '19px',
      lineHeight: '43px',
      color: '#FFFFFF',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
        lineHeight: '30px',
      },
    },
    
    supText: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
    },
    MakeNutritionTextContainer:{
      display:"flex",
       justifyContent:"space-between",
       alignItems:"center",
       columnGap:50,
       [theme.breakpoints.down("xs")]: {
        flexDirection:"column",
      },
    },
    MakeNutritionText:{
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '21px',
      lineHeight: '26px',
      textAlign: 'center',
      color: '#FFFFFF',
      [theme.breakpoints.down("sm")]: {
        fontSize: '18px',
        lineHeight: '21px',
      },
    },
    icon: {
      fill: '#fff',
   },

    
  });
// Customizable Area End

import HomePageController, { Props } from './HomepageController';
import HomePageDetailsWeb from './HomePageDetails.web';
class LandingPage extends HomePageController {
  constructor(props: Props) {
    super(props);
    }
    

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.hero__section}>
          <div className={classes.hero__content}>
            
            <div style={{padding:40}}>
            <img src={heroTopImage} alt="" className={classes.hero__image} />
            </div>
            <div style={{padding:10}}>  
            <Typography className={classes.hero_text}>
              Assess the N<sup className={classes.supText}>IQ</sup> score
              (nutritive value) of any Packaged, Raw or Cooked food
            </Typography>
            </div>

             <div style={{padding:15, zIndex:1}}>
             {/* @ts-ignore */}
              <CustomSearch />
            </div>  
            
            <div style={{padding:10,}} className={classes.MakeNutritionTextContainer}>
           <Typography className={classes.MakeNutritionText}>
            Make nutrition easy
            </Typography>
            <Typography className={classes.MakeNutritionText}>
            Make smarter food choices
            </Typography>
            </div>

            <div style={{padding:40}}>
            <FormControl>
            <Select
            disableUnderline
              value={this.state.selectedLanguage}
              onChange={(e:any)=>{this.handleLanguageChange(e)}}
              inputProps={{
                name: 'agent',
                id: 'age-simple',
                  classes: {
                      icon: classes.icon,
                  },
            
              }}
              style={{ color: '#ffff',
              fontFamily: 'Montserrat',
              fontStyle: "normal",
              fontWeight: 500, }}
              
            >
              {this.state.languageList.map((value:any, index:any) => {
                return (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
            </div>

          </div> 
        </div>
        {/* @ts-ignore */}
        <HomePageDetailsWeb/>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(LandingPage);

