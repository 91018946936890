import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, List, ListItem, ListItemIcon, ListItemText, IconButton, Divider } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from "react-router-dom";
import { classNames } from 'react-select/src/utils';
import { topHeaderBanner, sideBarBanner, profileImage } from './assets';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
// import {location} from react-router-dom

const useStyles = makeStyles((theme) => ({
    // navigation side bar 
    // side nav section
    profileContainer: {
        padding: '4rem 0',
    },
    userNameText: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '22px',
        color: '#FFFFFF',
        [theme.breakpoints.only("md")]: {
            fontSize: '18px',
        },
    },
    userAccountText: {
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '20px',
        color: '#FED600'
    },

    sideNavText: {
        backgroundColor: 'transparent',
        fontFamily: 'DM Sans!important',
        fontWeight: 400,
        fontSize: '18px',
    },

    active: {
        color: '#FB951A'
    },
    normal: {
        color: 'white',
    }
}));

const SideNavbar = () => {
    const classes = useStyles();

    const siteNavList = [
        {
            pathName: '#',
            navName: 'My Profile',
            icon: <PersonOutlineIcon />
        },
        {
            pathName: '#',
            navName: 'BMI Calculator',
            icon: <AccessibilityIcon />
        },
        {
            pathName: '#',
            navName: 'Nutrition Gap Calculator',
            icon: <AssignmentIcon />
        },
        {
            pathName: '/HealthyFoodBasket',
            navName: 'Healthy Food Basket',
            icon: <ListAltIcon />
        },
        {
            pathName: '#',
            navName: 'Health Points',
            icon: <LocalHospitalIcon />
        },
        {
            pathName: '#',
            navName: 'Favourite Products',
            icon: <FavoriteBorderIcon />
        },
        {
            pathName: '#',
            navName: 'Preferences',
            icon: <SettingsApplicationsIcon />
        },
        {
            pathName: '#',
            navName: 'Submit a Product',
            icon: <StoreMallDirectoryIcon />
        },

    ]


    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className={classes.profileContainer}
            >
                <div>
                    <img src={profileImage} alt="Siam" />
                </div>
                <Typography className={classes.userNameText}>Siam Khushnobish</Typography>
                <Typography className={classes.userAccountText}>My account</Typography>
            </Grid>
            <Divider style={{ backgroundColor: 'white', marginBottom: '2.1rem' }} />
            <div>
                {
                    siteNavList.map((item: any, key: any) => {
                        return (
                            <Link to={item.pathName} style={{ textDecoration: "none" }} key={key}>
                                <Grid
                                    container spacing={3}
                                    alignItems="center"
                                    style={
                                        {
                                            padding: '12px 5px',
                                            cursor: 'pointer',
                                        }
                                    }

                                    className={item.pathName === window.location.pathname ? classes.active : classes.normal}
                                >
                                    <Grid item xs={2}>
                                        {item.icon}
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography className={classes.sideNavText}>{item.navName}</Typography>
                                    </Grid>
                                </Grid>
                            </Link>
                        )
                    })
                }
            </div>
            {/* <Divider style={{ backgroundColor: 'white' }} /> */}

        </>
    )
}

export default SideNavbar;