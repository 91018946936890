import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { threadId } from 'worker_threads';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  languageList:any
  selectedLanguage:any;
  isSearchBar:Boolean;
  searchBarCtrl:any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HomePageController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: '',
      languageList:[
        'English',
        'Hindi',
        'Arebic',
        'Franch',
      ],
      selectedLanguage:'English',
      isSearchBar:false,
      searchBarCtrl:"hello",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // console.log(this.props.navigation, "___sai___")
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      'Content-Type': configJSON.productApiContentType,
      // token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Recived', message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      // let token = localStorage.getItem('authToken');
      this.setState({ token: token });
      this.getListRequest(token);
    }

    // if (
    //   getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    //   this.getProductApiCallId != null &&
    //   this.getProductApiCallId ===
    //     message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    // ) {
    //   var responseJson = message.getData(
    //     getName(MessageEnum.RestAPIResponceSuccessMessage)
    //   );
    //   if (responseJson && !responseJson.errors && responseJson.data) {
    //     this.setState({ arrayHolder: responseJson.data });
    //     runEngine.debugLog('arrayHolder', this.state.arrayHolder);
    //   } else {
    //     var errorReponse = message.getData(
    //       getName(MessageEnum.RestAPIResponceErrorMessage)
    //     );
    //     this.parseApiCatchErrorResponse(errorReponse);
    //   }
    // }
    // Customizable Area End
  }

  // Customizable Area Start
  handleLanguageChange = (e:any) => {
    this.setState({ selectedLanguage: e.target.value });
  };
  handleSearchBar = () => {
    this.state.isSearchBar ? 
    this.setState({isSearchBar: false }) :
    this.setState({isSearchBar: true})
  }

  handleSearchBarOpen = () => {
    this.setState({isSearchBar: true,});  
  }
  handleSearchBarClose = async() => {
    await this.handleSearchBarProps();
    await this.setState({isSearchBar: false,searchBarCtrl: ""})
  }
  handleSearchBarProps = () => {
    this.setState({searchBarCtrl: "HomeSearchBar"});
  }
  sentProps = (value:any) => {
    this.setState({searchBarCtrl: value});
  }


  screenTop = () => {
    window.scrollTo({ top: 0 })
  }
  
  

  // Customizable Area End
}
