// Customizable Area Start
import React, { Component } from "react";
import { Typography, Grid, Card, CardMedia, CardContent, Avatar, Button, Box, DialogContent, Dialog } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import SearchControllerWeb, { Props, configJSON } from "./SearchControllerWeb";
import { searchBgImage, DrumstickImage, NoProductFoundImg } from "./assets";
import NoProductWeb from "./NoProduct.web";
import ReactPaginate from 'react-paginate';
import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: 10,
      [theme.breakpoints.up(600)]: {
        marginTop: 64,
      },
      [theme.breakpoints.down(600)]: {
        marginTop: 48,
      },
      [theme.breakpoints.down(515)]: {
        marginTop: 56,
      },
    },

    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "baseline",
      height: "auto",
      margin: 50,
      [theme.breakpoints.down("lg")]: {
        margin: 40,
      },
      [theme.breakpoints.down("md")]: {
        margin: 25,
      },
      [theme.breakpoints.down("sm")]: {
        margin: 10,
      },
    },

    sideCompContainer: {
      height: "100%",
      width: 250,
      [theme.breakpoints.down("sm")]: {
        width: 200
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },

    filterContainer: {
      background: `linear-gradient(180.37deg, #38006F 0.32%, #B25843 232.68%)`,
      boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.12)",
      borderRadius: "8px",
      marginBottom: 25,
      height: 200,
      width: "100%",
    },

    filterTitle: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      letterSpacing: "0.03em",
      color: "#FFE601",
      padding: 20,
    },

    FoodTypeUnderline: {
      textUnderlineOffset: "0.5rem",
      textDecoration: "underline",
      textDecorationColor: "#FB951A",
    },

    filterNames: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "10px",
      lineHeight: "12px",
      color: "#FFFFFF",
    },

    AdvertisementContainer: {
      height: "541px",
      background: `linear-gradient(180.37deg, #38006F 0.32%, #B25843 232.68%)`,
      boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.12)",
      borderRadius: "8px",
      display: "block",
    },

    AdvertisementTitle: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "34px",
      letterSpacing: "0.03em",
      color: "#FFFFFF",
      position: "relative",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },


    },

    rootProductsContainer: {
      width: "100%",
    },

    textContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginLeft: 25,
      marginRight: 25,
      [theme.breakpoints.down("sm")]: {
        alignItem: "baseline",
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: 5,
        marginRight: 5,
      },
    },
    searchResultText: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "22px",
      lineHeight: "33px",
      color: "#37006E",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        lineHeight: "12px",
      },

    },
    totalResultText: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "17px",
      lineHeight: "12px",
      color: "#38006D",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",

      },
    },
    productsContainer: {
      margin: 20,
      display: "flex",
      justifyContent: "center",
      maxHeight: 640,
      overflow: "hidden",
      overflowY: "scroll",
      '&::-webkit-scrollbar': {
        width: '5px',
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,0.00)',

      }
    },
    media: {
      height: 100,
      width: 200

    },
    niqAvatarA: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      backgroundColor: "#00AB79",
    },
    productName: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "15px",
      color: "#455A64",
      textAlign: "left",
      Maxwidth: 300,
      height: 50,
      webkitLineClamp: 2,
      webkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: "24px",



    },
    pagenation: {
      marginTop: "auto",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
      },


    },
    filterScroll: {
      marginRight: "2px",
      height: 95,
      overflowY: "scroll",
      '&::-webkit-scrollbar': {
        width: '5px',
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#DFDEDE',

      }
    },
    pagenationContainer: {
      display: "flex",
      // alignItem:"center",
      // justifyContent:"center",
      listStyleType: "none",
    },
    pageClassName: {
      color: "#808080",
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '117.5%',
      textAlign: 'center',
      cursor: "pointer",
      display: "flex",
    },
    pageLinkClassName: {
      cursor: "pointer",
      margin: 5,
    },
    previousClassName: {
      background: "#FFFFFF",
      border: "0.2px solid #AAA7A7",
      borderRadius: "2px",
      cursor: "pointer",
      padding: 4
    },
    previousLinkClassName: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 600,
      fonSsize: '10px',
      lineHeight: '117.5%',
      textAlign: "center",
      color: "#000000",
    },
    activeClassName: {
      color: "#05727D",
    },
    disabledLinkClassName: {
      color: '#ADADAD',
    },
    noProductContainer: {

      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: 'center',
      paddingTop: 50,
      paddingBottom: 50,

      [theme.breakpoints.down("lg")]: {
        paddingTop: 40,
        paddingBottom: 40,
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: 25,
        paddingBottom: 25,
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: 15,
        paddingBottom: 15,
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: 5,
        paddingBottom: 5,
      },
    },
    noProductImage: {
      width: 200,
      height: 250,
      [theme.breakpoints.down("md")]: {
        width: 150,
        height: 200,
      },
      [theme.breakpoints.down("xs")]: {
        width: 100,
        height: 150,
      },
    },
    noProductTitle: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '25px',
      lineHeight: "117.5%",
      color: '#FF3939',
      textAlign: "center",
      padding: 50,
      [theme.breakpoints.down("lg")]: {
        padding: 35,
      },
      [theme.breakpoints.down("md")]: {
        padding: 25,
        fontSize: '20px',
      },
      [theme.breakpoints.down("sm")]: {
        padding: 15,
      },
      [theme.breakpoints.down("xs")]: {
        padding: 10,
        fontSize: '16px',
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: '#455A64',
      border: '1px solid #455A64',
      padding: '2px'

    },
    notificationText: {
      textAlign: 'center',
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "33px",
      margin: '0rem 0rem 2rem 0rem',
      color: "#455A64",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        lineHeight: "12px",
      },
    },

// Filter responsive
filterContainerRes: {
  display:"none",
  [theme.breakpoints.down("xs")]: {
  background: `linear-gradient(180.37deg, #38006F 0.32%, #B25843 232.68%)`,
  boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.12)",
  borderRadius: "8px",
  height: 35,
  width: "100%",
  marginTop:20,
  display:"flex",
  alignItems:"center",
  
},
},

filterTitleRes: {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "14px",
  letterSpacing: "0.03em",
  color: "#FFE601",
  padding: 20,
  width:"inherit",
  textAlign:"left"
},

FoodTypeUnderlineRes: {
  textUnderlineOffset: "0.5rem",
  textDecoration: "underline",
  textDecorationColor: "#FB951A",
},

filterNamesRes: {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10px",
  lineHeight: "12px",
  color: "#FFFFFF",
  width:"max-content",
},
filterScrollRes: {
  display:"flex",
  marginRight:"2px",
  // height:95,
  width:"inherit",
  overflowX:"scroll",
  '&::-webkit-scrollbar': {
    height: '1px',   
  },
  '&::-webkit-scrollbar-track': {
    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#DFDEDE',
    
  }
},

// Filter responsive


  });

class Search extends SearchControllerWeb {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.root}>

          {/* Banner Image*/}
          <img src={searchBgImage} alt="bgImage" style={{ width: "100%" }} />

          <div className={classes.container}>

            {/* filter & advertisement components */}
            <div className={classes.sideCompContainer}>
              <div className={classes.filterContainer} >
                <Typography className={classes.filterTitle}>
                  <span className={classes.FoodTypeUnderline}>Food </span>Type
                </Typography>


                <div className={classes.filterScroll}>
                  {this.state?.filterCategory?.map((filterType: any, index: any) => {
                    return (
                      <div key={index}
                        onClick={() => {
                          this.filterChange(filterType?.attributes?.category_type);
                          this.Removepageniation(filterType?.attributes?.category_type);
                        }}
                        style={{ display: "flex", alignItems: "center", marginBottom: 10, cursor: "pointer", }}>

                        <div
                          style={{
                            width: 10,
                            height: 10,
                            border: "1px solid #fff",
                            background: this.state.foodFilter == filterType?.attributes?.category_type ? "#FCC300" : "none",
                            borderRadius: 1,
                            marginTop: 10,
                            marginLeft: "10%",
                          }}

                        />
                        <div style={{ marginTop: 10, marginLeft: "10px" }}>
                          <Typography className={classes.filterNames}>
                            {filterType?.attributes?.category_type}({this.state?.filteredFoods[filterType?.attributes?.category_type]?.length || 0})
                          </Typography>
                        </div>
                      </div>
                    )
                  })}

                </div>

              </div>

               <div className={classes.AdvertisementContainer}>
                <Typography
                  component={"div"}
                  align="center"
                  variant="subtitle1"
                  className={classes.AdvertisementTitle}
                >
                  Advertisement
                </Typography>
              </div> 
              
            </div>


            <div className={classes.rootProductsContainer}>

              <div className={classes.textContainer}>
                <Typography className={classes.searchResultText}>
                  <span className={classes.FoodTypeUnderline}>Search Res</span>ult
                </Typography>
                <Typography className={classes.totalResultText}
                 style={{ display: `${this.state.paginationRemove === true ? "none" : "block"}` }}
                >
                  Total result: {this.state.pagenationData?.pagination?.total_count || 0}
                </Typography>
              </div>

              {/* Filter Container  Responsive*/}

              <div className={classes.filterContainerRes} >
              <Typography className={classes.filterTitleRes}>
                <span className={classes.FoodTypeUnderlineRes}>Food </span>Type
              </Typography>
              
              
              <div className={classes.filterScrollRes} style={{marginRight:10}}>  
              {this.state?.filterCategory?.map((filterType:any, index:any)=>{
                return(
                  <div key={index} 
                  onClick={()=>{
                    this.filterChange(filterType?.attributes?.category_type);
                    this.Removepageniation(filterType?.attributes?.category_type);
                  }}
                  style={{display: "flex",alignItems: "center",marginBottom: 10,cursor:"pointer",}}>
                  
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      border: "1px solid #fff",
                      background: this.state.foodFilter == filterType?.attributes?.category_type ? "#FCC300" : "none",
                      borderRadius: 1,
                      marginTop: 10,
                      marginLeft: 10,
                      
                    }}
                    
                  />
                  <div style={{ marginTop: 10, marginLeft: "10px", display:"flex" }}>
                    <Typography className={classes.filterNamesRes}>
                    {filterType?.attributes?.category_type}({this.state?.filteredFoods[filterType?.attributes?.category_type]?.length || 0})
                    </Typography>
                  </div>
                </div>
                )
              })}
              
              </div>

            </div>
{/* Filter Container Responsive */}



              <div style={{ display: "flex", flexDirection: "column", alignContent: "space-between", height: 720 }}>

                <div className={classes.productsContainer} >
                
                  {this.state?.filteredFoods[this.state.foodFilter]?.length === 0 ? <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", }}>
                    <div className={classes.noProductContainer}>
                      <img src={NoProductFoundImg} alt="bgImage"
                        className={classes.noProductImage} />
                      <Typography className={classes.noProductTitle}>No product found</Typography>
                    </div>
                  </div> :
                    <>
                      {this.state?.loading ? <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", height: 400 }}>
                        <CircularProgress style={{ width: "50px", height: "50px" }} />
                      </div> :

                        <Grid container justifyContent={"space-evenly"} alignItems={"center"}>

                          {this.state?.filteredSearchResults && this.state?.filteredSearchResults?.map((e: any, index: any) => {
                            //this.handleNotificationModalClose
                            return (
                              <Grid item style={{ padding: 10, width: 220 }} key={index}>
                                <Card style={{ boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.12)", borderRadius: 6, cursor: "pointer", }}
                                  // onClick={() => e?.attributes?.product_rating === 'NA' ? this.handleNotificationModalOpen() : this.goToProductDetailsPage(e?.attributes?.id)}>
                                    onClick={() => {
                                      e?.attributes?.product_rating === 'NA' ?
                                      //  this.handleNotificationModalOpen() 
                                      this.goToNoRatedPage(e)
                                       : this.goToProductDetailsPage(e?.attributes?.id);
                                      console.log(this.props.navigation, "hhh")
                                      
                                      }}> 

                                  {e?.attributes?.image ?
                                    <CardMedia component="img" className={classes.media} image={e?.attributes?.image}
                                      alt={e?.attributes?.product_name} /> : <Skeleton variant="rect" width={200} height={100} />}

                                  <CardContent style={{ display: "flex", justifyContent: "space-around", }}>

                                    {e?.attributes?.product_name ? <Typography
                                      variant="subtitle1"
                                      component="p"
                                      className={classes.productName}>
                                      {e?.attributes?.product_name}
                                    </Typography> :
                                      <Skeleton variant="text" style={{ width: 100, height: 50, }} />}

                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

                                      {e?.attributes?.product_rating ?
                                        <Avatar
                                          aria-label="recipe"
                                          className={classes.niqAvatarA}
                                          style={{
                                            fontSize: 14, background: e.attributes.product_rating === "A" ? "#00AB79" :
                                              e.attributes.product_rating === "B" ? "#AAC83C" :
                                                e.attributes.product_rating === "C" ? "#FCC300" :
                                                  e.attributes.product_rating === "D" ? "#FF784B" :
                                                    e.attributes.product_rating === "E" ? "#FF5050" : "#555454"
                                          }}
                                        >{e?.attributes?.product_rating} </Avatar>
                                        : <Skeleton variant="circle" width={25} height={25} />}
                                      <Typography
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          fontSize: "9px",
                                          textAlign: "left",
                                          color: "#757D8A",
                                          marginTop: 5,
                                        }}>
                                        N<sup>IQ</sup>&nbsp;Score
                                      </Typography>
                                    </div>
                                  </CardContent>

                                </Card>
                              </Grid>
                            );

                          })}
                        </Grid>
                      } </>}
                </div>

                {/* Pagination */}
                <div className={classes.pagenation}
                  style={{ display: `${this.state.paginationRemove === true ? "none" : "flex"}` }}
                >

                  {/* @ts-ignore */}
                  <ReactPaginate
                    previousLabel="Prev."
                    breakLabel="..."
                    nextLabel="Next"
                    pageCount={this.state.pagenationData?.pagination?.total_pages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={1}
                    onPageChange={this.handlePageClick}
                    containerClassName={classes.pagenationContainer}
                    pageClassName={classes.pageClassName}
                    pageLinkClassName={classes.pageLinkClassName}
                    previousClassName={classes.previousClassName}
                    previousLinkClassName={classes.previousLinkClassName}
                    nextClassName={classes.previousClassName}
                    nextLinkClassName={classes.previousLinkClassName}
                    breakClassName={classes.pageClassName}
                    breakLinkClassName={classes.pageLinkClassName}
                    activeClassName={classes.activeClassName}
                    disabledLinkClassName={classes.disabledLinkClassName}

                  />
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* dilogbox for null value */}
        <Dialog
          open={this.state.openNotificationModal}
          aria-labelledby="form-dialog-title"
          BackdropProps={{ style: { background: 'linear-gradient(167.38deg, rgba(10, 0, 48, 0.89) 3.11%, rgba(38, 2, 37, 0.89) 91.84%' } }}
        >
          <div>
            <MuiDialogTitle disableTypography>

              <IconButton aria-label="close" className={classes.closeButton} onClick={this.handleNotificationModalClose}>
                <CloseIcon style={{ fontSize: '13px' }} />
              </IconButton>

            </MuiDialogTitle>

            <DialogContent style={{ textAlign: 'center' }}>

              <Typography className={classes.notificationText}>Please select a product with N<sup>IQ </sup>score</Typography>
            </DialogContent>

          </div>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Search);
// Customizable Area End
