import React from 'react';
import Loader from '../../../components/src/Loader';
import ProductDetailsScreenWebController, { Props } from './ProductDetailsScreenWebController';
import { product_bg,  ReportThis, Compare, rightArrow, LeftArrow,scaleFirstPart,ratingScaleImg } from './assets';
import './productDetailsPagestyle.css'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import {
    Grid,
    Paper,
    Button,
    Typography,
    Tooltip, Link
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import ProductDetailsPageFAQ from './ProductDetailsPageFAQ';
import Products from './Products';
import HealthyFoodBasketButton from './HealthyFoodBasketButton';
import ProductRatingToolTip from './ProductRatingToolTip';
import { G } from 'react-native-svg';
import GoodNotSoGoodWeb from './goodNotSoGood.web';


const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: '6rem 1rem',
            [theme.breakpoints.only("xs")]: {
                margin: '4rem 1rem',
            },
            [theme.breakpoints.only("sm")]: {
                margin: '5rem 1rem',
            },
            [theme.breakpoints.only("md")]: {
                margin: '6rem 1rem',
            },
        },

        // common css for text front
        productTextPoppins500: {                                  
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500
        },
        productTextPoppins600: {
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 600
        },
        productTextMontserrat600: {
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600
        },

        // css for backBtnDiv css
        backBtnDiv: {
            width: '100%',
        },

        productDteailsContent: {
            justifyContent: "center",
            // alignItems: "center",
            // marginTop: '6rem',
            marginBottom: '3rem'
        },
        productImageGrid:{            
            width: '100%',
// backgroundImage: `url(${product_bg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: '10px',            
        },
        // css for product image
        productImageContent: {
            // backgroundImage: `url(${product_bg})`,
            // backgroundRepeat: 'no-repeat',
            // backgroundSize: 'cover',
            // margin: '1rem',
            // height: '427.74px',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px',
        },
        productImageDiv: {
            width: '260px',
            height: '260px',
            backgroundColor: 'white',
            backgroundRepeat: 'no-repeat',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.only("xs")]: {
                width: '200px',
                height: '200px',
            },
            [theme.breakpoints.between("sm", 'md')]: {
                width: '250px',
                height: '250px',
            },
        },
        productImageBorder: {
            border: "double 5px transparent",
            borderRadius: '50%',
            backgroundImage:
                "linear-gradient(white, white), linear-gradient(200deg,#41479B,#FF3939)",
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
            [theme.breakpoints.down("sm")]: {
                marginLeft: "auto",
                marginRight: "auto",
            },
        },


        // css for product details
        productNameText: {
            color: '#455A64',
            fontSize: '28px',
            [theme.breakpoints.only("xs")]: {
                fontSize: '15px',
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: '18px',
            },
            [theme.breakpoints.only("md")]: {
                fontSize: '20px',
            },
            [theme.breakpoints.only("lg")]: {
                fontSize: '23px',
            },
        },
        productTypeText: {
            color: '#FF5050',
            fontSize: '17px',
            margin:'10px 0',
            [theme.breakpoints.only("xs")]: {
                fontSize: '12px',
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: '13px',
            },
            [theme.breakpoints.only("md")]: {
                fontSize: '15px',
            },
            [theme.breakpoints.only("lg")]: {
                fontSize: '17px',
            },
        },

        productCategory: {
            // marginBottom: 34,
            border: "double 2px transparent",
            borderRadius: 15,
            backgroundImage:
                "linear-gradient(white, white), linear-gradient(360deg,#440A70,#FF3939)",
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
            [theme.breakpoints.down("sm")]: {
                marginBottom: 16,
                marginLeft: "auto",
                marginRight: "auto",
            },
        },
        productAction: {
            margin: ' 0 auto',
            border: "double 2px transparent",
            // width: '50%',
            width:100,
            borderRadius: 65,
            height: '350px',
            backgroundImage:
                "linear-gradient(white, white), linear-gradient(360deg,#440A70,#FF3939)",
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
            flexDirection: 'column',
            [theme.breakpoints.down("sm")]: {
                width: '100%',
                height: '100px',
                flexDirection: 'row',
            },
            [theme.breakpoints.only("md")]: {
                // width: '95%',
                height: '340px',
            },
        },
        actionItem: {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',        
        },

        child_1: {
            height: '70px',
            width: '70px',

            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto',
            [theme.breakpoints.only('xs')]: {
                height: '35px',
                width: '35px',
            },
            [theme.breakpoints.only('sm')]: {
                height: '45px',
                width: '45px',
            },
            [theme.breakpoints.only('md')]: {
                height: '50px',
                width: '50px',
            },
            [theme.breakpoints.only('lg')]: {
                height: '60px',
                width: '60px',
            },

        },
        
        childTextStyle: {
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: '18px'
        },

        scaleText: {
            fontFamily: `'Poppins', sans-serif`, fontWeight: 500, fontSize: '11px',
            [theme.breakpoints.only("xs")]: {
                // fontSize: '10px',
            },
            [theme.breakpoints.only("sm")]: {
                // fontSize: '14px',
            },
            [theme.breakpoints.only("md")]: {
                // fontSize: '12px',
            },
        },
        scaleTextHighest: {
            color: '#008D59',
        },
        scaleTextLowest: {
            color: '#FF3939'
        },
        niqTextContent: {
            textAlign: 'center',
            marginTop: '3rem',
            marginBottom: '1.5rem'
        },

        niqScoreText: {
            color: '#968C8C'
        },
        nutValueConteiner: {
            marginTop: '0.5rem',
            marginBottom: '2rem'
        },
        nutValueText: {
            padding: '0 1rem',
            color: '#455A64'
        },
        // -----------

        perGramText: {
            textAlign: 'center',
            color: '#38006D',
            padding: '10px 0',
        },
        ProductProgressBarContainer: {
            padding: '0 2rem',
            [theme.breakpoints.down("sm")]: {
                padding: '0 1rem',
            },
        },

        productGoodQualityText: {
            textAlign: "center",
            color: '#0EAA58',
        },
        productBadQualityText: {
            textAlign: "center",
            color: '#EF502E',
        },

        ProductProgressStatus: {
            marginBottom: 34,
            border: "double 2px transparent",
            borderRadius: 15,
            backgroundImage:
                "linear-gradient(white, white), linear-gradient(360deg,#440A70,#FF3939)",
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
            [theme.breakpoints.down("sm")]: {
                marginBottom: 16,
                marginLeft: "auto",
                marginRight: "auto",
            },
        },

        progressBar: {
            marginBottom: '2rem'
        },
        progress: {
            backgroundColor: '#D5D1D1',
            width: '100%',
            borderRadius: '20px',
            margin: '1rem 0'
        },
        progressContent: {
            borderRadius: '20px'
        },
        progressText: {
            color: '#FFFFFF',
            paddingLeft: '1rem'
        },
        progressBarText: {
            color: '#968C8C', fontFamily: `'Poppins', sans-serif`, fontWeight: 500, fontSize: '17px',
            [theme.breakpoints.down("sm")]: {
                fontSize: '14px'
            },
        },
        spaceTopBottom: {
            margin: '3rem 0'
        },
        // css for button
        productButtonContainer: {
            display: "flex",
            justifyContent: "center",
            marginTop: '5rem',
            marginBottom: 30,

            [theme.breakpoints.down("sm")]: {
                marginTop: 30,
                marginBottom: 30,
            },
        },
        productButton: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '22px',
            textAlign: 'center',
            textTransform: 'uppercase',
            color: '#FFFFFF',
            background: '#38006D',
            borderRadius: "44px",
            padding: '15px 100px',
            '&:hover': {
                background: '#12125c',
                border: 'none',
            },
            [theme.breakpoints.down("md")]: {
                padding: '15px 50px',
                fontSize: '15px',
            },
            [theme.breakpoints.down("sm")]: {
                padding: '5px 20px',
                fontSize: '12px',
            },
        },

        // choices product css
        someChoicesProductText: {
            color: '#1D2F5E', fontFamily: `'Poppins', sans-serif`, fontWeight: 600, fontSize: '28px',
            [theme.breakpoints.down("sm")]: {
                fontSize: '18px',
            },
            [theme.breakpoints.only("md")]: {
                fontSize: '22px',
            },
        },
        topBackBtnContainer: {
            width: '90%',
            margin: '0 auto',
            textAlign: 'right',
            display:"flex",
            justifyContent:"flex-end",
            alignItems:"center",
        },
        topBackButton: {
            border: '2px solid #FF3939',
            borderRadius: '30px',
            padding: '7px 40px',
            color:'red',
            background:"#fff",
            '&:hover': {
                background: 'none',
            },
            [theme.breakpoints.down("sm")]: {
                padding: '1px 25px',
                marginBottom:"1rem",
            },
        },

        //sai dev
        pergramtext: {                          
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '30px',
            color: "#38006D",
            textAlign:"center",
            [theme.breakpoints.down("lg")]: {
                fontSize: '16px',
            },
        
        },
        goodnotgoodtitle:{
            display:"flex",
            justifyContent:"space-around",
            [theme.breakpoints.down("xs")]: {
                display:"none",
            },
        },
        emojidisplay: {
            display:"none",
            [theme.breakpoints.down("xs")]: {
                display:"block",
            },
        },
        modelContent: {
            padding: '2rem',
            borderRadius: '1rem',
        },


    });

class ProductDetailsScreenWeb extends ProductDetailsScreenWebController {
    // Customizable Area Start
    render() {
        const {
            loading,
            loadingNiqChoicesProduct,
            products,
            niqProducts,
            addFoodBasketApiResponseText,
            modalOpen,
            isLogin
        } = this.state;
        const items = ["A", "B", "C", "D", "E"];
        const lineControl = [1, 2, 3, 4, 5]
        const { classes } = this.props;        
        if (this.state.loading) {
            return (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '100vh' }}
                >
                    <Loader loading={loading} />
                </Grid>
            );
        }

        return (
            <>
                <div className={classes.root}>
                    <div className={classes.topBackBtnContainer}>
                        <Button
                            className={`${classes.productTextPoppins500} ${classes.topBackButton}`}
                            onClick={() => this.backButton()}
                        >
                            Back
                        </Button>

                    </div>
                    <Grid container direction="row"
                        spacing={3}
                         className={classes.productDteailsContent}>
                        <Grid item sm={12} md={4} className={classes.productImageGrid}>
                            <div className={classes.productImageContent} style={{                            
                            backgroundPosition:"center",
                            backgroundImage: `url(${products?.attributes?.image})`,
                            backgroundSize:"cover",
                        }}>
                                <div className={classes.productImageBorder}>
                                    <div className={classes.productImageDiv}>
                                        <img src={products?.attributes?.image} className={classes.productImage} alt={products?.attributes?.product_name} style={{ width: '50%' }} />
                                    </div>
                                </div>
                            </div>
                        </Grid>

                        <Grid item sm={12} md={8} style={{ width: '100%' }}>
                            <Typography className={`${classes.productNameText} ${classes.productTextMontserrat600}`}>{products?.attributes?.product_name}</Typography>
                            <Typography className={`${classes.productTextPoppins500} ${classes.productTypeText}`}>{products?.attributes?.category_type}</Typography>
                            <Grid
  container
  direction="row"
  justifyContent="center"
  alignItems="center"
>
    <Grid item sm={12} md={10} style={{ width: '100%' }}>
    <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                className={classes.productCategory}
                            >

                                <div style={{ padding: '1.5rem', width: '100%' }} >
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        className={classes.nutValueConteiner}
                                    >
                                        <img src={LeftArrow} alt="LeftArrow" />
                                        <Typography className={`${classes.nutValueText} ${classes.scaleText}`}>Nutritive value scale</Typography>
                                        <img src={rightArrow} alt="rightArrow" />
                                    </Grid>
                                    {/* niq score scale grid here */}
                                    <Grid container >
                                        {
                                            items.map((item:any, index:any) => (
                                                <ProductRatingToolTip itemRating={products?.attributes?.product_rating} item={item} key={index} >
                                                    <Grid item xs direction="column"
                                        justifyContent="center"
                                            alignItems="center" style={{display:'flex'}} container>
                                            <Typography className={`child_${item} ${classes.child_1} ${classes.childTextStyle} ${item === products?.attributes?.product_rating && `bgColor_${item}`}`}>{item}</Typography>
                                            <img src={ratingScaleImg} alt="" style={{width:'103%'}} />
                                            </Grid>
                                                </ProductRatingToolTip>
                                            ))
                                        }
                                        </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        
                                    >
                                        <Typography className={`${classes.scaleTextHighest} ${classes.scaleText}`}>Highest</Typography>
                                        <Typography className={`${classes.scaleText} ${classes.scaleTextLowest}`}>Lowest</Typography>
                                    </Grid>

                                    <div className={classes.niqTextContent}>
                                        <Typography className={`${classes.scaleText} ${classes.niqScoreText}`} style={{fontSize:14}}>
                                            N<sup>IQ</sup> Score is a scientific algorithm to simplify nutritional <br /> facts for consumers. <Link style={{ color: '#5F338B',textDecoration:'underline' }}>Know more</Link>
                                        </Typography>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

    <Grid item sm={12} md={2} style={{ width: '100%'}} >
                            <Grid
                                container
                                justifyContent="space-around"
                                alignItems="center"
                                className={classes.productAction}
                                
                            >
                                <div className={classes.actionItem} >
                                    <img src={Compare} alt="Compare" />                                
                                    <Typography style={{                                        
                                        fontFamily: 'Poppins',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        lineHeight: '10px',                                        
                                        color: '#757D8A',
                                        marginTop:10,
                                    }}>Compare</Typography>
                                </div>
                                <div className={classes.actionItem} 
                                onClick={()=> isLogin ? this.handleFav(this.state.activeProduct) : this.handleLoginNotifiModalOpen()}
                                style={{cursor:"pointer"}}
                                >
                                    {this.checkStatusfav()}
                                    
                                    {/* <p>Favorite</p> */}
                                    <Typography style={{                                        
                                        fontFamily: 'Poppins',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        lineHeight: '10px',                                        
                                        color: '#757D8A',
                                        marginTop:10,
                                    }}>Favourite</Typography>
                                </div>
                                <div className={classes.actionItem}>
                                    <img src={ReportThis} alt="Report This" />                                    
                                    <Typography style={{                                        
                                        fontFamily: 'Poppins',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        fontSize: '12px',
                                        lineHeight: '10px',                                        
                                        color: '#455A64',
                                        textAlign: 'center',
                                        letterSpacing: '-0.01em',
                                        marginTop:10,
                                    }}>Report This</Typography>
                                    
                                </div>
                            </Grid>
                        </Grid>
</Grid>                        
                        </Grid>
                    </Grid>

                    

                    {/* @ts-ignore */}
                    <GoodNotSoGoodWeb/>

                    <HealthyFoodBasketButton 
                    foodProductId={products?.attributes?.id}  
                    addFoodBasketApiCall={this.addFoodBasket} 
                    addFoodBasketloader ={this.state.addFoodBasketloader}
                    foodBasketLists={this.state.foodBasketLists}
                    foodBasketListLoader={this.state.foodBasketListLoader}
                    navigationBtnFnc={this.navigationBtnFnc}
                    addFoodBasketApiResponseText={addFoodBasketApiResponseText}
                    modalOpen={modalOpen}
                    handleModalClose = {this.handleModalClose}
                    isLogin={isLogin}
                    />

                    <ProductDetailsPageFAQ />

                    <Typography className={`${classes.productTextPoppins600} ${classes.someChoicesProductText}`} >N<sup>IQ</sup> Choices</Typography>

                    {loadingNiqChoicesProduct ? (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        > <CircularProgress />
                        </Grid>
                    ) : <Products niqProducts={niqProducts} goToProductDetailsPage={this.goToProductDetailsPage} />}





                </div>

                {/* Login Notification dialog Box start */}

            <Dialog
                open={this.state.loginNotifiModalOpen}
                style={{ zIndex: 9999 }}
                aria-labelledby="form-dialog-title"
                BackdropProps={{ style: { background: 'linear-gradient(167.38deg, rgba(10, 0, 48, 0.89) 3.11%, rgba(38, 2, 37, 0.89) 91.84%' } }}

            // BackdropProps={{ style: { background: 'linear-gradient(360deg, rgba(38,2,37,1) 0%, rgba(10,0,48,0.7595413165266106) 66%' } }}
            >
                <div className={classes.modelContent}>
                    <DialogTitle id="form-dialog-title">
                        Looks like you have not signed up{' '}
                    </DialogTitle>
                    <DialogActions>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-evenly"
                            alignItems="center"
                        >
                            <Button variant="outlined" onClick={() => this.navigationBtnFnc('UserSignUpScreenWeb')} color="primary">
                                Sign Up
                            </Button>
                            <Button onClick={this.handleLoginNotifiModalClose} variant="contained" color="primary">
                                Continue
                            </Button>
                        </Grid>
                    </DialogActions>
                </div>
            </Dialog>

            {/* login notification dialog box end */}
            </>
        );
    }
}
// Customizable Area End

export default withStyles(styles, { withTheme: true })(
    ProductDetailsScreenWeb
);