Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.postAPiMethod = "POST";
exports.deleteAPiMethod = "DELETE";
exports.applicationJsonContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProductRecommendationEngine";
exports.labelBodyText = "ProductRecommendationEngine Body";
exports.getProductDetailsEndPoint = "bx_block_catalogue/products";
exports.getNiqChoicesProductApiEndPoint = "bx_block_catalogue/products/niq_score";
exports.postFavProductApiEndPoint = "bx_block_catalogue/favourite_products";
exports.addFoodBasketApiEndPoint = "bx_block_catalogue/orders"
exports.favouriteProducts = "bx_block_catalogue/favourite_products"

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End