import React from 'react';
import Loader from '../../../components/src/Loader';
import HealthyFoodBasketWebController, { Props } from './HealthyFoodBasketWebController';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, List, ListItem, ListItemIcon, ListItemText, IconButton, Divider } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Skeleton from '@material-ui/lab/Skeleton';
import { topHeaderBanner, sideBarBanner, profileImage } from './assets';
import SideNavbar from './SideNavbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import { Link } from "react-router-dom";


const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        media: {
            height: 100,
            width: 200
        },
        productName: {
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "15px",
            lineHeight: "18px",
            height: 50,
            color: "#455A64",
            textAlign: "left",
            whiteSpace: 'break-spaces',
            overflow: "hidden",
            textOverflow: 'ellipsis',
            width: 200,
        },
        niqAvatarA: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            backgroundColor: "#00AB79",
        },
        // navigation side bar 
        navigationSideBar: {
            backgroundImage: `url(${sideBarBanner})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'inherit',
            height: '1000px',
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },

        profileTopBanner: {
            backgroundImage: `url(${topHeaderBanner})`,
            // backgroundImage: `urls('${topHeaderBanner}')`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '255px',
            [theme.breakpoints.down("sm")]: {
                height: '170px',
            },
            [theme.breakpoints.down("xs")]: {
                height: '100px',
            },
        },

        // css for textContainer
        textContainer: {
            display: "flex",
            justifyContent: "space-between",
            // marginLeft: 25,
            // marginRight: 25,
            margin: '1.5rem 25px 1.5rem 25px',
            [theme.breakpoints.down("sm")]: {
                alignItem: "baseline",
            },
            [theme.breakpoints.down("xs")]: {
                marginLeft: 5,
                marginRight: 5,
            },
        },
        searchResultText: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "22px",
            lineHeight: "33px",
            color: "#37006E",
            [theme.breakpoints.down("sm")]: {
                fontSize: "16px",
                lineHeight: "12px",
            },

        },
        healthPointText: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "22px",
            lineHeight: "12px",
            color: "#FF7900",
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px",

            },
        },
        FoodTypeUnderline: {
            textUnderlineOffset: "0.5rem",
            textDecoration: "underline",
            textDecorationColor: "#fb951a61",
        },

        // css for foodBasketContainer
        foodBasketContainer: {
            margin: '1rem 25px 1.5rem 25px',
        },
        basketProduct: {
            // width: '90%',
            border: "double 1px transparent",
            borderRadius: '5px',
            backgroundImage:
                "linear-gradient(white, white), linear-gradient(200deg,#41479B,#FF3939)",
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
            // [theme.breakpoints.down("sm")]: {
            //     marginLeft: "auto",
            //     marginRight: "auto",
            // },
        },
        deleteButton: {

            width: '50px',
            height: '50px',
            border: "double 1px transparent",
            borderRadius: '50%',
            backgroundImage:
                "linear-gradient(white, white), linear-gradient(200deg,#41479B,#FF3939)",
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
        },

        // side nav section
        profileSection: {

        },
        profileContainer: {
            padding: '4rem 0',
        },
        userNameText: {
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: '25px',
            color: '#FFFFFF'
        },
        userAccountText: {
            fontFamily: 'Poppins',
            fontWeight: 400,
            fontSize: '20px',
            color: '#FED600'
        },
        sideNavIcon: {
            color: '#FFFFFF',
        },
        sideNavText: {
            color: '#FFFFFF',
        },
        sideNavcontentBg: {

        },

        // food products container
        foodProductName: {
            color: '#290743',
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: '27px',
            textTransform: 'capitalize',
            [theme.breakpoints.down("sm")]: {
                fontSize: '14px',
            },
        },
        totalFoodProduct: {
            color: '#263238',
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: '20px',
            [theme.breakpoints.down("sm")]: {
                fontSize: '12px',
            },
        },
        PtFoodProduct: {
            color: '#38006F',
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: '27px',
            [theme.breakpoints.down("sm")]: {
                fontSize: '15px',
            },
        },
        foodProductInfoContainer: {
            paddingLeft: '2rem',
            [theme.breakpoints.down("sm")]: {
                paddingLeft: '0'
            },
        },
        NoProductNofificationText: {
            color: '#FF3939',
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: '23px',
            textTransform: 'capitalize',
            [theme.breakpoints.down("sm")]: {
                fontSize: '14px',
            },
        },
    });

class HealthyFoodBasketWeb extends HealthyFoodBasketWebController {
    // Customizable Area Start
    render() {
        const {
            loading,
            foodBasketListLoader,
            foodBasketLists
        } = this.state;
        const { classes } = this.props;
        if (this.state.loading) {
            return (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '100vh' }}
                >
                    <Loader loading={loading} />
                </Grid>
            );
        }

        return (
            <div style={{ width: '100%', margin: '3.5rem 0' }} className={classes.root}>
                <Grid container>
                    <Grid item xs={false} sm={false} md={2} className={classes.navigationSideBar}>
                        <SideNavbar />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10}>
                        <div className={classes.profileTopBanner}>
                        </div>

                        <div className={classes.textContainer}>
                            <Typography className={classes.searchResultText}>
                                <span className={classes.FoodTypeUnderline}>Healthy Basket</span>
                            </Typography>
                            <Typography className={classes.healthPointText}>
                                Health Points : --
                            </Typography>
                        </div>
                        <div className={classes.foodBasketContainer}>
                            {
                                this.state.noFoodBasketNotification && <Typography className={classes.NoProductNofificationText}>No products added here, <Link to="/">Create one</Link> </Typography>
                            }
                            {
                                foodBasketListLoader && !this.state.noFoodBasketNotification ? <div style={{ textAlign: 'center', padding: '10px 0' }}><CircularProgress /></div> : foodBasketLists.map((item: any, key: any) => {
                                    return (
                                        <Grid container wrap="nowrap" spacing={2} key={key} style={{ padding: '1rem 0' }}>
                                            <Grid item xs>
                                                <div className={classes.basketProduct}>
                                                    <Grid container spacing={3} style={{ padding: '1rem' }}>
                                                        <Grid item xs={12} sm={8} md={9}>
                                                            <Link to={`/HealthyFoodDetails?id=${item?.attributes?.id}`} style={{ textDecoration: 'none' }}>
                                                                <div className={classes.foodProductInfoContainer}>
                                                                    <Typography className={classes.foodProductName}>{item?.attributes?.order_name}</Typography>
                                                                    <Typography className={classes.totalFoodProduct}>
                                                                        Total Products: <span style={{ color: 'red' }}>{item?.attributes?.product_count}</span>
                                                                    </Typography>
                                                                </div>
                                                            </Link>

                                                        </Grid>
                                                        <Grid item xs={12} sm={4} md={3}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Typography className={classes.PtFoodProduct}>-- pt</Typography>
                                                                <ArrowRightIcon className={classes.PtFoodProduct} />

                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                            <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div className={classes.deleteButton}>
                                                    <IconButton aria-label="delete" onClick={() => this.deleteFoodBasket(item?.attributes?.id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    )

                                })

                            }
                        </div>
                    </Grid >
                </Grid >

            </div >
        );
    }
}
// Customizable Area End

export default withStyles(styles, { withTheme: true })(
    HealthyFoodBasketWeb
);