import React from 'react';
// Customizable Area Start
import Loader from '../../../components/src/Loader';
import FavouriteSmartSearchController, { Props } from './FavouriteSmartSearchController';
import { Typography, Grid, ButtonBase, IconButton, Divider, Button, Chip } from "@material-ui/core";
import {
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';
import { favouriteSmartSearchTopBg } from './assets';


const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: '100%', 
            margin: '4rem 0',
            [theme.breakpoints.down("sm")]: {
                margin: '3.5rem 0',
            },
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },

        // favouriteSmartSearchTopBg: {
        //     backgroundImage: `url(${favouriteSmartSearchTopBg})`,
        //     backgroundPosition: 'center',
        //     backgroundRepeat: 'no-repeat',
        //     backgroundSize: 'cover',
        //     height: '200px'
        // },

        // css for textContainer
        textContainer: {
            display: "flex",
            justifyContent: "space-between",
            // marginLeft: 25,
            // marginRight: 25,
            alignItems: 'center',
            margin: '1.5rem 25px 1.5rem 25px',
            [theme.breakpoints.down("sm")]: {
                alignItem: "baseline",
            },
            [theme.breakpoints.down("xs")]: {
                marginLeft: 5,
                marginRight: 5,
            },
        },
        foodSearchContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center',
            [theme.breakpoints.down("sm")]: {
                alignItem: "baseline",
            },
        },
        foodSearchText: {
            color: '#455A64',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            fontSize: '22px',
            [theme.breakpoints.down("sm")]: {
                fontSize: '16px',
            },
        },
        TotalfoodSearchText: {
            color: '#41479B',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            fontSize: '20px',
            [theme.breakpoints.down("sm")]: {
                fontSize: "15px",
                // lineHeight: "12px",
            },
        },
        foodSearchProductText: {
            color: '#757D8A',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            fontSize: '10px'
        },
        newSmartSrcBtn: {
            backgroundColor: '#FF5050',
            fontFamily: 'Poppins',
            fontWeight: 500,
            color: '#FFFFFF',
            borderRadius: '8px',
            textTransform: 'capitalize',
            '&:hover': {
                backgroundColor: '#FF5050',
            },
        },


        searchResultText: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "22px",
            lineHeight: "33px",
            color: "#37006E",
            textTransform: 'capitalize',
            [theme.breakpoints.down("sm")]: {
                fontSize: "15px",
                lineHeight: "12px",
            },

        },
        TextUnderline: {
            textUnderlineOffset: "0.5rem",
            textDecoration: "underline",
            textDecorationColor: "#fb951a61",
        },

        // css for favouriteSmartSearchContainer
        favouriteSmartSearchContainer: {
            margin: '1rem 25px 1.5rem 25px',
        },


        productQualityContainer: {
            border: '1px solid #37006E',
            borderRadius: '10px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly'
        },
        tagList: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },

    });
// Customizable Area Stop
class FavouriteSmartSearchWeb extends FavouriteSmartSearchController {
    // Customizable Area Start
    render() {
        const {
            loading,
        } = this.state;
        const { classes } = this.props;


        return (
            <div className={classes.root}>
                
                <img src={favouriteSmartSearchTopBg} alt="bgImage" style={{ width: "100%" }} />
                

                <div className={classes.textContainer}>
                    <div className={classes.searchResultText}>
                        <span className={classes.TextUnderline}>Favourite smart searches</span>
                    </div>
                    <Button size="small" variant="contained" color="secondary" className={classes.newSmartSrcBtn}
                    onClick={()=>{                        
                        
                    }}
                    >New Smart Search</Button>
                </div>


                <div className={classes.favouriteSmartSearchContainer}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6} style={{ marginTop: '2rem' }}>
                            <div className={`${classes.paper} ${classes.productQualityContainer}`}>
                                <div className={classes.foodSearchContainer}>
                                    <Typography className={classes.foodSearchText}>Favourite Search 2</Typography>
                                    <div>
                                        <Typography className={classes.TotalfoodSearchText}>14</Typography>
                                        <Typography className={classes.foodSearchProductText}>Product</Typography>
                                    </div>
                                </div>
                                <div className={classes.tagList} >
                                    <Chip label="Food Type: Raw " />
                                    <Chip label="Category: Canned and Jarred Food " />
                                    <Chip label="Food Type: Raw " />
                                    <Chip label="Food Type: Raw " />
                                    <Chip label="Category: Canned and Jarred Food" />
                                    <Chip label="Food Type: Raw " />
                                    <Chip label="Food Type: Raw " />
                                    <Chip label="Category: Canned and Jarred Food " />
                                </div>

                            </div>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} style={{ marginTop: '2rem' }}>
                            <div className={`${classes.paper} ${classes.productQualityContainer}`}>
                                <div className={classes.foodSearchContainer}>
                                    <Typography className={classes.foodSearchText}>Favourite Search 2</Typography>
                                    <div>
                                        <Typography className={classes.TotalfoodSearchText}>14</Typography>
                                        <Typography className={classes.foodSearchProductText}>Product</Typography>
                                    </div>
                                </div>
                                <div className={classes.tagList} >
                                    <Chip label="Food Type: Raw " />
                                    <Chip label="Category: Canned and Jarred Food " />
                                    <Chip label="Food Type: Raw " />
                                    <Chip label="Food Type: Raw " />
                                    <Chip label="Category: Canned and Jarred Food" />
                                    <Chip label="Food Type: Raw " />
                                    <Chip label="Food Type: Raw " />
                                    <Chip label="Category: Canned and Jarred Food " />
                                </div>

                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} style={{ marginTop: '2rem' }}>
                            <div className={`${classes.paper} ${classes.productQualityContainer}`}>
                                <div className={classes.foodSearchContainer}>
                                    <Typography className={classes.foodSearchText}>Favourite Search 2</Typography>
                                    <div>
                                        <Typography className={classes.TotalfoodSearchText}>14</Typography>
                                        <Typography className={classes.foodSearchProductText}>Product</Typography>
                                    </div>
                                </div>
                                <div className={classes.tagList} >
                                    <Chip label="Food Type: Raw " />
                                    <Chip label="Category: Canned and Jarred Food " />
                                    <Chip label="Food Type: Raw " />
                                    <Chip label="Food Type: Raw " />
                                    <Chip label="Category: Canned and Jarred Food" />
                                    <Chip label="Food Type: Raw " />
                                    <Chip label="Food Type: Raw " />
                                    <Chip label="Category: Canned and Jarred Food " />
                                </div>

                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} style={{ marginTop: '2rem' }}>
                            <div className={`${classes.paper} ${classes.productQualityContainer}`}>
                                <div className={classes.foodSearchContainer}>
                                    <Typography className={classes.foodSearchText}>Favourite Search 2</Typography>
                                    <div>
                                        <Typography className={classes.TotalfoodSearchText}>14</Typography>
                                        <Typography className={classes.foodSearchProductText}>Product</Typography>
                                    </div>
                                </div>
                                <div className={classes.tagList} >
                                    <Chip label="Food Type: Raw " />
                                    <Chip label="Category: Canned and Jarred Food " />
                                    <Chip label="Food Type: Raw " />
                                    <Chip label="Food Type: Raw " />
                                    <Chip label="Category: Canned and Jarred Food" />
                                    <Chip label="Food Type: Raw " />
                                    <Chip label="Food Type: Raw " />
                                    <Chip label="Category: Canned and Jarred Food " />
                                </div>

                            </div>
                        </Grid>
                        
                    </Grid>
                </div>                                
            </div >
        );
    }
}
// Customizable Area End

export default withStyles(styles, { withTheme: true })(
    FavouriteSmartSearchWeb
);