export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const product_bg = require("../assets/product_bg.png");
export const ellipse = require("../assets/ellipse.png");
export const Compare = require("../assets/Compare.svg");
export const Favorite = require("../assets/Favorite.svg");
export const ReportThis = require("../assets/ReportThis.svg");
export const rightArrow = require("../assets/rightArrow.png");
export const LeftArrow = require("../assets/LeftArrow.png");
export const topHeaderBanner = require("../assets/topHeaderBanner.svg");
export const sideBarBanner = require("../assets/sideBarBanner.svg");
export const profileImage = require("../assets/profileImage.svg");
export const successfullyImg = require("../assets/successfullyImg.svg");
export const appleImage = require("../assets/appleImage.svg");
export const notificationImg = require("../assets/notificationImg.svg");
export const scaleFirstPart = require("../assets/scaleFirstPart.jpg");
export const ratingScaleImg = require("../assets/ratingScaleImg.jpg");