// Customizable Area Start
import React, { Component } from "react";
import {Accordion, AccordionSummary, AccordionDetails, Typography,Grid,Button} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import SearchControllerWeb, {
  Props,
  configJSON
} from "./SearchControllerWeb";
import {searchBgImage,NoProductFoundImg} from "./assets"
const styles = (theme: Theme) =>
  createStyles({
      root: {
        marginTop:10,
        [theme.breakpoints.up(600)]: {
          marginTop: 64,
        },
        [theme.breakpoints.down(600)]: {
          marginTop: 48,
        },
        [theme.breakpoints.down(515)]: {
          marginTop: 56,
        },
      },
      noProductContainer:{
        background: '#E5E5E5',
        display:"flex", 
        flexDirection:"column",
        justifyContent:"center",
        alignItems:'center',
        paddingTop:50,
        paddingBottom:50,
       
        [theme.breakpoints.down("lg")]: {
            paddingTop:40,
            paddingBottom:40,
        },
        [theme.breakpoints.down("md")]: {
            paddingTop:25,
            paddingBottom:25,
        },
        [theme.breakpoints.down("sm")]: {
            paddingTop:15,
            paddingBottom:15,
        },
        [theme.breakpoints.down("xs")]: {
            paddingTop:5,
            paddingBottom:5,
        },
      },
      noProductImage:{
        width: 200,
        height:250,
        [theme.breakpoints.down("md")]: {
            width: 150,
            height:200,
        },
        [theme.breakpoints.down("xs")]: {
            width: 100,
            height:150,
        },
      },
      noProductTitle:{
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '25px',
        lineHeight:"117.5%",
        color: '#FF3939',
        textAlign:"center",
        padding:50,
        [theme.breakpoints.down("lg")]: {
            padding:35,
        },
        [theme.breakpoints.down("md")]: {
            padding:25,
            fontSize: '20px',
        },
        [theme.breakpoints.down("sm")]: {
            padding:15,
        },
        [theme.breakpoints.down("xs")]: {
            padding:10,
            fontSize: '16px',
        },
      },
      straightLine:{
        width:"90%",
        borderBottom:"1px solid #455A64",
        marginLeft:80,
        marginRight:80,
      },
      containerStyle: {
        marginTop: 50,
        // marginLeft: 55.5,
        // marginRight: 55.5,
        paddingBottom: 100,
        width:"87%",

        [theme.breakpoints.down("md")]: {
          marginLeft: 0,
          marginRight: 0,
          paddingBottom: 0,
        },
      },
      AccordionRootStyle: {
        // marginRight: 45,
        // marginLeft: 59,
        marginBottom: 203.86,
        [theme.breakpoints.down("sm")]: {
          marginLeft: 0,
          marginRight: 0,
          marginBottom: 30,
        },
      },
      AccordionStyle: {
        marginBottom: 34,
        background: '#FFFFFF',
        border: '0.3px solid #757D8A',
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.12)',
        borderRadius: '5px',
        // backgroundImage:
        //   "linear-gradient(white, white), linear-gradient(180deg, #FF3939,#41479B)",
        // backgroundOrigin: "border-box",
        // backgroundClip: "content-box, border-box",
        [theme.breakpoints.down("sm")]: {
          marginBottom: 16,
          marginLeft: "auto",
          marginRight: "auto",
        },
      },
      AccordionSummaryStyle: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 22,
        margin: 12,
        color: "#455A64",
        [theme.breakpoints.down("sm")]: {
          fontSize: 16,
          margin: 5,
          fontWeight: 500,
        },
      },
      AccordionDetailStyle: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 20,
        lineHeight: 2,
        marginLeft: 30,
        marginRight: 70,
        marginBottom: 24,
        color: "#757D8A",
        [theme.breakpoints.down("sm")]: {
          fontWeight: 400,
          fontSize: 14,
          marginLeft: 0,
          marginRight: 0,
        },
      },
      AccordionDetailBoxStyle: {
        display: "flex",
        flexDirection: "column",
        rowGap: 10,
      },
      submitProductButton:{
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '22px',
        textAlign: 'center',
        textDecorationLine: 'underline',    
        textTransform:"none",
        color: '#FF3939',
        [theme.breakpoints.down("sm")]: {
          fontSize: 14,
        },
      },
      submitProductButtonContainer:{
        display:"flex",
        justifyContent:"center",
        marginTop:30,
        marginBottom:30,
      
      [theme.breakpoints.down("sm")]: {
        marginTop:30,
        marginBottom:30,
      },
      },
      SearchAnotherProductButton:{
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '22px',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#FFFFFF',
        background:'#38006D',
        borderRadius:"44px",
        padding:'25px 100px',
        '&:hover': {
          background: '#12125c',
          border: 'none',
      },
        [theme.breakpoints.down("sm")]: {
          padding:'15px 50px',
          fontSize: '16px',
        },
        [theme.breakpoints.down("sm")]: {
          padding:'5px 20px',
          fontSize: '12px',
        },
      }

 
  });

class NoProduct extends SearchControllerWeb {

    constructor(props: Props) {
    super(props);
    }

  render() {
    const { classes } = this.props;
    return (
        
        <div className={classes.root}>    
             <img src={searchBgImage} alt="bgImage" style={{width: "100%",}}/>  
             {/* No Product Found Page start */}
             <div className={classes.noProductContainer}>
             <img src={NoProductFoundImg} alt="bgImage" 
             className={classes.noProductImage}/>
             <Typography className={classes.noProductTitle}>No product found</Typography>
             <div className={classes.straightLine}/>
             
             <Grid container justifyContent="center" alignItems="center" >
            <div className={classes.containerStyle}>
             <Grid item>
                <div style={{ flexGrow: 1 }} />
                <div className={classes.AccordionRootStyle}>
             <Accordion className={classes.AccordionStyle}>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon style={{backgroundColor:"#FCC300",borderRadius:"50%",
                      }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        <span style={{ textUnderlineOffset:"0.5rem",
      textDecoration: "underline", 
      textDecorationColor: "#FB951A",}}>Looks like this product is not</span> available in our database
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography style={{fontSize:"inherit"}}>
                        Would you like to help us build a more complete database by submitting this product information.
                        </Typography>
                        
                      </div>
                    </AccordionDetails>
                    <div className={classes.submitProductButtonContainer}>
                    <Button className={classes.submitProductButton}>
                        Submit a product
                        </Button>
                        </div>
                  </Accordion>

                  <Accordion className={classes.AccordionStyle}>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon style={{backgroundColor:"#FCC300",borderRadius:"50%",
                      }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.AccordionSummaryStyle}>
                        <span style={{ textUnderlineOffset:"0.5rem",
      textDecoration: "underline", 
      textDecorationColor: "#FB951A",}}>Some products are not bein</span>g rated?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.AccordionDetailStyle}>
                      <div className={classes.AccordionDetailBoxStyle}>
                        <Typography style={{fontSize:"inherit"}}>
                        There are various types of products that app does not rate because they are very specific products for which no rating method exists:
                        </Typography>
                        <ol>
                          <li>Alcohol</li>
                          <li>Sugar</li>
                          <li>Infant formula</li>
                          <li>Food products designed for special medical purposes</li>
                          <li>Unprocessed products made of single ingredient or category of ingredients</li>
                          <li>Water</li>
                          <li>Herbs, spices , salt and salt substitutes, table top sweeteners</li>
                          <li>Milled coffee beans</li>
                          <li>Herbal and fruit infusions</li>
                          <li>Pet food</li>
                          <li>Nutritional supplements (Sports nutrition, Multi-vitamins etc.)</li>
                          <li>Products which have incomplete or no nutritional declaration - will not be rated</li>
                        </ol>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  </div>
                  <div className={classes.submitProductButtonContainer}>
                  <Button
                  className={classes.SearchAnotherProductButton}
                  onClick={()=>this.searchAnotherProductButton()}
                  >
                    Search Another Product
                  </Button>
                  </div>
                  </Grid>
                  </div>
                  </Grid>
                  {/* </Toolbar> */}
                  {/* </Container> */}
            </div>
        </div>
       
    );
  }
}


export default withStyles(styles, { withTheme: true })(NoProduct);
// Customizable Area End
