import React, { useEffect, useState, useCallback } from "react";
// import { View, Button, StyleSheet } from "react-native";

import { withRouter } from "react-router-dom";
//Customise area start
import {
  AppBar,
  Button,
  Toolbar,
  Box,
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Avatar,
  Typography,
} from "@material-ui/core";
import NiqLogo from "../assets/Asset 2 1.svg";
import whatsyourSvg from "../assets/What’s Your.svg";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import myprofile from "../assets/myprofile.svg";
import healthpoints from "../assets/healthpoints.svg";
import bmicalculator from "../assets/bmicalculator.svg";
import NutritionGapCal from "../assets/NutriGapCal.svg";
import HealthyFoodBasket from "../assets/HealthyFB.svg";
import Preferences from "../assets/Preferences.svg";
import Myorder from "../assets/Myorder.svg";
import submitAproduct from "../assets/SubmitAPro.svg";
import Logout from "../assets/Logout.svg";
import CustomSearch from "./CustomSearch"


const useStyles = makeStyles((theme) =>
  createStyles({
    containerStyle: {
      display: "flex",
      // flexGrow: 1,
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      marginLeft: 50,
      marginRight: 50,
      [theme.breakpoints.down("xl")]: {
        marginLeft: 40,
        marginRight: 40,
      },
      [theme.breakpoints.down("lg")]: {
        marginLeft: 30,
        marginRight: 30,
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: 20,
        marginRight: 20,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: 10,
        marginRight: 10,
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },

    MenuStyle: {
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },

    // LogoStyle: {
    //   [theme.breakpoints.up("lg")]: {
    //     display: "flex",
    //   },
    // },
    search: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      backgroundColor: "#fff",
      borderRadius: "59px",
      boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.12)",
      marginRight: theme.spacing(2),
      marginLeft: 0,
      paddingRight: "1em",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },

      gap: "10px",
    },

    inputInput: {
      background: "none !important",
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
      transition: theme.transitions.create("width"),
      fontFamily: "Poppins",
      color: "#38006D",
      fontSize: "13px",
      fontWeight: 500,
      lineHeight: "18px",
      fontStyle: "normal",
      width: "100%",
      [theme.breakpoints.up("xl")]: {
        fontSize: 18,
      },
      [theme.breakpoints.up("lg")]: {
        width: "34ch",
      },
      [theme.breakpoints.only("md")]: {
        width: "34ch",
      },
      [theme.breakpoints.only("sm")]: {
        width: "34ch",
      },
      [theme.breakpoints.between(599, 655)]: {
        width: "30ch",
      },
      [theme.breakpoints.between(360, 599)]: {
        maxWidth: "30ch",
        fontSize: 16,
      },
      [theme.breakpoints.down(360)]: {
        width: "6ch",
        fontSize: 16,
      },
    },

    voiceQrIcon: {
      display: "flex",
      columnGap: 10,
      alignItems: "center",
      justifyContent: "flex-end",
      [theme.breakpoints.up("xl")]: {
        columnGap: 30,
      },
      [theme.breakpoints.down("sm")]: {
        columnGap: 20,
      },
      [theme.breakpoints.down("xs")]: {
        columnGap: 10,
      },
    },

    searchDropdown: {
      position: "absolute",
      zIndex: "-1",
      width: "100%",
      maxHeight: 400,
      background: "#fff",
      borderRadius: "29px",
      borderStartStartRadius: "20px",
      borderStartEndRadius: "20px",
      padding: 20,
      paddingTop: 50,
      overflowY: "scroll",
      width: "90%",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,0.00)",
      },
    },

    AllButtonStyle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },

    AllStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },

    buttonContainerStyle: {
      display: "flex",
      justifyContent: "space-between",
      marginRight: 10,
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },

    buttonStyle: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "27px",
      textTransform: "capitalize",
      [theme.breakpoints.up("xl")]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },

    cartFavStyle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginRight: 10,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    menuButtonCartFav: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },

    menuButtonSignUp: {
      display: "none",
      [theme.breakpoints.down(720)]: {
        display: "block",
      },
    },

    MenuItemStyle: {
      color: "#38006D",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "13px",
    },

    SignUpButtonContainerStyle: {
      display: "flex",
      [theme.breakpoints.down(720)]: {
        display: "none",
      },
    },
    UserMenuContainerStyle: {
      display: "flex",
      [theme.breakpoints.down(720)]: {
        display: "none",
      },
    },

    SignUpButtonStyle: {
      color: "#38006D",
      background: "#ffffff",
      borderRadius: "5px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "13px",
      lineHeight: "20px",
    },

    badgeStyle: {
      "& > *": {
        color: "#38006D",
        backgroundColor: "#FED600;",
      },
    },
    small: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    menuProfilesmall: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    UserMenuItemStyle: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "11px",
      lineHeight: "24px",
    },
    userName: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "11px",
      lineHeight: "16px",
      color: "#FFFFFF",
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: "72px",
    },
    InuserName: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "11px",
      lineHeight: "16px",
      color: "#455A64",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "80px",
    },

    logoInSearchHide: {
      display: "flex",
      cursor: "pointer",
      flexGrow: "inherit",
      justifyContent: 'flex-start',
      [theme.breakpoints.down(720)]: {
        flexGrow: 1,
        justifyContent: 'center',
      },
    },
    logoInSearchNotHide: {
      display: "flex",
      cursor: "pointer",
      [theme.breakpoints.up("lg")]: {
        display: "flex",
      },


    }

  })
);

//Customise area stop

const TopNav = ({ history }) => {
  //Customise area start
  const classes = useStyles();
  const [profileMenu, setProfileMenu] = React.useState(null);
  const [userProfileMenu, setUserProfileMenu] = React.useState(null);
  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    let favListCust = localStorage.getItem('favList')
    if (favListCust) {
      setAllProducts(JSON.parse(favListCust))
    }
  }, []);


  const handelLogoutBtn = () => {
    setUserProfileMenu(null);
    localStorage.clear();
    history.push("/")
  };

  //Customise area stop
  return (
    // Customise area start
    <AppBar
      style={{
        background:
          "linear-gradient(169.52deg, #38006F 55.17%, #FF3939 111.06%)",
        display: history?.location?.pathname == "/signup" ? "none" : false,
        zIndex: 9999,
      }}>
      <Toolbar disableGutters>
        <Box className={classes.containerStyle}>
          <Box className={classes.MenuStyle}>
            <IconButton
              aria-haspopup="true"
              color="inherit"
              aria-controls="profile-menu"
              onClick={(e) => setProfileMenu(e.currentTarget)}
              size={"medium"}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="profile-menu"
              open={Boolean(profileMenu)}
              anchorEl={profileMenu}
              elevation={0}
              style={{ marginTop: 10 }}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              onClose={() => setProfileMenu(null)}
              disableAutoFocusItem
            >
              <MenuItem
                onClick={() => {
                  setProfileMenu(null);
                  history.push("/");
                }}
                className={classes.MenuItemStyle}
                style={{
                  color:
                    history?.location?.pathname == "/" ? "#FED600" : "#38006D",
                }}
              >
                Home
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setProfileMenu(null)
                  history.push("/FavouriteSmartSearchWeb");
                }}
                className={classes.MenuItemStyle}
              >
                Smart Search
              </MenuItem>
              <MenuItem
                onClick={() => setProfileMenu(null)}
                className={classes.MenuItemStyle}
              >
                Nutrition Gap
              </MenuItem>
              <MenuItem
                onClick={() => setProfileMenu(null)}
                className={classes.MenuItemStyle}
              >
                N
                <sup
                  style={{
                    fontSize: "75%",
                    lineHeight: 0,
                    position: "relative",
                    verticalAlign: "baseline",
                    top: "-0.5em",
                  }}
                >
                  IQ
                </sup>
                &nbsp;Must
              </MenuItem>
              <MenuItem
                onClick={() => setProfileMenu(null)}
                className={classes.MenuItemStyle}
              >
                Health Blog
              </MenuItem>
              <MenuItem
                onClick={() => setProfileMenu(null)}
                className={[classes.menuButtonCartFav, classes.MenuItemStyle]}
              >
                Cart
              </MenuItem>
              <MenuItem
                onClick={() => setProfileMenu(null)}
                className={[classes.menuButtonCartFav, classes.MenuItemStyle]}    
                style={{display:"flex", justifyContent:"space-between"}}

              >
                Favorite                    
                <Badge badgeContent={allProducts?.length > 0 ? allProducts?.length : 0} max={99} className={classes.badgeStyle}/>                                
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push("/HealthyFoodBasket")
                  setProfileMenu(null);
                }}
                className={[classes.menuButtonCartFav, classes.MenuItemStyle]}
              >
                Healthy Food Basket
              </MenuItem>
              {localStorage.getItem('token') && localStorage.getItem('token') != '' && localStorage.getItem('token') != null ? (
                <MenuItem
                  onClick={() => {
                    handelLogoutBtn();
                    setProfileMenu(null);
                  }}
                  className={[classes.menuButtonSignUp, classes.MenuItemStyle]}
                >
                  Logout
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={() => {
                    history.push("/signup");
                    setProfileMenu(null);
                  }}
                  className={[classes.menuButtonSignUp, classes.MenuItemStyle]}
                  style={{
                    color:
                      history?.location?.pathname == "/signup"
                        ? "#FED600"
                        : "#38006D",
                  }}
                >
                  SignUp/LogIn
                </MenuItem>
              )}
            </Menu>
          </Box>
          {/* <Box style={{background:"#fff", padding:20}}> */}
          <Box
            style={{
              // display: "flex", cursor: "pointer",
              // flexGrow:history.location.pathname === "/landingPage" ? "inherit" : 1,
              // justifyContent:history.location.pathname === "/landingPage" ? 'center' : "none",


            }}
            onClick={() => { history.push("/"); }}
            // className={classes.LogoStyle}
            className={`${history?.location?.pathname === "/landingPage" ? classes.logoInSearchHide : classes.logoInSearchNotHide}`}
          >
            <img src={whatsyourSvg} alt="WhatsYourStp" />
            <img src={NiqLogo} alt="NiqLogo" />
          </Box>

          {/* </Box> */}
          {/* Search */}
          <Box style={{
            display: history.location.pathname === "/landingPage" ? "none" : "block"
          }}>
            <CustomSearch data={"TopNav"} />

          </Box>

          {/* Search */}

          <Box className={classes.buttonContainerStyle}>
            <Button
              className={classes.buttonStyle}
              style={{
                color:
                  history?.location?.pathname === "/" ? "#FED600" : "#FFFFFF",
              }}
              onClick={() => { history.push("/"); }}
            >
              Home
            </Button>

            <Button
              className={classes.buttonStyle}
              style={{
                color: history?.location?.pathname === "/FavouriteSmartSearchWeb" ? "#FED600" : "#FFFFFF",
                // color: "#fff",
              }}
              onClick={() => { history.push("/FavouriteSmartSearchWeb"); }}
            >
              Smart Search
            </Button>

            <Button
              className={classes.buttonStyle}
              style={{
                // color: history?.location?.pathname === "/" ? "#FED600": "#FFFFFF",
                color: "#fff",
              }}
            >
              Nutrition Gap
            </Button>

            <Button
              className={classes.buttonStyle}
              style={{
                // color: history?.location?.pathname === "/" ? "#FED600": "#FFFFFF",
                color: "#fff",
              }}
            >
              N
              <sup
                style={{
                  fontSize: "75%",
                  lineHeight: 0,
                  position: "relative",
                  verticalAlign: "baseline",
                  top: "-0.5em",
                }}
              >
                IQ
              </sup>
              &nbsp;Must
            </Button>

            <Button
              className={classes.buttonStyle}
              style={{
                // color: history?.location?.pathname === "/" ? "#FED600": "#FFFFFF",
                color: "#fff",
              }}
            >
              Health Blog
            </Button>
          </Box>

          <Box className={classes.cartFavStyle}>
            <IconButton
              size="medium"
              aria-label="show 1 item in cart"
              color="inherit"
            >
              <Badge badgeContent={0} max={9} className={classes.badgeStyle}>
                <ShoppingCartIcon
                  style={{ background: "none", color: "#fff" }}
                />
              </Badge>
            </IconButton>

            <IconButton
              size="medium"
              aria-label="show 1 new Favorite"
              color="inherit"
            >
              <Badge badgeContent={allProducts?.length > 0 ? allProducts?.length : 0} max={9} className={classes.badgeStyle}>
                <FavoriteBorderIcon
                  style={{ background: "none", color: "#fff" }}
                />
              </Badge>
            </IconButton>
          </Box>

          {localStorage.getItem('token') && localStorage.getItem('token') != '' && localStorage.getItem('token') != null ? (
            <Box className={classes.UserMenuContainerStyle}>
              {localStorage.getItem("user") ? (
                <Avatar
                  alt={JSON.parse(localStorage.getItem("user")).name ? JSON.parse(localStorage.getItem("user")).name : "Hello!"}
                  src={JSON.parse(localStorage.getItem("user")).image}
                  className={classes.small} />
              ) : (
                <Avatar
                  alt={JSON.parse(localStorage.getItem("user")).name ? JSON.parse(localStorage.getItem("user")).name : "Hello!"}
                  src={"/static/images/avatar/1.jpg"}
                  className={classes.small}
                />
              )}

              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginLeft: 5,
                }}
              >
                <Typography className={classes.userName}>
                  {JSON.parse(localStorage.getItem("user")).name ? JSON.parse(localStorage.getItem("user")).name : "Hello!"}
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={(e) => setUserProfileMenu(e.currentTarget)}
                >
                  <Typography
                    style={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "9px",
                      lineHeight: "14px",
                      color: "#FED600",
                    }}
                  >
                    My account
                  </Typography>
                  <ArrowDropDownIcon />
                </Box>
              </Box>

              <Box
                className={classes.MenuStyle}
                style={{ position: "relative" }}
              >
                <Menu
                  id="profile-menu"
                  open={Boolean(userProfileMenu)}
                  anchorEl={userProfileMenu}
                  elevation={0}
                  style={{ marginTop: 10 }}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  onClose={() => setUserProfileMenu(null)}
                  disableAutoFocusItem
                >
                  <MenuItem className={classes.UserMenuItemStyle}>
                    {localStorage.getItem("user") ? (
                      <Avatar
                        alt={JSON.parse(localStorage.getItem("user")).name ? JSON.parse(localStorage.getItem("user")).name : "Hello!"}
                        src={JSON.parse(localStorage.getItem("user")).image}
                        className={classes.menuProfilesmall}
                      />
                    ) : (
                      <Avatar
                        alt={JSON.parse(localStorage.getItem("user")).name ? JSON.parse(localStorage.getItem("user")).name : "Hello!"}
                        src="/static/images/avatar/1.jpg"
                        className={classes.menuProfilesmall}
                      />
                    )}

                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        marginLeft: 5,
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "11px",
                          lineHeight: "16px",
                          color: "#455A64",
                        }}
                      >
                        {JSON.parse(localStorage.getItem("user")).name ? JSON.parse(localStorage.getItem("user")).name : "Hello!"}
                      </Typography>

                      <Typography
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "9px",
                          lineHeight: "14px",
                          color: "#FED600",
                        }}
                      >
                        My account
                      </Typography>
                    </Box>
                  </MenuItem>

                  <MenuItem
                    onClick={() => setUserProfileMenu(null)}
                    className={classes.UserMenuItemStyle}
                  >
                    <img
                      src={myprofile}
                      alt="myProfile"
                      width={12}
                      height={12}
                      style={{ marginRight: 10 }}
                    />
                    My Profile
                  </MenuItem>

                  <MenuItem
                    onClick={() => setUserProfileMenu(null)}
                    className={classes.UserMenuItemStyle}
                  >
                    <img
                      src={healthpoints}
                      alt="myProfile"
                      width={12}
                      height={12}
                      style={{ marginRight: 10 }}
                    // onClick={() => history.push("/HealthyFoodBasket")}
                    />
                    Health Points
                  </MenuItem>

                  <MenuItem
                    onClick={() => setUserProfileMenu(null)}
                    className={classes.UserMenuItemStyle}
                  >
                    <img
                      src={bmicalculator}
                      alt="myProfile"
                      width={12}
                      height={12}
                      style={{ marginRight: 10 }}
                    />
                    BMI Calculator
                  </MenuItem>

                  <MenuItem
                    onClick={() => setUserProfileMenu(null)}
                    style={{ lineHeight: 1.5 }}
                    className={classes.UserMenuItemStyle}
                  >
                    <img
                      src={NutritionGapCal}
                      alt="myProfile"
                      width={12}
                      height={12}
                      style={{ marginRight: 10 }}
                    />
                    Nutrition Gap
                    <br />
                    Calculator
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      setUserProfileMenu(null);
                      history.push("/HealthyFoodBasket")
                    }}
                    className={classes.UserMenuItemStyle}
                  >
                    <img
                      src={HealthyFoodBasket}
                      alt="myProfile"
                      width={12}
                      height={12}
                      style={{ marginRight: 10 }}
                    />
                    Healthy Food Basket
                  </MenuItem>

                  <MenuItem
                    onClick={() => setUserProfileMenu(null)}
                    className={classes.UserMenuItemStyle}
                  >
                    <img
                      src={Preferences}
                      alt="myProfile"
                      width={12}
                      height={12}
                      style={{ marginRight: 10 }}
                    />
                    Preferences
                  </MenuItem>

                  <MenuItem
                    onClick={() => setUserProfileMenu(null)}
                    className={classes.UserMenuItemStyle}
                  >
                    <img
                      src={Myorder}
                      alt="myProfile"
                      width={12}
                      height={12}
                      style={{ marginRight: 10 }}
                    />
                    My Order
                  </MenuItem>

                  <MenuItem
                    onClick={() => setUserProfileMenu(null)}
                    className={classes.UserMenuItemStyle}
                  >
                    <img
                      src={submitAproduct}
                      alt="myProfile"
                      width={12}
                      height={12}
                      style={{ marginRight: 10 }}
                    />
                    Submit a Product
                  </MenuItem>

                  <MenuItem
                    onClick={() => handelLogoutBtn()}
                    className={classes.UserMenuItemStyle}
                  >
                    <img
                      src={Logout}
                      alt="myProfile"
                      width={12}
                      height={12}
                      style={{ marginRight: 10 }}
                    />
                    Logout
                  </MenuItem>
                </Menu>
              </Box>

            </Box>
          ) : (
            <Box className={classes.SignUpButtonContainerStyle}>
              <Button
                className={classes.SignUpButtonStyle}
                variant="contained"
                onClick={() => history.push("/signup")}
              >
                Login/Signup
              </Button>
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
    //Customise area stop

    // <View style={styles.main}>
    //   <Button title="Signup" onPress={() => history.push("/SocialMediaAccountRegistrationScreen")} />
    // </View>
  );
};

// const styles = StyleSheet.create({
//   main: {
//     flexDirection: "row",
//     justifyContent: "space-evenly",
//     backgroundColor: "#cccccc"
//   }
// });

export default withRouter(TopNav);
