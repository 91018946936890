Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "SearchHistory";
exports.labelBodyText = "SearchHistory Body";

exports.btnExampleTitle = "CLICK ME";
exports.getSearchProductsEndPoint = "bx_block_catalogue/search";
exports.getFilterCategoryEndPoint = "bx_block_categories/categories";

// Customizable Area End