import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
    getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    arrayHolder: any;
    token: string;
    // Customizable Area Start
    loading: boolean;
    addFoodBasketloader: boolean;
    foodBasketListLoader: boolean;
    foodBasketLists: any;
    // Customizable Area End
}

interface SS {
    id: any;
}


export default class FavouriteSmartSearchController extends BlockComponent<Props, S, SS> {
    addFoodBasketmessageId: string = '';
    getFoodBasketApiCallId: string = '';
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage), //{rf}
            // Customizable Area End
        ];

        this.state = {
            arrayHolder: [],
            token: '',
            loading: false,
            addFoodBasketloader: false,
            foodBasketListLoader: false,
            foodBasketLists: [],

        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
    }



    // Customizable Area Start

    // <---------- API call for Add food basket {rf} ---------->
    addFoodBasket = (productId: any, order_name: any) => {
        console.log('I ma call');

        this.setState({
            addFoodBasketloader: true
        })
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': configJSON.applicationJsonContentType,
            token: userToken,
        };

        const httpBody = {
            data: {
                'product_id': productId,
                'order_name': order_name
            },
        };

        // rest api call message create
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        // save rest api resuest id
        this.addFoodBasketmessageId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addFoodBasketApiEndPoint // end point setup
        );

        // set header
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        // sent the body data
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPiMethod // api method
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    //  <---------- API call for food basket list {rf} ---------->
    getFoodBasketList = () => {
        this.setState({
            foodBasketListLoader: true
        })
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getFoodBasketApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethodType);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.addFoodBasketApiEndPoint);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }




    // receive function for API response
    async receive(from: string, message: Message) {


        //  <---------- response for  Add food basket api {rf} ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.addFoodBasketmessageId &&
            this.addFoodBasketmessageId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson?.data) {
                this.setState({
                    addFoodBasketloader: false
                })
                this.getFoodBasketList();

            }

        }

        // end for {rf}

        //  <---------- response for  food basket list {rf} ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getFoodBasketApiCallId &&
            this.getFoodBasketApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson?.data) {
                this.setState({
                    foodBasketLists: responseJson?.data,
                    foodBasketListLoader: false

                })

                console.log('responseJson?.data for add food basket List', responseJson?.data);

            }

        }

        // end for {rf}

    }

    backButton = () => {
        window.location.pathname = "/search"
    }


    navigationBtnFnc = (path: any) => {
        this.props?.navigation?.navigate(path);
    }
    // Customizable Area End
}