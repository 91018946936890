import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
    getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    arrayHolder: any;
    token: string;
    // Customizable Area Start
    loading: boolean;
    addFoodBasketloader: boolean;
    foodBasketListLoader: boolean;
    foodProductDetailsLoader: boolean;
    foodBasketLists: any;
    foodProductDetails: any;
    foodProductNutritionValue: any;
    addFoodBasketErrorText: any;
    noFoodBasketNotification: any;
    // Customizable Area End
}

interface SS {
    id: any;
}


export default class HealthyFoodBasketWebController extends BlockComponent<Props, S, SS> {
    addFoodBasketmessageId: string = '';
    getFoodBasketApiCallId: string = '';
    getHealthFoodDetailsApiCallId: string = '';
    deletefoodBasketApiCallId: string = '';
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage), //{rf}
            // Customizable Area End
        ];

        this.state = {
            arrayHolder: [],
            token: '',
            loading: false,
            addFoodBasketloader: false,
            foodBasketListLoader: false,
            foodProductDetailsLoader: false,
            foodBasketLists: [],
            foodProductDetails: [],
            foodProductNutritionValue: [],
            addFoodBasketErrorText: '',
            noFoodBasketNotification: '',

        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getFoodBasketList();
        const param = new URLSearchParams(window.location.search).get('id')
        if (param)
            this.getHealthProductDetails(param);


    }



    // Customizable Area Start

    // <---------- API call for Add food basket {rf} ---------->
    addFoodBasket = (data: any) => {

        this.setState({
            addFoodBasketloader: true
        })
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': configJSON.applicationJsonContentType,
            token: userToken,
        };

        const httpBody = { data: data };

        // rest api call message create
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        // save rest api resuest id
        this.addFoodBasketmessageId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addFoodBasketApiEndPoint // end point setup
        );

        // set header
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        // sent the body data
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postAPiMethod // api method
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    //  <---------- API call for food basket list {rf} ---------->
    getFoodBasketList = () => {
        this.setState({
            foodBasketListLoader: true
        })
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getFoodBasketApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethodType);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.addFoodBasketApiEndPoint);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // delete food basket
    deleteFoodBasket = (id: any) => {
        this.setState({
            foodBasketListLoader: true,
            foodProductDetailsLoader: true
        })
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deletefoodBasketApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteAPiMethod);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.addFoodBasketApiEndPoint}/:id?order_id=${id}`);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getHealthProductDetails = (id: any) => {
        this.setState({
            foodProductDetailsLoader: true
        })
        const userToken = localStorage.getItem("token");
        const header = {
            'Content-Type': 'application/json',
            token: userToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getHealthFoodDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethodType);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.addFoodBasketApiEndPoint}/:id?order_id=${id}`);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }



    // receive function for API response
    async receive(from: string, message: Message) {


        //  <---------- response for  Add food basket api {rf} ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.addFoodBasketmessageId &&
            this.addFoodBasketmessageId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson?.data) {
                this.setState({
                    addFoodBasketloader: false
                })
                this.getFoodBasketList();
            }
            if (responseJson?.error) {
                this.setState({
                    addFoodBasketErrorText: responseJson?.error
                })
            }

        }

        // end for {rf}

        //  <---------- response for  food basket list {rf} ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getFoodBasketApiCallId &&
            this.getFoodBasketApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson?.data) {
                this.setState({
                    foodBasketLists: responseJson?.data,
                    foodBasketListLoader: false,
                    noFoodBasketNotification: ''

                })
            }
            if (responseJson?.error) {
                this.setState({
                    foodBasketListLoader: false,
                    noFoodBasketNotification: responseJson.error,
                    foodBasketLists: []

                })
                // this.props?.navigation?.navigate('NoProudctFound');
            }

        }

        // end for {rf}

        //  <---------- response for  food basket delete api {rf} ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.deletefoodBasketApiCallId &&
            this.deletefoodBasketApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson?.message) {
                this.setState({
                    foodBasketListLoader: false,
                    foodProductDetailsLoader: false
                })
                this.getFoodBasketList();
                this.navigationBtnFnc('HealthyFoodBasket');
            }

        }

        // end for {rf}



        //  <---------- response for  food basket product Details {rf} ---------->
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getHealthFoodDetailsApiCallId &&
            this.getHealthFoodDetailsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson.error) {
                this.setState({
                    foodProductDetailsLoader: false
                })
                this.navigationBtnFnc('HealthyFoodBasket');
            }
            if (responseJson && !responseJson.error) {
                this.setState({
                    foodProductDetails: responseJson?.product?.data,
                    foodProductNutritionValue: responseJson.nutrition_value[0],
                    foodProductDetailsLoader: false

                })
            }

        }

        // end for {rf}

    }

    backButton = () => {
        window.location.pathname = "/search"
    }


    navigationBtnFnc = (path: any) => {
        this.props?.navigation?.navigate(path);
    }

    pramsCheck = () => {
        const param = new URLSearchParams(window.location.search).get('id')
        console.log('param', param);
    }

// function for navigate to the product details page with GetProductDetailsAdapter {adapter} {add by rifadulDev}
goToProductDetailsPage = (id: any) => {
    localStorage.setItem('proId', id)
    const msg: Message = new Message(getName(MessageEnum.FromProductPageNavigationMessage)); // here nedd to change messageEnum name {adapter} {add by rifadulDev}
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), id); // here nedd to change value {adapter} {add by rifadulDev}
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "ProductDetails"); // here need to change Route name
    this.send(msg);
  }

    // Customizable Area End
}