import React from 'react';
import Loader from '../../../components/src/Loader';
import HealthyFoodBasketWebController, { Props } from './HealthyFoodBasketWebController';
import { Typography, Grid, ButtonBase, IconButton, Divider } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { topHeaderBanner, sideBarBanner, appleImage, Compare } from './assets';
import SideNavbar from './SideNavbar';
import './productDetailsPagestyle.css'
import {
    createStyles,
    withStyles,
    Theme,
} from '@material-ui/core/styles';



const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        media: {
            height: 100,
            width: 200
        },
        productName: {
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "15px",
            lineHeight: "18px",
            height: 50,
            color: "#455A64",
            textAlign: "left",
            whiteSpace: 'break-spaces',
            overflow: "hidden",
            textOverflow: 'ellipsis',
            width: 200,
        },
        niqAvatarA: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            backgroundColor: "#00AB79",
        },
        // navigation side bar 
        navigationSideBar: {
            backgroundImage: `url(${sideBarBanner})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'inherit',
            height: '1000px',
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },

        profileTopBanner: {
            backgroundImage: `url(${topHeaderBanner})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '200px'
        },

        // css for textContainer
        textContainer: {
            display: "flex",
            justifyContent: "space-between",
            // marginLeft: 25,
            // marginRight: 25,
            alignItems: 'center',
            margin: '1.5rem 25px 1.5rem 25px',
            [theme.breakpoints.down("sm")]: {
                alignItem: "baseline",
            },
            [theme.breakpoints.down("xs")]: {
                marginLeft: 5,
                marginRight: 5,
            },
        },
        searchResultText: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "22px",
            lineHeight: "33px",
            color: "#37006E",
            textTransform: 'capitalize',
            [theme.breakpoints.down("sm")]: {
                fontSize: "16px",
                lineHeight: "12px",
            },

        },
        backButtonText: {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "22px",
            lineHeight: "12px",
            color: "#FF5050",
            cursor: 'pointer',
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px",

            },
        },
        FoodTypeUnderline: {
            textUnderlineOffset: "0.5rem",
            textDecoration: "underline",
            textDecorationColor: "#fb951a61",
        },

        // css for foodBasketContainer
        foodBasketContainer: {
            margin: '1rem 25px 1.5rem 25px',
        },
        basketProduct: {
            // width: '90%',
            border: "double 1px transparent",
            borderRadius: '10px',
            backgroundImage:
                "linear-gradient(white, white), linear-gradient(200deg,#41479B,#FF3939)",
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
        },
        deleteButton: {

            width: '50px',
            height: '50px',
            border: "double 1px transparent",
            borderRadius: '50%',
            backgroundImage:
                "linear-gradient(white, white), linear-gradient(200deg,#41479B,#FF3939)",
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
        },

        // side nav section
        profileSection: {

        },
        profileContainer: {
            padding: '4rem 0',
        },
        userNameText: {
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: '25px',
            color: '#FFFFFF'
        },
        userAccountText: {
            fontFamily: 'Poppins',
            fontWeight: 400,
            fontSize: '20px',
            color: '#FED600'
        },
        sideNavIcon: {
            color: '#FFFFFF',
        },
        sideNavText: {
            color: '#FFFFFF',
        },
        sideNavcontentBg: {

        },

        // food products container
        foodProductName: {
            color: '#290743',
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: '27px',
            textTransform: 'capitalize',
            [theme.breakpoints.down("sm")]: {
                fontSize: '14px',
            },
        },
        totalFoodProduct: {
            color: '#263238',
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: '20px',
            [theme.breakpoints.down("sm")]: {
                fontSize: '12px',
            },
        },
        PtFoodProduct: {
            color: '#38006F',
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: '27px',
            [theme.breakpoints.down("sm")]: {
                fontSize: '15px',
            },
        },
        // health product details
        dividerColor: {
            backgroundColor: "red",
            color: "red"
        },
        productGoodQualityText: {
            textAlign: "center",
            color: '#0EAA58',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600
        },
        productBadQualityText: {
            textAlign: "center",
            color: '#EF502E',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600
        },
        listItemName: {
            // textAlign: "center",
            color: '#263238',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 500,
            fontSize: '17px'
        },
        healthPoint: {
            fontSize: '35px',
            textAlign: "center",
            color: '#FFFFFF',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            [theme.breakpoints.only('sm')]: {
                fontSize: '28px',
            }
        },
        healthPointGainText: {
            textAlign: "center",
            color: '#FFFFFF',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '2.5rem',
            [theme.breakpoints.only('sm')]: {
                fontSize: '17px',
            }
        },

        healthProductName: {
            color: '#455A64',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            fontSize: '22px',
            [theme.breakpoints.only("xs")]: {
                fontSize: '13px',
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: '17px',
            },
        },
        healthProductNiqScore: {
            color: '#757D8A',
            fontFamily: `'Montserrat', sans-serif`,
            fontWeight: 600,
            fontSize: '20px',
            [theme.breakpoints.only("xs")]: {
                fontSize: '13px',
            },
            // [theme.breakpoints.only("sm")]: {
            //     fontSize: '17px',
            // },
        },
        TotalProductText: {
            color: '#455A64',
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 600,
            fontSize: '25px',
        },

        childTextStyle: {
            fontFamily: `'Poppins', sans-serif`,
            fontWeight: 500,
            fontSize: '18px'
        },
        productRating: {
            height: '30px',
            width: '30px',

            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 2rem',
            [theme.breakpoints.only('xs')]: {
                height: '25px',
                width: '25px',
            }
        },
        healthFoodImage: {
            width: '130px',
            height: '130px',
            [theme.breakpoints.only("xs")]: {
                width: '70px',
                height: '70px',
            }
        },
        deleteIconBtn: {
            color: '#FF3939', border: '1px solid #FF784B',
            width: '40px',
            height: '40px',
            marginLeft: '1rem'
        },
        healthPoitGrid: {
            backgroundColor: '#38006D',
            borderRadius: '10px',
            color: '#ffff',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        productQualityContainer: {
            border: '1px solid #37006E',
            borderRadius: '10px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly'
        },
        deleteAndCompareContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center'
        },

    });

class HealthyFoodDetailsPageWeb extends HealthyFoodBasketWebController {
    // Customizable Area Start
    render() {
        const {
            loading,
            foodProductDetailsLoader,
            foodProductDetails,
            foodProductNutritionValue
        } = this.state;
        const { classes } = this.props;
        if (foodProductDetailsLoader) {
            return (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '100vh' }}
                >
                    <Loader loading={foodProductDetailsLoader} />
                </Grid>
            );
        }

        return (
            <div style={{ width: '100%', margin: '4rem 0' }} className={classes.root}>
                <Grid container>
                    <Grid item sm={false} md={2} className={classes.navigationSideBar}>
                        <SideNavbar />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10}>
                        <div className={classes.profileTopBanner}>
                        </div>

                        <div className={classes.textContainer}>
                            <div className={classes.searchResultText}>
                                <span className={classes.FoodTypeUnderline}>{foodProductDetails?.attributes?.order_name}</span>
                                <IconButton aria-label="delete" className={classes.deleteIconBtn} onClick={() => this.deleteFoodBasket(foodProductDetails?.attributes?.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                            <Typography className={classes.backButtonText} onClick={() => this.navigationBtnFnc('HealthyFoodBasket')}>
                                Back
                            </Typography>
                        </div>


                        <div className={classes.foodBasketContainer}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={3} md={3} >
                                    <div className={`${classes.paper} ${classes.healthPoitGrid}`} >
                                        <Typography className={classes.healthPoint}>+--</Typography>
                                        <Typography className={classes.healthPointGainText}>Health <br /> Points Gain</Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={9} md={9}>
                                    <div className={`${classes.paper} ${classes.productQualityContainer}`}>
                                        <Grid container spacing={3}>

                                            <Grid item xs={5}>
                                                <Typography className={classes.productGoodQualityText}> <i className="fa-solid fa-face-laugh-beam"></i> Positives ({foodProductNutritionValue?.positive_points})</Typography>
                                            </Grid>
                                            <Grid item xs={2}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center"
                                                }}
                                            >
                                                <Divider orientation="vertical" style={{ backgroundColor: "#38006D", width: "2%" }} />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography className={classes.productBadQualityText}><i className="fa-solid fa-face-frown-open"></i> Negatives ({foodProductNutritionValue?.negative_points})</Typography>
                                            </Grid>

                                        </Grid>
                                        <Grid container spacing={3}>

                                            <Grid item xs={5}>
                                                <Typography className={classes.listItemName}>
                                                    Energy: {foodProductNutritionValue?.positive?.energy}
                                                </Typography>
                                                <Typography className={classes.listItemName}>
                                                    Vitamin: {foodProductNutritionValue?.positive?.minerals}
                                                </Typography>
                                                <Typography className={classes.listItemName}>
                                                    Minerals: {foodProductNutritionValue?.positive?.vitamin}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center"
                                                }}
                                            >
                                                <Divider orientation="vertical" style={{ backgroundColor: "#FF3939", width: "2%" }} />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography className={classes.listItemName}>
                                                    Fat: {foodProductNutritionValue?.negative?.fat}
                                                </Typography>
                                                <Typography className={classes.listItemName}>
                                                    Sugar: {foodProductNutritionValue?.negative?.sugar}
                                                </Typography>

                                            </Grid>

                                        </Grid>

                                    </div>
                                </Grid>

                            </Grid>

                            <div style={{ margin: '3rem 0' }}>
                                <Typography className={classes.TotalProductText}>Total Products: {foodProductDetails?.attributes?.product_count}</Typography>
                                {
                                    foodProductDetails?.attributes?.order_id?.data.map((item: any, key: any) => {
                                        return (
                                            <Grid container spacing={2} style={{ margin: '1rem 0' }} className={classes.basketProduct} key={key}>
                                                <Grid item xs={12} sm container >
                                                    <Grid item>
                                                        {/* <ButtonBase className={classes.image}> */}
                                                        <img className={classes.healthFoodImage} alt="complex" src={item?.attributes?.product_id?.data?.attributes?.image} />
                                                        {/* </ButtonBase> */}
                                                    </Grid>
                                                    <Grid item xs container direction="column" spacing={2}>
                                                        {/* @ts-ignore */}
                                                        <Grid item xs onClick={(e:any)=>this.goToProductDetailsPage(item?.attributes?.product_id?.data?.attributes?.id)}
                                                        style={{cursor:"pointer"}}
                                                        >
                                                            <Typography gutterBottom variant="subtitle1" className={classes.healthProductName}>
                                                                {item?.attributes?.product_id?.data?.attributes?.product_name}
                                                            </Typography>
                                                            <div style={{ display: 'flex' }}>
                                                                <Typography variant="body2" gutterBottom className={classes.healthProductNiqScore}>
                                                                    NIQ Score
                                                                </Typography>
                                                                <div
                                                                    className={`${classes.productRating} 
                                                                child_${item?.attributes?.product_id?.data?.attributes?.product_rating} 
                                                                ${classes.childTextStyle} 
                                                                ${`bgColor_${item?.attributes?.product_id?.data?.attributes?.product_rating}`}`}
                                                                >
                                                                    {item?.attributes?.product_id?.data?.attributes?.product_rating}</div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item className={classes.deleteAndCompareContainer}>

                                                        <IconButton aria-label="delete">
                                                            <img src={Compare} alt="Compare" />
                                                        </IconButton>
                                                        <IconButton aria-label="delete" style={{
                                                            color: '#FF3939',
                                                        }}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                    )
                                }
                            </div>
                        </div>



                    </Grid >
                </Grid >

            </div >
        );
    }
}
// Customizable Area End

export default withStyles(styles, { withTheme: true })(
    HealthyFoodDetailsPageWeb
);