import React from "react";
// Customizable Area Start
// import validator from "validator";
import ReactDOM from "react-dom";
import ReactCustomFlagSelect from "react-custom-flag-select";
import "react-custom-flag-select/lib/react-custom-flag-select.min.css";
import { Textbox } from "react-inputs-validation";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
// import { FLAGS } from "./consts";
// Customizable Area End


// import "./styles.css";
// Customizable Area Start
const find = (arr, obj) => {
  const res = [];
  arr.forEach((o) => {
    let match = false;
    Object.keys(obj).forEach((i) => {
      if (obj[i] === o[i]) {
        match = true;
      }
    });
    if (match) {
      res.push(o);
    }
  });
  return res;
};
// Customizable Area End


// Customizable Area Start
const FLAG_SELECTOR_OPTION_LIST = [
  {
    id: "+91",
    name: "IN",
    displayText: "IN(91)",
    englishName: "India",
    flag: 'https://countryflagsapi.com/svg/in'
  },
  {
    id: "+1",
    name: "US",
    displayText: "US(1)",
    englishName: "United States",
    locale: "en-US",
    flag: 'https://countryflagsapi.com/svg/re'
  },
  {
    id: "+971",
    name: "AE",
    displayText: "AE(971)",
    englishName: "United Arab Emirates",
    flag: ' https://countryflagsapi.com/svg/ae'
  },
  {
    id: "+376",
    name: "AD",
    displayText: "AD(376)",
    englishName: "Andorra",
    flag: 'https://countryflagsapi.com/svg/ad'
  },
  {
    id: "+93",
    name: "AF",
    displayText: "AF(93)",
    englishName: "Afghanistan",
    flag: 'https://countryflagsapi.com/svg/af'
  },
  {
    id: "+1",
    name: "AG",
    displayText: "AG(1)",
    englishName: "Antigua And Barbuda",
    flag: 'https://countryflagsapi.com/svg/ag'
  },
  {
    id: "+1",
    name: "AI",
    displayText: "AI(1)",
    englishName: " Anguilla",
    flag: 'https://countryflagsapi.com/svg/ai'
  },
  {
    id: "+355",
    name: "AL",
    displayText: "AL(355)",
    englishName: "Albania",
    flag: 'https://countryflagsapi.com/svg/al'
  },
  {
    id: "+374",
    name: "AM",
    displayText: "AM(374)",
    englishName: "Armenia",
    flag: 'https://countryflagsapi.com/svg/am'
  },
  {
    id: "+244",
    name: "AO",
    displayText: "AO(244)",
    englishName: "Angola",
    flag: 'https://countryflagsapi.com/svg/ao'
  },
  {
    id: "+672",
    name: "AQ",
    displayText: "AQ(672)",
    englishName: "Antarctica",
    flag: 'https://countryflagsapi.com/svg/aq'
  },
  {
    id: "+1-684",
    name: "AS",
    displayText: "AS(1-684)",
    englishName: "American Samoa",
    flag: 'https://countryflagsapi.com/svg/as'
  },
  {
    id: "+43",
    name: "AT",
    displayText: "AT(43)",
    englishName: "Austria",
    flag: 'https://countryflagsapi.com/svg/at'
  },
  {
    id: "+61",
    name: "AU",
    displayText: "AU(61)",
    englishName: "Australia",
    flag: 'https://countryflagsapi.com/svg/au'
  },
  {
    id: "+358",
    name: "AX",
    displayText: "AX(358)",
    englishName: "åland Islands",
    flag: 'https://countryflagsapi.com/svg/ax'
  },
  {
    id: "+994",
    name: "AZ",
    displayText: "AZ(994)",
    englishName: "Azerbaijan ",
    flag: 'https://countryflagsapi.com/svg/az'
  },
  {
    id: "+387",
    name: "BA",
    displayText: "BA(387)",
    englishName: "Bosnia And Herzegovina",
    flag: 'https://countryflagsapi.com/svg/ba'
  },
  {
    id: "+1-246",
    name: "BB",
    displayText: "BB(1-246)",
    englishName: "Barbados",
    flag: 'https://countryflagsapi.com/svg/bb'
  },
  {
    id: "+880",
    name: "BD",
    displayText: "BD(880)",
    englishName: "Bangladesh",
    flag: 'https://countryflagsapi.com/svg/bd'
  },
  {
    id: "+32",
    name: "BE",
    displayText: "BE(32)",
    englishName: "Belgium",
    flag: 'https://countryflagsapi.com/svg/be'
  },
  {
    id: "+226",
    name: "BF",
    displayText: "BF(226)",
    englishName: "Burkina Faso",
    flag: 'https://countryflagsapi.com/svg/bf'
  },
  {
    id: "+359",
    name: "BG",
    displayText: "BG(359)",
    englishName: "Bulgaria",
    flag: 'https://countryflagsapi.com/svg/bg'
  },
  {
    id: "+973",
    name: "BH",
    displayText: "BH(973)",
    englishName: "Bahrain",
    flag: 'https://countryflagsapi.com/svg/bh'
  },
  {
    id: "+257",
    name: "BI",
    displayText: "BI(257)",
    englishName: "Burundi",
    flag: 'https://countryflagsapi.com/svg/bi'
  },
  {
    id: "+229",
    name: "BJ",
    displayText: "BJ(229)",
    englishName: "Benin",
    flag: 'https://countryflagsapi.com/svg/bj'
  },
  {
    id: "+590",
    name: "BL",
    displayText: "BL(590)",
    englishName: "Saint Barthélemy",
    flag: 'https://countryflagsapi.com/svg/bl'
  },
  {
    id: "+1-441",
    name: "BM",
    displayText: "BM(1-441)",
    englishName: " Bermuda",
    flag: 'https://countryflagsapi.com/svg/bm'
  },
  {
    id: "+673",
    name: "BN",
    displayText: "BN(673)",
    englishName: "Brunei Darussalam",
    flag: 'https://countryflagsapi.com/svg/bn'
  },
  {
    id: "+591",
    name: "BO",
    displayText: "BO(591)",
    englishName: "Bolivia",
    flag: 'https://countryflagsapi.com/svg/bo'
  },
  {
    id: "+599",
    name: "BQ",
    displayText: "BQ(599)",
    englishName: "Bonaire",
    flag: 'https://countryflagsapi.com/svg/bq'
  },
  {
    id: "+55",
    name: "BR",
    displayText: "BR(55)",
    englishName: "Brazil",
    flag: 'https://countryflagsapi.com/svg/br'
  },
  {
    id: "+1-242",
    name: "BS",
    displayText: "BS(1-242)",
    englishName: "The Bahamas",
    flag: 'https://countryflagsapi.com/svg/bs'
  },
  {
    id: "+975",
    name: "BT",
    displayText: "BT(975)",
    englishName: "Bhutan",
    flag: 'https://countryflagsapi.com/svg/bt'
  },
  {
    id: "+55",
    name: "BV",
    displayText: "BV(55)",
    englishName: "Bouvet Island",
    flag: 'https://countryflagsapi.com/svg/bv'
  },
  {
    id: "+267",
    name: "BW",
    displayText: "BW(267)",
    englishName: "Botswana",
    flag: 'https://countryflagsapi.com/svg/bw'
  },
  {
    id: "+375",
    name: "BY",
    displayText: "BY(375)",
    englishName: "Belarus",
    flag: 'https://countryflagsapi.com/svg/by'
  },
  {
    id: "+501",
    name: "BZ",
    displayText: "BZ(501)",
    englishName: "Belize",
    flag: 'https://countryflagsapi.com/svg/bz'
  },
  {
    id: "+1",
    name: "CA",
    displayText: "CA(1)",
    englishName: "Canada ",
    flag: 'https://countryflagsapi.com/svg/ca'
  },
  {
    id: "+61",
    name: "CC",
    displayText: "CC(61)",
    englishName: "The Cocos Islands",
    flag: 'https://countryflagsapi.com/svg/cc'
  },
  {
    id: "+243",
    name: "CD",
    displayText: "CD(243)",
    englishName: "Democratic Republic Of Congo",
    flag: 'https://countryflagsapi.com/svg/cd'
  },
  {
    id: "+236",
    name: "CF",
    displayText: "CF(236)",
    englishName: "The Central African Republic",
    flag: 'https://countryflagsapi.com/svg/ax'
  },
  {
    id: "+243",
    name: "CG",
    displayText: "CG(243)",
    englishName: "Congo",
    flag: 'https://countryflagsapi.com/svg/cg'
  },
  {
    id: "+41",
    name: "CH",
    displayText: "CH(41)",
    englishName: "Switzerland",
    flag: 'https://countryflagsapi.com/svg/ch'
  },
  {
    id: "+225",
    name: "CI",
    displayText: "CI(225)",
    englishName: "Côte D'ivoire",
    flag: 'https://countryflagsapi.com/svg/ci'
  },
  {
    id: "+682",
    name: "CK",
    displayText: "CK(682)",
    englishName: "The Cook Islands",
    flag: 'https://countryflagsapi.com/svg/ck'
  },
  {
    id: "+56",
    name: "CL",
    displayText: "CL(56)",
    englishName: "Chile",
    flag: 'https://countryflagsapi.com/svg/cl'
  },
  {
    id: "+237",
    name: "CM",
    displayText: "CM(237)",
    englishName: "Cameroon",
    flag: 'https://countryflagsapi.com/svg/cm'
  },
  {
    id: "+86",
    name: "CN",
    displayText: "CN(86)",
    englishName: "China",
    flag: 'https://countryflagsapi.com/svg/cn'
  },
  {
    id: "+57",
    name: "CO",
    displayText: "CO(57)",
    englishName:  "Colombia",
    flag: 'https://countryflagsapi.com/svg/co'
  },
  {
    id: "+506",
    name: "CR",
    displayText: "CR(506)",
    englishName: "Costa Rica",
    flag: 'https://countryflagsapi.com/svg/cr'
  },
  {
    id: "+53",
    name: "CU",
    displayText: "CU(53)",
    englishName: "Cuba",
    flag: 'https://countryflagsapi.com/svg/cu'
  },
  {
    id: "+238",
    name: "CV",
    displayText: "CV(238)",
    englishName: "Cabo Verde",
    flag: 'https://countryflagsapi.com/svg/cv'
  },
  {
    id: "+599",
    name: "CW",
    displayText: "CW(599)",
    englishName: "Curaçao",
    flag: 'https://countryflagsapi.com/svg/cw'
  },
  {
    id: "+61",
    name: "CX",
    displayText: "CX(61)",
    englishName: "Christmas Island",
    flag: 'https://countryflagsapi.com/svg/cx'
  },
  {
    id: "+357",
    name: "CY",
    displayText: "CY(357)",
    englishName: "Cyprus",
    flag: 'https://countryflagsapi.com/svg/cy'
  },
  {
    id: "+420",
    name: "CZ",
    displayText: "CZ(420)",
    englishName: "Czechia",
    flag: 'https://countryflagsapi.com/svg/cz'
  },
  {
    id: "+49",
    name: "DE",
    displayText: "DE(49)",
    englishName: "Germany",
    flag: 'https://countryflagsapi.com/svg/de'
  },
  {
    id: "+253",
    name: "DJ",
    displayText: "DJ(253)",
    englishName: "Djibouti",
    flag: 'https://countryflagsapi.com/svg/dj'
  },
  {
    id: "+45",
    name: "DK",
    displayText: "DK(45)",
    englishName: "Denmark",
    flag: 'https://countryflagsapi.com/svg/dk'
  },
  {
    id: "+1-767",
    name: "DM",
    displayText: "DM(1-767)",
    englishName: "Dominica",
    flag: 'https://countryflagsapi.com/svg/dm'
  },
  {
    id: "+1-809",
    name: "DO",
    displayText: "DO(1-809)",
    englishName: "Dominican Republic",
    flag: 'https://countryflagsapi.com/svg/do'
  },
  {
    id: "+213",
    name: "DZ",
    displayText: "DZ(213)",
    englishName: " Algeria",
    flag: 'https://countryflagsapi.com/svg/dz'
  },
  {
    id: "+593",
    name: "EC",
    displayText: "EC(593)",
    englishName: "Ecuador",
    flag: 'https://countryflagsapi.com/svg/ec'
  },
  {
    id: "+372",
    name: "EE",
    displayText: "EE(372)",
    englishName: "Estonia",
    flag: 'https://countryflagsapi.com/svg/ee'
  },
  {
    id: "+20",
    name: "EG",
    displayText: "EG(20)",
    englishName: "Egypt",
    flag: 'https://countryflagsapi.com/svg/eg'
  },
  {
    id: "+212",
    name: "EH",
    displayText: "EH(212)",
    englishName: "Western Sahara",
    flag: 'https://countryflagsapi.com/svg/eh'
  },
  {
    id: "+291",
    name: "ER",
    displayText: "ER(291)",
    englishName: "Eritrea",
    flag: 'https://countryflagsapi.com/svg/er'
  },
  {
    id: "+34",
    name: "ES",
    displayText: "ES(34)",
    englishName: "Spain",
    flag: 'https://countryflagsapi.com/svg/es'
  },
  {
    id: "+251",
    name: "ET",
    displayText: "ET(251)",
    englishName: "Ethiopia",
    flag: 'https://countryflagsapi.com/svg/et'
  },
  {
    id: "+358",
    name: "FI",
    displayText: "FI(358)",
    englishName: "Finland",
    flag: 'https://countryflagsapi.com/svg/fi'
  },
  {
    id: "+679",
    name: "FJ",
    displayText: "FJ(679)",
    englishName: "Fiji",
    flag: 'https://countryflagsapi.com/svg/fj'
  },
  {
    id: "+500",
    name: "FK",
    displayText: "FK(500)",
    englishName: "The Falkland Islands",
    flag: 'https://countryflagsapi.com/svg/fk'
  },
  {
    id: "+691",
    name: "FM",
    displayText: "FM(691)",
    englishName: "The Federated States Of Micronesia",
    flag: 'https://countryflagsapi.com/svg/fm'
  },
  {
    id: "+298",
    name: "FO",
    displayText: "FO(298)",
    englishName: "The Faroe Islands",
    flag: 'https://countryflagsapi.com/svg/fo'
  },
  {
    id: "+33",
    name: "FR",
    displayText: "FR(33)",
    englishName: "France",
    flag: 'https://countryflagsapi.com/svg/fr'
  },
  {
    id: "+241",
    name: "GA",
    displayText: "GA(241)",
    englishName: "Gabon",
    flag: 'https://countryflagsapi.com/svg/ga'
  },
  {
    id: "+44",
    name: "GB-ENG",
    displayText: "GB-ENG(44)",
    englishName: "England",
    flag: 'https://countryflagsapi.com/svg/gb-eng'
  },
  {
    id: "+44",
    name: "GB-NIR",
    displayText: "GB-NIR(44)",
    englishName: "Northern Ireland",
    flag: 'https://countryflagsapi.com/svg/gb-nir'
  },
  {
    id: "+44 ",
    name: "GB-SCT",
    displayText: "GB-SCT(44)",
    englishName: "Scotland",
    flag: 'https://countryflagsapi.com/svg/ gb-sct'
  },
  {
    id: "+44",
    name: "GB-WLS",
    displayText: "GB-WLS(44)",
    englishName: "Wales",
    flag: 'https://countryflagsapi.com/svg/gb-wls'
  },
  {
    id: "+44",
    name: "GB",
    displayText: "GB(44)",
    englishName: "The United Kingdom Of Great Britain And Northern Ireland",
    flag: 'https://countryflagsapi.com/svg/gb'
  },
  {
    id: "+1-473",
    name: "GD",
    displayText: "GD(1-473)",
    englishName: "Grenada",
    flag: 'https://countryflagsapi.com/svg/gd'
  },
  {
    id: "+995",
    name: "GE",
    displayText: "GE(995)",
    englishName: "Georgia",
    flag: 'https://countryflagsapi.com/svg/ge'
  },
  {
    id: "+594",
    name: "GF",
    displayText: "GF(594)",
    englishName: "French Guiana",
    flag: 'https://countryflagsapi.com/svg/gf'
  },
  {
    id: "+44-1481",
    name: "GG",
    displayText: "GG(44-1481)",
    englishName: "Guernsey",
    flag: 'https://countryflagsapi.com/svg/gg'
  },
  {
    id: "+233",
    name: "GH",
    displayText: "GH(233)",
    englishName: "Ghana",
    flag: 'https://countryflagsapi.com/svg/gh'
  },
  {
    id: "+350",
    name: "GI",
    displayText: "GI(350)",
    englishName: "Gibraltar",
    flag: 'https://countryflagsapi.com/svg/gi'
  },
  {
    id: "+299",
    name: "GL",
    displayText: "GL(299)",
    englishName: "Greenland",
    flag: 'https://countryflagsapi.com/svg/gl'
  },
  {
    id: "+220",
    name: "GM",
    displayText: "GM(220)",
    englishName: "Gambia",
    flag: 'https://countryflagsapi.com/svg/gm'
  },
  {
    id: "+224",
    name: "GN",
    displayText: "GN(224)",
    englishName: "Guinea",
    flag: 'https://countryflagsapi.com/svg/gn'
  },
  {
    id: "+590",
    name: "GP",
    displayText: "GP(590)",
    englishName: "Guadeloupe",
    flag: 'https://countryflagsapi.com/svg/gp'
  },
  {
    id: "240",
    name: "GQ",
    displayText: "GQ(240)",
    englishName: "Equatorial Guinea",
    flag: 'https://countryflagsapi.com/svg/gq'
  },
  {
    id: "+30",
    name: "GR",
    displayText: "GR(30)",
    englishName: "Greece",
    flag: 'https://countryflagsapi.com/svg/gr'
  },
  {
    id: "+500",
    name: "GS",
    displayText: "GS(500)",
    englishName: "South Georgia And The South Sandwich Islands",
    flag: 'https://countryflagsapi.com/svg/gs'
  },
  {
    id: "+502",
    name: "GT",
    displayText: "GT(502)",
    englishName: "Guatemala",
    flag: 'https://countryflagsapi.com/svg/gt'
  },
  {
    id: "+1-671",
    name: "GU",
    displayText: "GU(1-671)",
    englishName: "Guam",
    flag: 'https://countryflagsapi.com/svg/gu'
  },
  {
    id: "+245",
    name: "GW",
    displayText: "GW(245)",
    englishName: "Guinea-bissau",
    flag: 'https://countryflagsapi.com/svg/gw'
  },
  {
    id: "+592",
    name: "GY",
    displayText: "GY(592)",
    englishName: "Guyana",
    flag: 'https://countryflagsapi.com/svg/gy'
  },
  {
    id: "+852",
    name: "HK",
    displayText: "HK(852)",
    englishName: "Hong Kong",
    flag: 'https://countryflagsapi.com/svg/hk'
  },
  {
    id: "+504",
    name: "HN",
    displayText: "HN(504)",
    englishName: "Honduras",
    flag: 'https://countryflagsapi.com/svg/hn'
  },
  {
    id: "+385",
    name: "HR",
    displayText: "HN(385)",
    englishName: "Croatia",
    flag: 'https://countryflagsapi.com/svg/hr'
  },
  {
    id: "+509",
    name: "HT",
    displayText: "HT(509)",
    englishName: "Haiti",
    flag: 'https://countryflagsapi.com/svg/ht'
  },
  {
    id: "+36",
    name: "HU",
    displayText: "HU(36)",
    englishName: "Hungary",
    flag: 'https://countryflagsapi.com/svg/hu'
  },
  {
    id: "+353",
    name: "IE",
    displayText: "IE(353)",
    englishName: "Ireland",
    flag: 'https://countryflagsapi.com/svg/ie'
  },
  {
    id: "+972",
    name: "IL",
    displayText: "IL(972)",
    englishName: "Israel",
    flag: 'https://countryflagsapi.com/svg/il'
  },  
  {
    id: "+44",
    name: "IM",
    displayText: "IM(44)",
    englishName: "Isle Of Man",
    flag: 'https://countryflagsapi.com/svg/im'
  },
  {
    id: "+246",
    name: "IO",
    displayText: "IO(246)",
    englishName: "The British Indian Ocean Territory",
    flag: 'https://countryflagsapi.com/svg/io'
  },  
  {
    id: "+964",
    name: "IQ",
    displayText: "IQ(964)",
    englishName: "Iraq",
    flag: 'https://countryflagsapi.com/svg/iq'
  },  
  {
    id: "+98",
    name: "IR",
    displayText: "IR(98)",
    englishName: "Iran",
    flag: 'https://countryflagsapi.com/svg/ir'
  },  
  {
    id: "+354",
    name: "IS",
    displayText: "IS(354)",
    englishName: "Iceland",
    flag: 'https://countryflagsapi.com/svg/is'
  },  
  {
    id: "+39",
    name: "IT",
    displayText: "IT(139)",
    englishName: "Italy",
    flag: 'https://countryflagsapi.com/svg/it'
  },  
  {
    id: "+44",
    name: "JE",
    displayText: "JE(44)",
    englishName: "Jersey",
    flag: 'https://countryflagsapi.com/svg/je'
  },  
  {
    id: "+1-876",
    name: "JM",
    displayText: "JM(1-876)",
    englishName: "Jamaica",
    flag: 'https://countryflagsapi.com/svg/jm'
  },  
  {
    id: "+962",
    name: "JO",
    displayText: "JO(962)",
    englishName: "Jordan",
    flag: 'https://countryflagsapi.com/svg/jo'
  },  
  {
    id: "+254",
    name: " KE",
    displayText: "KE(254)",
    englishName: "Kenya",
    flag: 'https://countryflagsapi.com/svg/ke'
  },  
  {
    id: "+996",
    name: "KG",
    displayText: "KG(996)",
    englishName: "Kyrgyzstan",
    flag: 'https://countryflagsapi.com/svg/kg'
  },  
  {
    id: "+855",
    name: "KH",
    displayText: "KH(855)",
    englishName: "Cambodia",
    flag: 'https://countryflagsapi.com/svg/kh'
  },  
  {
    id: "+ 686",
    name: " KI",
    displayText: " KI(686)",
    englishName: "Kiribati",
    flag: 'https://countryflagsapi.com/svg/ki'
  },  
  {
    id: "+269",
    name: "KM",
    displayText: "KM(269)",
    englishName: "The Comoros",
    flag: 'https://countryflagsapi.com/svg/km'
  },  
  {
    id: "+1-869",
    name: "KN",
    displayText: "KN(1-869)",
    englishName: "Saint Kitts And Nevis",
    flag: 'https://countryflagsapi.com/svg/kn'
  },  
  {
    id: "+850",
    name: "KP",
    displayText: "KP(850)",
    englishName: "The Democratic People's Republic Of Korea",
    flag: 'https://countryflagsapi.com/svg/kp'
  },  
  {
    id: "+82",
    name: "KR",
    displayText: "KR(82)",
    englishName: "The Republic Of Korea",
    flag: 'https://countryflagsapi.com/svg/kr'
  },  
  {
    id: "+965",
    name: "KW",
    displayText: "KW(965)",
    englishName: "Kuwait",
    flag: 'https://countryflagsapi.com/svg/kw'
  },  
  {
    id: "+1-345",
    name: "KY",
    displayText: "KY(1-345)",
    englishName: "The Cayman Islands",
    flag: 'https://countryflagsapi.com/svg/ky'
  },  
  {
    id: "+385",
    name: "KZ",
    displayText: "KZ(385)",
    englishName: "Croatia",
    flag: 'https://countryflagsapi.com/svg/kz'
  },  
  {
    id: "+856",
    name: "LA",
    displayText: "LA(856)",
    englishName: "The Lao People's Democratic Republic",
    flag: 'https://countryflagsapi.com/svg/la'
  },  
  {
    id: "+961",
    name: "LB",
    displayText: "LB(961)",
    englishName: "Lebanon",
    flag: 'https://countryflagsapi.com/svg/lb'
  },  
  {
    id: "+1-758",
    name: "LC",
    displayText: "LC(1-758)",
    englishName: "Saint Lucia",
    flag: 'https://countryflagsapi.com/svg/lc'
  },  
  {
    id: "+423",
    name: "LI",
    displayText: "LI(423)",
    englishName: "Liechtenstein",
    flag: 'https://countryflagsapi.com/svg/li'
  },  
  {
    id: "+94",
    name: "LK",
    displayText: "LK(94)",
    englishName: "Sri Lanka",
    flag: 'https://countryflagsapi.com/svg/lk'
  },  
  {
    id: "+231",
    name: "LR",
    displayText: "LR(231)",
    englishName: "Liberia",
    flag: 'https://countryflagsapi.com/svg/lr'
  },  
  {
    id: "+266",
    name: "LS",
    displayText: "LS(266)",
    englishName: "Lesotho",
    flag: 'https://countryflagsapi.com/svg/ls'
  },  
  {
    id: "+370",
    name: "LT",
    displayText: "LT(370)",
    englishName: "Lithuania",
    flag: 'https://countryflagsapi.com/svg/lt'
  },  
  {
    id: "+352",
    name: "LU",
    displayText: "LU(352)",
    englishName: "Luxembourg",
    flag: 'https://countryflagsapi.com/svg/lu'
  },  
  {
    id: "+371",
    name: "LV",
    displayText: "LV(371)",
    englishName: "Latvia",
    flag: 'https://countryflagsapi.com/svg/lv'
  },  
  {
    id: "+218",
    name: "LY",
    displayText: "LY(218)",
    englishName: "Libya",
    flag: 'https://countryflagsapi.com/svg/ly'
  },  
  {
    id: "+212",
    name: "MA",
    displayText: "MA(212)",
    englishName: "Morocco",
    flag: 'https://countryflagsapi.com/svg/ma'
  },  
  {
    id: "+377",
    name: "MC",
    displayText: "MC(377)",
    englishName: "Monaco",
    flag: 'https://countryflagsapi.com/svg/mc'
  },  
  {
    id: "+373",
    name: "MD",
    displayText: "MD(373)",
    englishName: "The Republic Of Moldova",
    flag: 'https://countryflagsapi.com/svg/md'
  },  
  {
    id: "+382",
    name: "ME",
    displayText: "ME(382)",
    englishName: "Montenegro",
    flag: 'https://countryflagsapi.com/svg/me'
  },  
  // {
  //   id: "+663",
  //   name: "MF",
  //   displayText: "MF(663)",
  //   englishName: "Saint Martin",
  //   flag: 'https://countryflagsapi.com/svg/mf'
  // },  





  
  {
    id: "+261",
    name: "MG",
    displayText: "MG(261)",
    englishName: "Madagascar",
    flag: 'https://countryflagsapi.com/svg/mg'
  },    
  {
    id: "+692",
    name: "MH",
    displayText: "MH(692)",
    englishName: "The Marshall Islands",
    flag: 'https://countryflagsapi.com/svg/mh'
  },  
  {
    id: "+389",
    name: "MK",
    displayText: "MK(389)",
    englishName: "Republic Of North Macedonia",
    flag: 'https://countryflagsapi.com/svg/mk'
  },  
  {
    id: "+223",
    name: "ML",
    displayText: "ML(223)",
    englishName: "Mali",
    flag: 'https://countryflagsapi.com/svg/ml'
  },  
  {
    id: "+95",
    name: "MM",
    displayText: "MM(95)",
    englishName: "Myanmar",
    flag: 'https://countryflagsapi.com/svg/mm'
  },  
  {
    id: "+976",
    name: "MN",
    displayText: "MN(976)",
    englishName: "Mongolia",
    flag: 'https://countryflagsapi.com/svg/mn'
  },  
  {
    id: "+853",
    name: "MO",
    displayText: "MO(853)",
    englishName: "Macao",
    flag: 'https://countryflagsapi.com/svg/mo'
  },  
  {
    id: "+1-670 ",
    name: "MP",
    displayText: "MP(1-670)",
    englishName: "The Northern Mariana Islands",
    flag: 'https://countryflagsapi.com/svg/mp'
  },  
  {
    id: "+596",
    name: "MQ",
    displayText: "MQ(596)",
    englishName: "Martinique",
    flag: 'https://countryflagsapi.com/svg/mq'
  },  
  {
    id: "+222",
    name: "MR",
    displayText: "MR(222)",
    englishName: "Mauritania",
    flag: 'https://countryflagsapi.com/svg/mr'
  },  
  {
    id: "+1-664",
    name: "MS",
    displayText: "MS(1-664)",
    englishName: "Montserrat",
    flag: 'https://countryflagsapi.com/svg/ms'
  },  
  {
    id: "+356",
    name: "MT",
    displayText: "MT(356)",
    englishName: "Malta",
    flag: 'https://countryflagsapi.com/svg/mt'
  },  
  {
    id: "+230",
    name: "MU",
    displayText: "MU(230)",
    englishName: "Mauritius",
    flag: 'https://countryflagsapi.com/svg/mu'
  },  
  {
    id: "+960",
    name: "MV",
    displayText: "MV(960)",
    englishName: "Maldives",
    flag: 'https://countryflagsapi.com/svg/mv'
  },  
  {
    id: "+265",
    name: "MW",
    displayText: "MW(265)",
    englishName: "Malawi",
    flag: 'https://countryflagsapi.com/svg/mw'
  },  
  {
    id: "+52",
    name: "MK",
    displayText: "MK(52)",
    englishName: "Mexico",
    flag: 'https://countryflagsapi.com/svg/mk'
  },  
  {
    id: "+60",
    name: "MY",
    displayText: "MY(60)",
    englishName: "Malaysia",
    flag: 'https://countryflagsapi.com/svg/my'
  },  
  {
    id: "+258",
    name: "MZ",
    displayText: "MZ(258)",
    englishName: "Mozambique",
    flag: 'https://countryflagsapi.com/svg/mz'
  },  
  {
    id: "+687",
    name: "NC",
    displayText: "NC(687)",
    englishName: "New Caledonia",
    flag: 'https://countryflagsapi.com/svg/nc'
  },  
  {
    id: "+227",
    name: "NE",
    displayText: "NE(227)",
    englishName: "Niger",
    flag: 'https://countryflagsapi.com/svg/ne'
  },  
  {
    id: "+672",
    name: "NF",
    displayText: "NF(672)",
    englishName: "Norfolk Island",
    flag: 'https://countryflagsapi.com/svg/nf'
  },  
  {
    id: "+234",
    name: "NG",
    displayText: "NG(234)",
    englishName: "Nigeria",
    flag: 'https://countryflagsapi.com/svg/ng'
  },  
  {
    id: "+505",
    name: "NI",
    displayText: "NI(505)",
    englishName: "Nicaragua",
    flag: 'https://countryflagsapi.com/svg/ni'
  },  
  {
    id: "+31",
    name: "NL",
    displayText: "NL(31)",
    englishName: "Netherlands",
    flag: 'https://countryflagsapi.com/svg/nl'
  },  
  {
    id: "+47",
    name: "NO",
    displayText: "NO(47)",
    englishName: "Norway",
    flag: 'https://countryflagsapi.com/svg/no'
  },  
  {
    id: "+674",
    name: "NR",
    displayText: "NR(674)",
    englishName: "Nauru",
    flag: 'https://countryflagsapi.com/svg/nr'
  },  
  {
    id: "+683",
    name: "NU",
    displayText: "NU(683)",
    englishName: "Niue",
    flag: 'https://countryflagsapi.com/svg/nu'
  },  
  {
    id: "+64",
    name: "NZ",
    displayText: "NZ(64)",
    englishName: "New Zealand",
    flag: 'https://countryflagsapi.com/svg/nz'
  },  
  {
    id: "+968",
    name: "OM",
    displayText: "OM(968)",
    englishName: "Oman",
    flag: 'https://countryflagsapi.com/svg/om'
  },  
  {
    id: "+507",
    name: "PA",
    displayText: "PA(507)",
    englishName: "Panama",
    flag: 'https://countryflagsapi.com/svg/pa'
  },  
  {
    id: "+51",
    name: "PE",
    displayText: "PE(51)",
    englishName: "Peru",
    flag: 'https://countryflagsapi.com/svg/pe'
  },  
  {
    id: "+675",
    name: "PG",
    displayText: "PG(675)",
    englishName: "Papua New Guinea",
    flag: 'https://countryflagsapi.com/svg/pg'
  },  
  {
    id: "+689",
    name: "PF",
    displayText: "PF(689)",
    englishName: "French Polynesia",
    flag: 'https://countryflagsapi.com/svg/pf'
  },  
  {
    id: "+63",
    name: "PH",
    displayText: "PH(63)",
    englishName: "Philippines",
    flag: 'https://countryflagsapi.com/svg/ph'
  },  
  {
    id: "+92",
    name: "PK",
    displayText: "PK(92)",
    englishName: "Pakistan",
    flag: 'https://countryflagsapi.com/svg/pk'
  },  
  {
    id: "+48",
    name: "PL",
    displayText: "PL(48)",
    englishName: "Poland",
    flag: 'https://countryflagsapi.com/svg/pl'
  },  
  {
    id: "+508",
    name: "PM",
    displayText: "PM(508)",
    englishName: "Saint Pierre And Miquelon",
    flag: 'https://countryflagsapi.com/svg/pm'
  },  
  {
    id: "+64",
    name: "PN",
    displayText: "PN(64)",
    englishName: "Pitcairn",
    flag: 'https://countryflagsapi.com/svg/pn'
  },  
  {
    id: "+1-787",
    name: "PR",
    displayText: "PR(1-787)",
    englishName: "Puerto Rico",
    flag: 'https://countryflagsapi.com/svg/pr'
  },  
  {
    id: "+970",
    name: "PS",
    displayText: "PS(970)",
    englishName: "Palestine",
    flag: 'https://countryflagsapi.com/svg/ps'
  },  
  {
    id: "+351",
    name: "PT",
    displayText: "PT(351)",
    englishName: "Portugal",
    flag: 'https://countryflagsapi.com/svg/pt'
  },  
  {
    id: "+680",
    name: "PW",
    displayText: "PW(680)",
    englishName: "Palau",
    flag: 'https://countryflagsapi.com/svg/pw'
  },  
  {
    id: "+595",
    name: "PY",
    displayText: "PY(059502)",
    englishName: "Paraguay",
    flag: 'https://countryflagsapi.com/svg/py'
  },  
  {
    id: "+974",
    name: "QA",
    displayText: "QA(974)",
    englishName: "Qatar",
    flag: 'https://countryflagsapi.com/svg/qa'
  },  
  {
    id: "+262",
    name: "RE",
    displayText: "RE(262)",
    englishName: "Réunion",
    flag: 'https://countryflagsapi.com/svg/re'
  },  
  {
    id: "+40",
    name: "RO",
    displayText: "RO(40)",
    englishName: "Romania",
    flag: 'https://countryflagsapi.com/svg/ro'
  },  
  {
    id: "+381",
    name: "RS",
    displayText: "RS(381)",
    englishName: "Serbia",
    flag: 'https://countryflagsapi.com/svg/rs'
  },  
  {
    id: "+7",
    name: "RU",
    displayText: "RU(7)",
    englishName: "Russian Federation",
    flag: 'https://countryflagsapi.com/svg/ru'
  },  
  {
    id: "+250",
    name: "RW",
    displayText: "RW(646)",
    englishName: "250",
    flag: 'https://countryflagsapi.com/svg/rw'
  },  
  {
    id: "+966",
    name: "SA",
    displayText: "SA(966)",
    englishName: "Saudi Arabia",
    flag: 'https://countryflagsapi.com/svg/sa'
  },  
  {
    id: "+677",
    name: "SB",
    displayText: "SB(677)",
    englishName: "Solomon Islands",
    flag: 'https://countryflagsapi.com/svg/sb'
  },  
  {
    id: "+248",
    name: "SC",
    displayText: "SC(248)",
    englishName: "Seychelles",
    flag: 'https://countryflagsapi.com/svg/sc'
  },  
  {
    id: "+249",
    name: "SD",
    displayText: "SD(249)",
    englishName: "Sudan",
    flag: 'https://countryflagsapi.com/svg/sd'
  },  
  {
    id: "+46",
    name: "SE",
    displayText: "SE(46)",
    englishName: "Sweden",
    flag: 'https://countryflagsapi.com/svg/se'
  },  
  {
    id: "+65",
    name: "SG",
    displayText: "SG(65)",
    englishName: "Singapore",
    flag: 'https://countryflagsapi.com/svg/sg'
  },  
  {
    id: "+290",
    name: "SH",
    displayText: "SH(290)",
    englishName: "Saint Helena",
    flag: 'https://countryflagsapi.com/svg/sh'
  },  
  {
    id: "+386",
    name: "SI",
    displayText: "SI(386)",
    englishName: "Slovenia",
    flag: 'https://countryflagsapi.com/svg/si'
  },  
  {
    id: "+47",
    name: "SJ",
    displayText: "SJ(47)",
    englishName: "Svalbard And Jan Mayen",
    flag: 'https://countryflagsapi.com/svg/sj'
  },  
  {
    id: "+421",
    name: "SK",
    displayText: "SK(421)",
    englishName: "Slovakia",
    flag: 'https://countryflagsapi.com/svg/sk'
  },  
  {
    id: "+232",
    name: "SL",
    displayText: "SL(232)",
    englishName: "Sierra Leone",
    flag: 'https://countryflagsapi.com/svg/sl'
  },  
  {
    id: "+378",
    name: "SM",
    displayText: "SM(378)",
    englishName: "Marino",
    flag: 'https://countryflagsapi.com/svg/sm'
  },  
  {
    id: "+221",
    name: "SN",
    displayText: "SN(221)",
    englishName: "Senegal",
    flag: 'https://countryflagsapi.com/svg/sn'
  },  
  {
    id: "+252",
    name: "SO",
    displayText: "SO(252)",
    englishName: "Somalia",
    flag: 'https://countryflagsapi.com/svg/so'
  },  
  {
    id: "+597",
    name: "SR",
    displayText: "SR(597)",
    englishName: "Suriname",
    flag: 'https://countryflagsapi.com/svg/sr'
  },  
  {
    id: "+211",
    name: "SS",
    displayText: "SS(211)",
    englishName: "South Sudan",
    flag: 'https://countryflagsapi.com/svg/ss'
  },  
  {
    id: "+239",
    name: "ST",
    displayText: "ST(239)",
    englishName: "Sao Tome And Principe",
    flag: 'https://countryflagsapi.com/svg/st'
  },  
  {
    id: "+503",
    name: "SV",
    displayText: "SV(503)",
    englishName: "El Salvador",
    flag: 'https://countryflagsapi.com/svg/sv'
  },  
  {
    id: "+721",
    name: "SX",
    displayText: "SX(721)",
    englishName: "Sint Maarten",
    flag: 'https://countryflagsapi.com/svg/sx'
  },  
  {
    id: "+963",
    name: "SY",
    displayText: "SY(963)",
    englishName: "Syrian Arab Republic",
    flag: 'https://countryflagsapi.com/svg/sy'
  },  
  {
    id: "+268",
    name: "SZ",
    displayText: "SZ(268)",
    englishName: "Eswatini",
    flag: 'https://countryflagsapi.com/svg/sz'
  },  
  {
    id: "+1-649",
    name: "TC",
    displayText: "TC(1-649)",
    englishName: "The Turks And Caicos Islands",
    flag: 'https://countryflagsapi.com/svg/tc'
  },  
  {
    id: "+262",
    name: "TF",
    displayText: "TF(262)",
    englishName: "The French Southern Territories",
    flag: 'https://countryflagsapi.com/svg/tf'
  },  
  {
    id: "+228",
    name: "TG",
    displayText: "TG(228)",
    englishName: "Togo",
    flag: 'https://countryflagsapi.com/svg/tg'
  },  
  {
    id: "+66",
    name: "TH",
    displayText: "TH(66)",
    englishName: "Thailand",
    flag: 'https://countryflagsapi.com/svg/th'
  },  
  {
    id: "+992",
    name: "TJ",
    displayText: "TJ(992)",
    englishName: "Tajikistan",
    flag: 'https://countryflagsapi.com/svg/tj'
  },  
  {
    id: "+690",
    name: "TK",
    displayText: "TK(690)",
    englishName: "Tokelau",
    flag: 'https://countryflagsapi.com/svg/tk'
  },  
  {
    id: "+670",
    name: "TL",
    displayText: "TL(670)",
    englishName: "Timor-leste",
    flag: 'https://countryflagsapi.com/svg/tl'
  },  
  {
    id: "+993",
    name: "TM",
    displayText: "TM(993)",
    englishName: "Turkmenistan",
    flag: 'https://countryflagsapi.com/svg/tm'
  },  
  {
    id: "+216",
    name: "TN",
    displayText: "TN(216)",
    englishName: "Tunisia",
    flag: 'https://countryflagsapi.com/svg/tn'
  },  
  {
    id: "+676",
    name: "TO",
    displayText: "TO(676)",
    englishName: "Tonga",
    flag: 'https://countryflagsapi.com/svg/to'
  },  
  {
    id: "+90",
    name: "TR",
    displayText: "TR(90)",
    englishName: "Turkey",
    flag: 'https://countryflagsapi.com/svg/tr'
  },  
  {
    id: "+1-868",
    name: "TT",
    displayText: "TT(1-868)",
    englishName: "Trinidad And Tobago",
    flag: 'https://countryflagsapi.com/svg/tt'
  },  
  {
    id: "+688",
    name: "TV",
    displayText: "TV(688)",
    englishName: "Tuvalu",
    flag: 'https://countryflagsapi.com/svg/tv'
  },  
  {
    id: "+886",
    name: "TW",
    displayText: "TW(886)",
    englishName: "Taiwan",
    flag: 'https://countryflagsapi.com/svg/tw'
  },  
  {
    id: "+255",
    name: "TZ",
    displayText: "TZ(255)",
    englishName: "United Republic Of Tanzania",
    flag: 'https://countryflagsapi.com/svg/tz'
  },  
  {
    id: "+380",
    name: "UA",
    displayText: "UA(380)",
    englishName: "Ukraine",
    flag: 'https://countryflagsapi.com/svg/ua'
  },  
  {
    id: "+1-256",
    name: "UG",
    displayText: "UG(1-256)",
    englishName: "Uganda",
    flag: 'https://countryflagsapi.com/svg/ug'
  },  
  {
    id: "+11",
    name: "UM",
    displayText: "UM(11)",
    englishName: "The United States Minor Outlying Islands",
    flag: 'https://countryflagsapi.com/svg/um'
  },  
  {
    id: "+598",
    name: "UY",
    displayText: "UY(598)",
    englishName: "Uruguay",
    flag: 'https://countryflagsapi.com/svg/uy'
  },    
  {
    id: "+379",
    name: "VA",
    displayText: "VA(379)",
    englishName: "The Holy See",
    flag: 'https://countryflagsapi.com/svg/va'
  },  
  {
    id: "+1-784",
    name: "VC",
    displayText: "VC(1-784)",
    englishName: "Saint Vincent And The Grenadines",
    flag: 'https://countryflagsapi.com/svg/vc'
  },  
  {
    id: "+58",
    name: "VE",
    displayText: "VE(58)",
    englishName: "Venezuela",
    flag: 'https://countryflagsapi.com/svg/ve'
  },  
  {
    id: "+1-284",
    name: "VG",
    displayText: "VG(1-284)",
    englishName: "British Virgin Islands",
    flag: 'https://countryflagsapi.com/svg/vg'
  },  
  {
    id: "+1-340",
    name: "VI",
    displayText: "VI(1-340)",
    englishName: "Us Virgin Islands",
    flag: 'https://countryflagsapi.com/svg/vi'
  },  
  {
    id: "+84",
    name: "VN",
    displayText: "VN(84)",
    englishName: "Viet Nam",
    flag: 'https://countryflagsapi.com/svg/vn'
  },  
  {
    id: "+678",
    name: "VU",
    displayText: "VU(678)",
    englishName: "Vanuatu",
    flag: 'https://countryflagsapi.com/svg/vu'
  },  
  {
    id: "+681",
    name: "WF",
    displayText: "WF(681)",
    englishName: "Wallis And Futuna",
    flag: 'https://countryflagsapi.com/svg/wf'
  },  
  {
    id: "+685",
    name: "WS",
    displayText: "WS(865)",
    englishName: "Samoa",
    flag: 'https://countryflagsapi.com/svg/ws'
  },  
  { 
    id: "+ 383",
    name: "XK",
    displayText: "VXKN(383)",
    englishName: "Kosovo",
    flag: 'https://countryflagsapi.com/svg/xk'
  },  
  {
    id: "+967",
    name: "YE",
    displayText: "YE(967)",
    englishName: "Yemen",
    flag: 'https://countryflagsapi.com/svg/ye'
  },  
  {
    id: "+262",
    name: "YT",
    displayText: "YT(262)",
    englishName: "Mayotte",
    flag: 'https://countryflagsapi.com/svg/yt'
  },  
  {
    id: "+27",
    name: "ZA",
    displayText: "ZA(27)",
    englishName: "South Africa",
    flag: 'https://countryflagsapi.com/svg/za'
  },  
  {
    id: "+260",
    name: "ZM",
    displayText: "ZM(260)",
    englishName: "Zambia",
    flag: 'https://countryflagsapi.com/svg/zm'
  },  
  {
    id: "+263",
    name: "ZW",
    displayText: "ZW(263)",
    englishName: "Zimbabwe",
    flag: 'https://countryflagsapi.com/svg/zw'
  },     
];
// Customizable Area End

// Customizable Area Start
const DEFAULT_AREA_CODE = FLAG_SELECTOR_OPTION_LIST[0].id;
// Customizable Area End

// Customizable Area Start
export class TestComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      areaCode: DEFAULT_AREA_CODE,
      phone: "",
      hasPhoneError: true,
      validate: false
    };
  }

  handlePhoneChange(res) {
    this.setState({ phone: res });
    this.props.setPhoneNumValue(this.state.areaCode+''+res)
  }

  toggleValidating(validate) {
    this.setState({ validate });
  }

  render() {
    const { areaCode, phone, validate } = this.state;
    const currentItem = find(FLAG_SELECTOR_OPTION_LIST, { id: areaCode })[0];
    return (
      <div style={{ padding: "10px", width: 400 }}>
        <div style={{ padding: "20px", }}>
            <div style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  left: "0",
                  height: "45px",
                  //  customize
                  top: "5%",
                  left:"1%",
                  height: "35px",
                  backgroundColor: "#EEEEEE",
                  borderRadius: "4px"
                }}
              >
                <ReactCustomFlagSelect
                  attributesWrapper={{ id: "areaCodeWrapper", tabIndex: "1" }} //Optional.[Object].Modify wrapper general attributes.
                  attributesButton={{ id: "areaCodeButton" }} //Optional.[Object].Modify button general attributes.
                  attributesInput={{ id: "areaCode", name: "areaCode" }} //Optional.[Object].Modify hidden input general attributes.
                  fields={["name", "locale", "englishName"]} // Optional.[array].Default: ['name']. Fields for search filtering.
                  value={currentItem.id} //Optional.[String].Default: "".
                  disabled={false} //Optional.[Bool].Default: false.
                  showSearch={false} ////Optional.[Bool].Default: false.
                  showArrow={true} //Optional.[Bool].Default: true.
                  animate={true} //Optional.[Bool].Default: false.
                  optionList={FLAG_SELECTOR_OPTION_LIST} //Required.[Array of Object(s)].Default: [].
                  // selectOptionListItemHtml={<div>us</div>} //Optional.[Html].Default: none. The custom select options item html that will display in dropdown list. Use it if you think the default html is ugly.
                  // selectHtml={<div>us</div>} //Optional.[Html].Default: none. The custom html that will display when user choose. Use it if you think the default html is ugly.
                  classNameWrapper="" //Optional.[String].Default: "".
                  classNameContainer="" //Optional.[String].Default: "".
                  classNameOptionListContainer="" //Optional.[String].Default: "".
                  classNameOptionListItem="" //Optional.[String].Default: "".
                  classNameDropdownIconOptionListItem={""} //Optional.[String].Default: "".
                  customStyleWrapper={{}} //Optional.[Object].Default: {}.
                  customStyleContainer={{ border: "none", fontSize: "12px" }} //Optional.[Object].Default: {}.
                  customStyleSelect={{ width: "80px" }} //Optional.[Object].Default: {}.
                  customStyleOptionListItem={{}} //Optional.[Object].Default: {}.
                  customStyleOptionListContainer={{
                    maxHeight: "100px",
                    overflow: "auto",
                    width: "120px"
                  }} //Optional.[Object].Default: {}.
                  onChange={(areaCode) => {
                    this.setState({ areaCode: areaCode }, () => {
                      this.handlePhoneChange(phone);
                    });
                  }}
                  // onBlur={() => {}} //Optional.[Func].Default: none.
                  // onFocus={(e) => {console.log(e)}} //Optional.[Func].Default: none.
                  // onClick={(e) => {console.log(e)}} //Optional.[Func].Default: none.
                />
              </div>
              <Textbox
                attributesInput={{
                  id: "phone",
                  placeholder: "Enter Your Mobile Number",
                  type: "text",
                  border:'none'
                }}
                customStyleWrapper={{ height: "100%" }}
                customStyleContainer={{ height: "100%" }}
                customStyleInput={{
                  paddingTop: "0",
                  paddingBottom: "0",
                  height: "45px",
                  paddingLeft: "90px",
                  paddingRight: "20px",
                  border: "none",
                  borderBottom: "1px solid"
                }}
                value={phone}
                validate={validate}
                validationCallback={(res) =>
                  this.setState({
                    hasPhoneError: res,
                    validate: false
                  })
                }
                type="text"
                placeholder="Please enter your phone number"
                onChange={(res) => {
                  const number = res.replace(/[^\d]/g, '')
                  this.handlePhoneChange(number);
                }}
                onBlur={() => {}}
                validationOption={{
                  check: true,
                  required: true,
                  customFunc: (phone) => {
                    // if (
                    //   validator.isMobilePhone(`${phone}`, currentItem.locale)
                    // ) {
                    //   return true;
                    // } else {
                    //   return `Invalid phone format for ${currentItem.locale}`;
                    // }
                  }
                }}
              />
            </div>
            <div style={{ height: "20px" }} />

            <input type="submit" style={{ display: "none" }} />
        </div>
      </div>
    );
  }
}
// Customizable Area End
