import React from 'react';
// Customizable Area Start
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import {
  heroBgImage,
  heroTopImage,
  aboutImage,
  extensiveDataImage,
  globalGuidelinesImage,
  nutritiveValueImage,
  productAnalysisImage,
  smartSearchImage,
  nutritiveValueBgImage,
  lineNiq,
  linePA,
  lineEDT,
  lineSS,
} from './assets';
import LandingPageWeb from './LandingPage.web';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      // width: '100%',
      margin: 0,
    },
    // css for hero_section start {rf}
    hero__section: {
      height: '100vh',
      background: `linear-gradient(109.52deg, rgba(56, 0, 111, 0.82) 55.17%, rgba(255, 57, 57, 0.82) 111.06%), url(${heroBgImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    hero__image: {
      // width: '80%',

      [theme.breakpoints.only('md')]: {
        width: '30%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '40%',
      },
    },
    hero__content: {
      width: '100%',
      position: 'absolute',
      justifyContent: 'center',
      top: '50%',
      left: '50%',
      transform: `translate(-50%, -50%)`,
      textAlign: 'center',
    },
    hero_text: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '32px',
      lineHeight: '43px',
      color: '#FFFFFF',
      [theme.breakpoints.down('md')]: {
        fontSize: '22px',
        lineHeight: '30px',
        // padding:'padd'
      },
    },
    supText: {
      fontSize: '16px',
    },
    hero_button_text: {
      backgroundColor: 'white',
      color: 'rgba(56, 0, 109, 1)',
      fontWeight: 600,
      width: '250px',
      fontSize: '27px',
      lineHeight: '40px',
      textTransform: 'capitalize',
      borderRadius: '8px',
      [theme.breakpoints.down('md')]: {
        fontSize: '21px',
        lineHeight: '30px',
        width: '200px',
      },
    },
    // css for hero_section end {rf}
    // css for counter_section start {rf}
    counter_section: {
      width: '100%',
      // height: '140px',
      background: 'linear-gradient(90.14deg, #590EA0 3.61%, #FF5050 99.93%)',
      borderBottom: '5px solid #ffff',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      // color: theme.palette.text.secondary,
      whiteSpace: 'nowrap',
      marginBottom: theme.spacing(1),
      background: 'none',
      boxShadow: 'none',
      color: 'white',
    },
    counter_title_text: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '35px',
      lineHeight: '52px',
      color: '#FFFFFF',
      [theme.breakpoints.down('md')]: {
        fontSize: '25px',
      },
    },

    counter_content_text: {
      fontFamily: 'Poppins',

      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '27px',
      color: '#FFFFFF',
      [theme.breakpoints.down('md')]: {
        fontSize: '15px',
      },
    },
    // css for counter_section end {rf}

    // css for about_section start {rf}
    about_section: {
      background: `linear-gradient(248.69deg, rgba(54, 0, 109, 0.82) 45.74%, rgba(252, 169, 36, 0.82) 92.73%)`,
      borderBottom: '5px solid #ffff',
      padding: '5rem 0',
    },
    // css for about_section end {rf}

    // css for productAnalysis_section start {rf}
    productAnalysis_section: {
      background: `linear-gradient(248.69deg, rgba(54, 0, 109, 0.8) 42.39%, rgba(255, 79, 79, 0.8) 92.73%)`,
      borderBottom: '5px solid #ffff',
      padding: '5rem 0',
    },
    // css for productAnalysis_section end {rf}

    // css for globalGuidelines_section start {rf}
    globalGuidelines_section: {
      background: `linear-gradient(248.69deg, rgba(54, 0, 109, 0.8) 42.39%, rgba(138, 65, 206, 0.8) 92.73%)`,
      borderBottom: '5px solid #ffff',
      padding: '5rem 0',
    },
    // css for globalGuidelines_section end {rf}

    // css for nutritiveValue_section start {rf}
    nutritiveValue_section: {
      background: `linear-gradient(140deg, rgba(54, 0, 109, 0.82) 45.74%, rgba(252, 169, 36, 0.82) 92.73%),url(${nutritiveValueBgImage})`,
      backgroundSize:'cover',
      backgroundRepeat:'no-repeat',
      borderBottom: '5px solid #ffff',
      padding: '5rem 0',
      backgroundPosition:'center',
    },
    // css for nutritiveValue_section end {rf}

    // css for extensiveData_section start {rf}
    extensiveData_section: {
      background: `linear-gradient(90deg, rgba(8, 17, 152, 0.8) -8.48%, rgba(255, 79, 79, 0.8) 123.7%)`,
      borderBottom: '5px solid #ffff',
      padding: '5rem 0',

    },
    // css for extensiveData_section end {rf}

    // css for smartSearch_section start {rf}
    smartSearch_section: {
      background: `linear-gradient(257.5deg, rgba(255, 184, 69) 5.59%, rgba(255, 79, 79) 97.18%)`,
      borderBottom: '5px solid #ffff',
      padding: '5rem 0',
    },
    // css for smartSearch_section end {rf}

    // global css for about_section,productAnalysis_section,globalGuidelines_section,nutritiveValue_section,extensiveData_section,smartSearch_section grid text container start {rf}

    boxTitleText: {
      fontWeight: 600,
      fontFamily: 'Montserrat',
      fontSize: '40px',
      lineHeight: '70px',
      [theme.breakpoints.down('md')]: {
        fontSize: '20px',
        lineHeight: '50px',
      },
    },
    boxTitleTextEDT: {
      fontWeight: 600,
      fontFamily: 'Montserrat',
      fontSize: '42px',
      lineHeight: '70px',
      [theme.breakpoints.down('md')]: {
        fontSize: '30px',
        lineHeight: '50px',
      },
    },

    UnderlineStyle: {
      marginBottom: 20,
      width: '230px',
      [theme.breakpoints.down('md')]: {
        width: '150px',
      },

      [theme.breakpoints.down('xs')]: {
        width: '150px',
      },
    },
    EDTUnderlineStyle: {
      marginBottom: 20,
      width: '370px',
      [theme.breakpoints.down('md')]: {
        width: '260px',
      },

    },
    SSUnderlineStyle: {
      marginBottom: 20,
      width: '210px',
      [theme.breakpoints.down('md')]: {
        width: '130px',
      },

      [theme.breakpoints.down('xs')]: {
        width: '130px',
      },
    },
    boxContentText: {
      fontWeight: 500,
      fontFamily: 'Montserrat',
      fontSize: '28px',
      lineHeight: '40px',
      textAlign: 'justify',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
        lineHeight: '28px',
      },

    },
    home_intro_img: {
      width: '65%',
      [theme.breakpoints.only('sm')]: {
        width: '40%',
      },
      [theme.breakpoints.only('xs')]: {
        width: '60%',
      },
    },
    gridContent: {
      color: '#ffff',
      padding: '0 4rem',
      [theme.breakpoints.down('md')]: {
        padding: '0 1rem',
      },
    },
    girdSwap: {
      // [theme.breakpoints.up('lg')]: {
      //   order: 1,
      // },
      [theme.breakpoints.down('md')]: {
        order: 2,
      },

    },
    // global css for about_section,productAnalysis_section,globalGuidelines_section,nutritiveValue_section,extensiveData_section,smartSearch_section grid text container end {rf}
  });
// Customizable Area End

import HomePageController, { Props } from './HomepageController';
class HomePage extends HomePageController {
  render() {
    const { classes,navigation } = this.props;
    return (
      <div className={classes.root}>
        
        {/* --------- hero section start {rf} -------------- */}
        {this.state?.isSearchBar ? 
        //@ts-ignore
        <LandingPageWeb /> :
        <div className={classes.hero__section}>
          <div className={classes.hero__content}>
            <img src={heroTopImage} alt="" className={classes.hero__image} />
            <h3 className={classes.hero_text}>
              Assess the N<sup className={classes.supText}>IQ</sup> score
              (nutritive value) of any Packaged, Raw or Cooked food
            </h3>
            <Button
              variant="contained"
              size="large"
              className={classes.hero_button_text}
              onClick={()=>{
                navigation.navigate("LandingPage")
                
                // localStorage.setItem("dis", "i");
                this.handleSearchBarOpen();
                this.screenTop();
                
                
              }}
            >
              Search Now
            </Button>
          </div>
        </div> 
        
        }
        {/* --------- hero section end {rf} -------------- */}

        {/* --------- countDown section start {rf} -------------- */}

        <div className={classes.counter_section}>
          <Grid container style={{ padding: '45px 0' }}>
            <Grid item xs={12} sm={6} md={3}>
              <Paper className={classes.paper}>
                <Typography variant="h4" className={classes.counter_title_text}>
                  20,000+
                </Typography>
                <Typography
                  className={classes.counter_content_text}

                >
                  Total Product Listed
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper className={classes.paper}>
                <Typography variant="h4" className={classes.counter_title_text}>
                  Millions of
                </Typography>
                <Typography

                  className={classes.counter_content_text}
                >
                  Smart searches
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper className={classes.paper}>
                <Typography variant="h4" className={classes.counter_title_text}>
                  10,000+
                </Typography>
                <Typography

                  className={classes.counter_content_text}
                >
                  Daily searches
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper className={classes.paper}>
                <Typography variant="h4" className={classes.counter_title_text}>
                  1,00,000+
                </Typography>
                <Typography

                  className={classes.counter_content_text}
                >
                  Users
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </div>

        {/* --------- countDown section end {rf} -------------- */}

        {/* --------- about section start {rf} -------------- */}

        <div className={classes.about_section}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={5}>
              <Paper className={classes.paper}>
                <img
                  src={aboutImage}
                  alt=""
                  className={classes.home_intro_img}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <div className={classes.gridContent}>
                <Typography variant="h4" className={classes.boxTitleText}>
                  What’s Your N<sup>IQ</sup>
                </Typography>
                <div style={{ height: '1px', background: '#FCC300', }} className={classes.UnderlineStyle} />
                <Typography className={classes.boxContentText}>
                  We are a completely independent app, that helps you assess the
                  nutritive value of any packaged, raw or cooked food or drink
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        {/* --------- about section end {rf} -------------- */}

        {/* --------- productAnalysis section start {rf} -------------- */}

        <div className={classes.productAnalysis_section}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={7} className={classes.girdSwap}>
              <div className={classes.gridContent}>
                <Typography variant="h4" className={classes.boxTitleText}>
                  Product Analysis
                </Typography>
                <div style={{ height: '1px', background: '#FFFFFF', }} className={classes.UnderlineStyle} />
                <Typography className={classes.boxContentText}>
                  Is based on a scientific logic developed by some of the
                  world’s best scientists and ratified by highly experienced
                  nutrition experts
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <Paper className={classes.paper}>
                <img
                  src={productAnalysisImage}
                  alt=""
                  className={classes.home_intro_img}
                />
              </Paper>
            </Grid>
          </Grid>
        </div>
        {/* --------- productAnalysis section end {rf} -------------- */}

        {/* --------- globalGuidelines section start {rf} -------------- */}

        <div className={classes.globalGuidelines_section}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={5}>
              <Paper className={classes.paper}>
                <img
                  src={globalGuidelinesImage}
                  alt=""
                  className={classes.home_intro_img}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <div className={classes.gridContent}>
                <Typography variant="h4" className={classes.boxTitleText}>
                  Global & Local Guidelines
                </Typography>
                <div style={{ height: '1px', background: '#FFFFFF', }} className={classes.UnderlineStyle} />
                <Typography className={classes.boxContentText}>
                  The analysis is based on WHO, European & Indian dietary
                  guidelines, which are globally accepted as a benchmark
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        {/* --------- globalGuidelines section end {rf} -------------- */}

        {/* --------- nutritiveValue section start {rf} -------------- */}

        <div className={classes.nutritiveValue_section}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={7} className={classes.girdSwap}>
              <div className={classes.gridContent}>
                <Typography variant="h4" className={classes.boxTitleText}>
                  Nutritive value scale
                </Typography>
                <Typography className={classes.boxContentText}>
                  N<sup>IQ</sup> score grades all products on their nutritive value from
                  highest (A) to lowest (E) based on this unique scientific
                  logic
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <Paper className={classes.paper}>
                <img
                  src={nutritiveValueImage}
                  alt=""
                  className={classes.home_intro_img}
                />
              </Paper>
            </Grid>
          </Grid>
        </div>
        {/* --------- nutritiveValue section end {rf} -------------- */}

        {/* --------- extensiveData section start {rf} -------------- */}

        <div className={classes.extensiveData_section}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"

          >
            <Grid item xs={12} sm={12} md={5}>
              <Paper className={classes.paper}>
                <img
                  src={extensiveDataImage}
                  alt="extensiveDataImage"
                  className={classes.home_intro_img}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <div className={classes.gridContent}>
                <Typography variant="h4" className={classes.boxTitleText}>
                  Extensive Data & Deep Tech
                </Typography>
                <div style={{ height: '1px', background: '#FFFFFF', }} className={classes.EDTUnderlineStyle} />
                <Typography className={classes.boxContentText}>
                  An extensive database of 20,000+ products, millions of data
                  points & deep analytic tools to simplify complex information
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        {/* --------- extensiveData section end {rf} -------------- */}

        {/* --------- smartSearch section start {rf} -------------- */}

        <div className={classes.smartSearch_section}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={5}>
              <Paper className={classes.paper}>
                <img
                  src={smartSearchImage}
                  alt=""
                  className={classes.home_intro_img}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <div className={classes.gridContent}>
                <Typography variant="h4" className={classes.boxTitleText}>
                  Smart Search
                </Typography>
                <div style={{ height: '1px', background: '#FFFFFF', }} className={classes.SSUnderlineStyle} />
                <Typography className={classes.boxContentText}>
                  Helps you make the right choices in-case of food allergies,
                  health needs and dietary preferences
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        {/* --------- smartSearch section end {rf} -------------- */}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(HomePage);