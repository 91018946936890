import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
import React from 'react'
import { product_bg, Favorite, ReportThis, Compare, rightArrow, LeftArrow } from './assets';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  loading: boolean;
  activeProduct: any;
  productId: any;
  products: any;
  allProducts: any;
  positiveProductDetails: any;
  negativeProductDetails: any;

  niqProducts: any;
  loadingNiqChoicesProduct: boolean;
  loadingAddFavProduct: boolean;

  modalOpen: boolean;
  addFoodBasketloader: boolean;
  foodBasketListLoader: boolean;
  foodBasketLists: any;
  PostID: any;
  addFoodBasketApiResponseText: any;
  foodBasketNotificationDailogBoxOpen: boolean;
  isLogin: boolean;
  loginNotifiModalOpen:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}


export default class ProductDetailsScreenWebController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: string = '';
  getNiqChoicesProductApiCallId: string = '';
  postFavProductApiCallId: string = '';
  addFoodBasketmessageId: string = '';
  getFoodBasketApiCallId: string = '';
  PostID: string = '';
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage), // nedd to subScribe messageEnum {adapter} {add by rifadulDev}
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: '',
      loading: false,
      productId: null,
      products: [],
      activeProduct: '',
      positiveProductDetails: [],
      negativeProductDetails: [],
      allProducts: [],
      niqProducts: [],
      loadingNiqChoicesProduct: false,
      loadingAddFavProduct: false,
      modalOpen: false,
      addFoodBasketloader: false,
      foodBasketListLoader: false,
      foodBasketLists: [],
      PostID: [],
      addFoodBasketApiResponseText: 'Product added successfully',
      foodBasketNotificationDailogBoxOpen: false,
      isLogin: false,
      loginNotifiModalOpen:false,

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    const haveToken = localStorage.getItem('token')
    if (haveToken) {
      this.setState({
        isLogin: true
      })
      this.getFoodBasketList();
    }

    this.renderPage();
  }

  renderPage = () =>{
    const productId = this.state.productId === null ? localStorage.getItem('proId') : this.state.productId
    let prevData = localStorage.getItem('favList');
    if (prevData) {
      this.setState({ allProducts: JSON.parse(prevData) })
    }
    this.setState({ activeProduct: productId })
    this.getProductDetails(productId);
    this.getNiqChoicesProduct(productId);
  }


  componentDidUpdate(){
    if (this.props?.navigation?.history?.location?.search.substring(1)){
      console.log('__render',this.props?.navigation?.history?.location?.search.substring(1))
      
      // console.log('__data',this.props?.navigation?.history?.location?.search)
      // this.renderPage();
      // localStorage.setItem('proId', this.props?.navigation?.history?.location?.search.substring(1))
      // this.getProductDetails(this.props?.navigation?.history?.location?.search.substring(1));
    }    
    // console.log(this.props?.navigation?.history?.location?.search.substring(1), "gg")
    // console.log(prevProps?.navigation?.history?.location?.search?.substring(1), "ggg")
    // this.getProductDetails(this.props?.navigation?.history?.location?.search.substring(1));
    // this.setState({ activeProduct:  this.props?.navigation?.history?.location?.search.substring(1)})
    // this.getProductDetails( this.props?.navigation?.history?.location?.search.substring(1));
    // this.getNiqChoicesProduct( this.props?.navigation?.history?.location?.search.substring(1));
   
    
  }


  navigationBtnFnc = (path: any) => {
    this.props?.navigation?.navigate(path);
  }

  goToProductDetailsPage = (id: any) => {
    localStorage.setItem('proId', id);
    this.setState({
      productId: id,
    }, () => {
      this.getProductDetails(id);
      this.getNiqChoicesProduct(id);
      this.getFoodBasketList();
    })
    window.location.reload()
  }

  // Customizable Area Start
  //  <---------- API call for product details {rf} ---------->
  getProductDetails = (id: any) => {
    this.setState({
      loading: true
    })

    // const userToken = localStorage.getItem("token");
    const header = {
      'Content-Type': 'application/json',
      // token: userToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethodType);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getProductDetailsEndPoint}?id=${id}`);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleFav = (data: any) => {
    let stateData: any = this.state.allProducts;
    let checkProduct = stateData.find((e: any) => {
      return e == this.state.activeProduct;
    })
    if (checkProduct) {
      let checkProduct = stateData.filter((e: any) => {
        return e != this.state.activeProduct;
      })
      localStorage.setItem('favList', JSON.stringify(checkProduct));
      window.location.reload()
    } else {
      stateData.push(data);
      localStorage.setItem('favList', JSON.stringify(stateData));
      window.location.reload()
    }
  }

  //  <---------- API call for Add Favourite ---------->
  
  handleSubmit = async (data: any) => {
    const token = await localStorage.getItem("authToken")


    const headers = {
      token: token
    };

    const form = new FormData();
    form.append("data[attributes][favourite]", data.favourite);
    form.append("data[attributes][product_id]", data.product_id);
    // var options = { content: form };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PostID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.favouriteProducts
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      form
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "post"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };



  //  <---------- API call for NIQ Choices products {rf} ---------->
  getNiqChoicesProduct = (id: any) => {
    this.setState({
      loadingNiqChoicesProduct: true
    })
    const userToken = localStorage.getItem("token");
    const header = {
      'Content-Type': 'application/json',
      token: userToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNiqChoicesProductApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethodType);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getNiqChoicesProductApiEndPoint}?product_id=${id}`);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  // <---------- API call for Add food basket {rf} ---------->
  addFoodBasket = (data: any) => {

    this.setState({
      addFoodBasketloader: true
    })
    const userToken = localStorage.getItem("token");
    const header = {
      'Content-Type': configJSON.applicationJsonContentType,
      token: userToken,
    };

    const httpBody = {
      data: data
    }

    // rest api call message create
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // save rest api resuest id
    this.addFoodBasketmessageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addFoodBasketApiEndPoint // end point setup
    );

    // set header
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // sent the body data
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod // api method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  //  <---------- API call for food basket list {rf} ---------->
  getFoodBasketList = () => {
    this.setState({
      foodBasketListLoader: true
    })
    const userToken = localStorage.getItem("token");
    const header = {
      'Content-Type': 'application/json',
      token: userToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFoodBasketApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethodType);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.addFoodBasketApiEndPoint);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }




  // receive function for API response
  async receive(from: string, message: Message) {
    // recive value from {adapter} {add by rifadulDev}
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const productId = message.getData(
        getName(MessageEnum.FromProductPageNavigationMessage)
      );
      this.setState({
        productId: productId
      })
      // window.location.reload() //added by by sai_dev

      localStorage.removeItem('proId')
      localStorage.setItem('proId', productId)
    }



    //  <---------- response for  get product details api {rf} ---------->
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId &&
      this.getProductApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.data) {
        this.setState({
          products: responseJson?.data,
          loading: false
        })

        // Good And Not So Good Functionality Start
        
        let filteredGood:any=[];
        let filteredNotSoGood:any=[];
        
    // Logic for Not So Good Products
    if (this.state.products?.attributes?.negative_not_good != null) {        
    for (let i=0; i < this.state.products?.attributes?.negative_not_good?.length;  i++) {
  
        // @ts-ignore
        // if(Object.entries(this.state.products?.attributes?.negative_not_good)[i]?.level?.toLowerCase() === "low") {
        //   filteredGood.push(this.state.products?.attributes?.negative_not_good[i])
        // }
        // // @ts-ignore
        // else if (this.state.products?.attributes?.negative_not_good[i]?.level?.toLowerCase() === "free") {
        //       filteredGood.push(this.state.products?.attributes?.negative_not_good[i])
        // }
        // else {
          filteredNotSoGood.push(this.state.products?.attributes?.negative_not_good[i])
        // }
    }
  }

  // Logic for Good Products
  if (this.state.products?.attributes?.positive_good != null) {
    for (let i=0; i < this.state.products?.attributes?.positive_good?.length; i++) {
      // @ts-ignore
      // if(this.state.products?.attributes?.positive_good[i]?.level?.toLowerCase() != "low") {
        filteredGood.push(this.state.products?.attributes?.positive_good[i])
      // }
  }
  }
    this.setState({ positiveProductDetails: filteredGood})
    this.setState({ negativeProductDetails: filteredNotSoGood })
      // Good And Not So Good Functionality Stop


        window.scrollTo({ top: 0 })
      }
      if (responseJson.errors) {
        if (responseJson?.errors === 'Product not found') {
          this.setState({
            loading: false,
          })
          this.props?.navigation?.navigate('NoProudctFound');
        } else {
          this.setState({
            loading: false
          })
          this.props?.navigation?.navigate('UserSignUpScreenWeb');
        }
      }
      if (responseJson?.errors === 'Product not found') {
        this.setState({
          loading: false,
        })
        this.props?.navigation?.navigate('NoProudctFound');
      }

    }

    // end for {rf}

    //  <---------- response for  get NIQ Choices product api {rf} ---------->
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getNiqChoicesProductApiCallId &&
      this.getNiqChoicesProductApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.data) {
        this.setState({
          niqProducts: responseJson?.data,
          loadingNiqChoicesProduct: false
        })
        window.scrollTo({ top: 0 })

      } else {
        this.setState({
          loadingNiqChoicesProduct: false
        })
      }
      if (responseJson?.errors) {
        this.setState({
          // niqProducts: ['no data']
          loadingNiqChoicesProduct: false
        })
      }

    }

    // end for {rf}

    //  <---------- response for  Add food basket api {rf} ---------->
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addFoodBasketmessageId &&
      this.addFoodBasketmessageId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.data) {
        this.setState({
          addFoodBasketloader: false
        })
        this.getFoodBasketList();
        this.handleModalOpen()
      }
      if (responseJson?.error) {
        this.setState({
          addFoodBasketloader: false,
          addFoodBasketApiResponseText: responseJson?.error === 'Bad Request' ? 'Please try again' : responseJson?.error,
        })
        this.getFoodBasketList();
        this.handleModalOpen()
      }

    }

    // end for {rf}

    //  <---------- response for  food basket list {rf} ---------->
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getFoodBasketApiCallId &&
      this.getFoodBasketApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.data) {
        this.setState({
          foodBasketLists: responseJson?.data,
          foodBasketListLoader: false

        })
      }
      if (responseJson?.error) {
        this.setState({
          foodBasketListLoader: false
        })
      }

    }

    // end for {rf}

    // addFavProducts-------------------------//

    // if (apiRequestCallId === this.PostID) {
    //   this.setState({
    //   });
    //   console.log(responseJson, "Create Campaign");
    // }

  }

  handleModalOpen = () => {
    this.setState({ modalOpen: true })
  }
  handleModalClose = () => {
    this.setState({ addFoodBasketApiResponseText: 'Product added successfully', modalOpen: false })
  }

  handleLoginNotifiModalOpen = () => {
    this.setState({ loginNotifiModalOpen: true })
  }
  handleLoginNotifiModalClose = () => {
    this.setState({ loginNotifiModalOpen: false })
  }


  backButton = () => {
    this.props?.navigation?.goBack();
  }

  checkStatusfav = () => {

    let data = this.state.allProducts.find((e: any) => {
      return e == this.state.activeProduct
    })
    if (data) {
      return <img src={Favorite} alt="Favorite" />
    } else {
      return <FavoriteBorderIcon style={{ color: '#ADADAD' }} fontSize="large"  />
    }
  }
  // Customizable Area End
}